import moment from "moment";

export function countByGrade(data, key) {
  const counts = {};

  for (const item of data || []) {
    counts[item[key]] = counts[item[key]] || 0;
    counts[item[key]]++;
  }

  return counts;
}

export function getKeyAndValue(obj) {
  const keys = Object.keys(obj);
  const values = Object.values(obj);
  return [keys, values];
}

export function formatDate(data) {
  return data.map((item) => ({
    ...item,
    Datecreated: moment(item.Datecreated).format("D MMMM"),
    CreatedAt: moment(item.CreatedAt).format("D MMMM"),
    ChangedAt: moment(item.ChangedAt).format("D MMMM"),
    FollowUpDate: moment(item.FollowUpDate).format("D MMMM"),
  }));
}

export const omitedColumns = ["id", "leadId", "userId"];

export function countKey(data, key) {
  const counts = {};
  for (const item of data || []) {
    counts[item[key]] = item.count || 0;
    counts[item[key]]++;
  }

  return counts;
}

export function getConversionRate(data) {
  return data.map((item) => ({
    ...item,
    ConversionRate:
      item.Prospects !== 0 && item.Leads !== 0
        ? ((item.Prospects / item.Leads) * 100).toFixed(2) + "%"
        : "0%",
  }));
}

export const defaultOmnitedColumns = [
  "LeadNumber",
  "id",
  "leadId",
  "prospectId",
  "userId",
  "ConversionFlag",
  "DeletionFlag",
  "State",
  "SalesOrg",
  "ChangedBy",
  "Cocode",
  "Remark",
  "ProspectNumber",
  "Remarks",
  "ChangedAt",
];
export const componentType = {
  followup: {
    name: "Followup",
    omitedColumns: defaultOmnitedColumns,
    collapsible: true,
    options: true,
  },
  table: {
    name: "Table",
    tableName: "",
    omitedColumns: defaultOmnitedColumns,
    collapsible: true,
    options: true,
    pagination: true,
  },
  chart: {
    type: {
      pie: {
        name: "Pie",
        tableName: "",
        columnName: "",
      },
      donut: {
        name: "donut",
        tableName: "",
        columnName: "",
      },
      bar: {
        name: "Bar",
        tableName: "",
        columnName: "",
      },
    },
  },
};

export const defaultComponents = [
  {
    name: "TOTALCOUNT",
    label: "Total Count",
    visible: true,
  },
  {
    name: "RECENTS",
    label: "Recently added",
    visible: true,
  },
  {
    name: "FOLLOWUPS",
    label: "Follow-Up Date",
    visible: true,
  },
  {
    name: "LEADS_BY_GRADE",
    label: "Grade chart",
    visible: true,
    componentType: componentType.chart.type.pie,
  },
  {
    name: "LEADS_BY_CITY",
    label: "City chart",
    visible: true,
    componentType: componentType.chart.type.pie,
  },
  {
    name: "LEADS_BY_COUNTRY",
    label: "Country chart",
    visible: true,
    componentType: componentType.chart.type.pie,
  },
  {
    name: "LEADS_BY_INDUSTRY",
    label: "Industry chart",
    visible: true,
    componentType: componentType.chart.type.bar,
  },
  {
    name: "LEAD_OWNER_ANALYTICS",
    label: "Lead Owner Analytics",
    visible: true,
  },

  // EditChart
  {
    name: "LEADS_Chart",
    label: "Dynamic Chart",
    visible: true,
    //componentType: componentType.chart.type.pie,
  },
];

export const customizeTableHelpText = `Drag and drop to reorder column. Click on “eye” to Toggle Visibility`;
