import React, { useState, useEffect, useRef } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { BsArrowLeftRight } from "react-icons/bs";
import { useTable } from "../../hooks";

export default function Table({
  columns,
  setColumns,
  data,
  setData,
  enableGrapScroll,
  enableDrag,
  enableSort,
  hideEyeIcon,
}) {
  const tableRef = useRef(null);

  const {
    dragging,
    onDragStart,
    onDragOver,
    onDrop,
    onMouseDown,
    onMouseUp,
    onMouseMove,
    tableColumns,
    sortTable,
    lastSortedColumn,
    sortOrder,
    toggleColumnVisibility,
  } = useTable({
    tableRef,
    columns,
    setColumns,
    data,
    setData,
    enableDrag,
    enableGrapScroll,
  });

  return (
    <div ref={tableRef} className="flex overflow-x-auto pb-4 gap-7">
      <div className="relative flex-shrink-0 w-full pb-3">
        {tableColumns?.length !== 0 ? (
          <table className="min-h-[100px] table-auto border-collapse w-max">
            {/* Table header */}
            <thead className="flex-nowrap text-left">
              <tr className="flex">
                {tableColumns.map((column, index) => (
                  <TableHeadCell
                    key={index}
                    {...{
                      column,
                      dragging,
                      enableDrag,
                      enableSort,
                      index,
                      lastSortedColumn,
                      onDragOver,
                      onDragStart,
                      onDrop,
                      sortOrder,
                      sortTable,
                      toggleColumnVisibility,
                      hideEyeIcon, // Pass the prop to TableHeadCell
                    }}
                  />
                ))}
              </tr>
            </thead>
            {/* Table body */}
            {data?.length !== 0 && (
              <tbody {...{ onMouseDown, onMouseUp, onMouseMove }}>
                {data.map((row, rowIndex) => (
                  <tr
                    className="flex items-left border-b-2 border-slate-300"
                    key={rowIndex}
                  >
                    {tableColumns.map((column, columnIndex) => (
                      <TableCell
                        key={rowIndex + columnIndex}
                        {...{ row, column }}
                      />
                    ))}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        ) : (
          <h1 style={{ textAlign: "center", color: "black" }}>No data found</h1>
        )}
      </div>
    </div>
  );
}

function TableHeadCell({
  column,
  index,
  enableDrag,
  dragging,
  onDragStart,
  onDragOver,
  onDrop,
  enableSort,
  toggleColumnVisibility,
  hideEyeIcon,
}) {
  const onToggleColumnVisibility = () => {
    toggleColumnVisibility(index);
  };

  return (
    <th
      className="group w-52 flex justify-left items-center gap-3 py-2 border-b-2  border-slate-300 whitespace-nowrap text-center data-[drag=true]:cursor-grab data-[drag=true]:active:cursor-grabbing"
      key={column.key}
      draggable={enableDrag}
      onDragStart={(event) => onDragStart(event, index)}
      onDragOver={onDragOver}
      onDrop={(event) => onDrop(event, index)}
      data-drag={enableDrag}
    >
      <span
        data-drag={enableDrag}
        data-dragging={dragging}
        data-visible={column.visible}
        className="flex gap-3 items-center text-normal animate-none animate-infinite data-[drag=true]:animate-bounce group-hover:animate-none data-[dragging=true]:animate-none data-[visible=false]:text-gray-primary"
      >
        {column.name}
        {/* Conditionally render the eye icon */}
        {!hideEyeIcon && (
          <span className="invisible group-hover:visible">
            {column.visible ? (
              <AiOutlineEye
                onClick={onToggleColumnVisibility}
                className="text-gray text-medium cursor-pointer"
              />
            ) : (
              <AiOutlineEyeInvisible
                onClick={onToggleColumnVisibility}
                className="text-gray text-xs cursor-pointer"
              />
            )}
          </span>
        )}
      </span>
      {enableSort && enableDrag && (
        <BsArrowLeftRight className="rotate-90 text-gray text-xs cursor-pointer" />
      )}
    </th>
  );
}

function TableCell({ column, row }) {
  // console.log(column, row);
  return (
    <td className="w-52 flex justify-left items-center gap-3 py-2 text-left break-words">
      <span className="text-normal text-black-shady">{row[column.name]}</span>
    </td>
  );
}
