import React from "react";
import { useState, useEffect } from "react";
import Axios from "../../api-instance";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Rnd } from "react-rnd";
import moment, { utc } from "moment/moment";
// Internal module import
import "./DraggableProspectTable.css";

// Chakara ui modules
import { Badge } from "@chakra-ui/react";
import {
  useDisclosure,
  Button,
  Modal,
  Stack,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";

import {
  IconButton,
  Tooltip,
  useToast,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Select,
  Box,
  FormErrorMessage,
  FormHelperText,
  Input,
} from "@chakra-ui/react";

// Icons Imports
import { MdEdit } from "react-icons/md";
import { BiSort } from "react-icons/bi";
import { MdOutlineFactCheck } from "react-icons/md";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { CgClose } from "react-icons/cg";

const DraggableProspectTable = ({
  isLoading,
  columns,
  data,
  getData,
  prospectData,
  setProspectData,
  index,
  checkBoxSelected,
  currentPage,
  setCheckBoxSelected,
  handleCheckbox,
  setCheckBoxAll,
  checkBoxAll,
  handleDragOver,
  handleDragStart,
  handleDrop,
  tableColumnsProspect,
  pageNumbers,
}) => {
  const toast = useToast();

  // State Variables
  const [scrollBehavior, setScrollBehavior] = React.useState("inside");
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [selectedProspect, setSelectedProspect] = useState({});
  const [editedProspect, setEditedProspect] = useState({});
  const [gradeData, setGradeData] = useState([]);
  const [industryData, setIndustryData] = useState([]);
  const [tagData, setTagData] = useState([]);
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  //This state is used for header checkbox selection
  const [activePageCheckBoxSelect, setActivePageCheckBoxSelect] = useState([]);
  const [size, setSize] = React.useState("xl"); // Set the initial size to 'full'
  const [formData, setFormData] = useState({
    PhoneWithCode: "",
    CountryData: "",
  });

  // UseEffect
  useEffect(() => {
    setEditedProspect(selectedProspect);
  }, [selectedProspect]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await Axios.get("/masterdata/get_country");

        setCountryData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  useEffect(() => {
    getCountries(editedProspect["Country"]);
  }, [selectedProspect, editedProspect["Country"]]);

  // Grade Map

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await Axios.get("/masterdata/get_grade");
        setGradeData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Industry Map

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await Axios.get("/masterdata/get_industry");
        setIndustryData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Tags Map

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await Axios.get("/masterdata/get_tag");
        setTagData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Region API
  const [regionData, setRegionData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await Axios.get("/masterdata/get_region");
        setRegionData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Lead Source API
  const [leadSourceData, setLeadSourceData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await Axios.get("/masterdata/get_source");
        setLeadSourceData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  const handleChangePhone = (value, countryData) => {
    if (countryData && countryData.dialCode) {
      const dialCodeLength = countryData.dialCode.length;
      const Phone = value.slice(dialCodeLength);

      setFormData((prevFormData) => ({
        ...prevFormData,
        Phone,
        PhoneWithCode: value,
        CountryData: countryData,
      }));
    }
  };
  const handleChange = async (e) => {
    const { name, value } = e.target;
    let meetingConductedFlag = editedProspect.meetingConductedFlag || 0;
    let meetingCanceledFlag = editedProspect.meetingCanceledFlag || 0;
    let callConductedFlag = editedProspect.callConductedFlag || 0;
    let callCanceledFlag = editedProspect.callCanceledFlag || 0;
    setEditedProspect((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "Country") {
      setSelectedProspect((prev) => ({
        ...prev,
        Country: value,
        City: "",
      }));

      try {
        const { data } = await Axios.get(
          `/masterdata/get_city?countryData=${value}`
        );
        setCityData(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } else if (name === "City") {
      setSelectedProspect((prev) => ({
        ...prev,
        City: value,
      }));
    } else if (name === "MeetingStatus") {
      // Update meetingConductedFlag and meetingCanceledFlag based on the selected MeetingStatus
      meetingConductedFlag = value === "Conducted" ? 1 : 0;
      meetingCanceledFlag = value === "Cancelled" ? 1 : 0;
    } else if (name === "CallStatus") {
      // Update callConductedFlag and callCanceledFlag based on the selected CallStatus
      callConductedFlag = value === "Conducted" ? 1 : 0;
      callCanceledFlag = value === "Cancelled" ? 1 : 0;
    }

    // Update editedLead state with updated flags
    setEditedProspect((prev) => ({
      ...prev,
      meetingConductedFlag,
      meetingCanceledFlag,
      callConductedFlag,
      callCanceledFlag,
    }));
  };

  const getCountries = async (country) => {
    try {
      const { data } = await Axios.get(
        `/masterdata/get_city?countryData=${country}`
      );
      setCityData(data.data);
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
  // Single Update
  const handleSubmitUpdate = async () => {
    const updateData = {
      ...editedProspect,
      DialCode: formData?.CountryData
        ? formData.CountryData.dialCode
        : editedProspect.DialCode, // Use the updated dial code
      Phone: formData?.Phone ? formData.Phone : editedProspect.Phone, // Use the updated phone number
      MeetingId: selectedProspect.MeetingId, 
      CallId: selectedProspect.CallId, 
    };
    try {
      const { data } = await Axios.put(
        `/prospects/singleupdate_prospect?userId=${JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          ...updateData,
          id: selectedProspect.id,
          userId: JSON.parse(localStorage.getItem("user")).userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      if (data?.success) {
        toast({
          title: "Prospect Updated Successfully!",
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        // Optionally, you may want to update the selectedProspect state here
        setSelectedProspect((prevSelectedProspect) => ({
          ...prevSelectedProspect,
          ...editedProspect,
        }));
        getData();
      } else {
        toast({
          title: data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      console.log(error.message);
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
  };
  //  Function to open the modal and fetch Prospect data for a particular ID
  const handleOpenModal = async (id, ProspectNumber) => { 
    const selectedProspect = prospectData.find(
      (prospect) => prospect.id === id
    );
    setSelectedProspect(selectedProspect);
    handleFullSizeClick();
    setSelectedRowId(id);
     // Access initial MeetingStatus and CallStatus
     const initialMeetingStatus = selectedProspect.MeetingStatus;
     const initialCallStatus = selectedProspect.CallStatus;
     console.log("Initial MS Prosp:", initialMeetingStatus, "Initial CS Prosp:", initialCallStatus);

     // Handle changes only if there is a change in MeetingStatus or CallStatus
     if (initialMeetingStatus !== "Conducted" && initialMeetingStatus !== "Cancelled") {
         // If MeetingStatus is not initially set, set the default flags
         const meetingConductedFlag = 0;
         const meetingCanceledFlag = 0;
         setEditedProspect((prev) => ({ ...prev, meetingConductedFlag, meetingCanceledFlag }));
     }

     if (initialCallStatus !== "Conducted" && initialCallStatus !== "Cancelled") {
         // If CallStatus is not initially set, set the default flags
         const callConductedFlag = 0;
         const callCanceledFlag = 0;
         setEditedProspect((prev) => ({ ...prev, callConductedFlag, callCanceledFlag }));
     }
    // const response = await Axios.get(
    //   `/prospectsMeeting/get_prospectmeeting?userId=${JSON.parse(localStorage.getItem("user")).userId}&ProspectNumber=${ProspectNumber}`
    // );
   
    // const selectedProspectMeetData = response.data.data;
  
    // const callResponse = await Axios.get(
    //   `/prospectsCall/get_prospectcall?userId=${JSON.parse(localStorage.getItem("user")).userId}&ProspectNumber=${ProspectNumber}`
    // );
    // const selectedCallData = callResponse.data.data;
    // if (selectedProspectMeetData && selectedProspectMeetData.length > 0) {
    //   const { MeetingId, MeetingDate, MeetingRemarks } = selectedProspectMeetData[0];
    //   // Format MeetingDate to display only the date part
    //   const formattedMeetingDate = moment(MeetingDate).format('YYYY-MM-DD');
    //   // Update state with fetched MeetingDate (only date part) and MeetingRemarks
    //   setEditedProspect(prevState => ({
    //     ...prevState,
    //     MeetingId,
    //     MeetingDate: formattedMeetingDate,
    //     MeetingRemarks
    //   }));
    // } 
    // if (selectedCallData && selectedCallData.length > 0) {
    //   const { CallId, CallDate, CallRemarks } = selectedCallData[0];
    //   // Format CallDate to display only the date part
    //   const formattedCallDate = moment(CallDate).format('YYYY-MM-DD');
    //   // Update state with fetched CallDate (only date part) and CallRemarks
    //   setEditedProspect(prevState => ({
    //     ...prevState,
    //     CallId,
    //     CallDate: formattedCallDate,
    //     CallRemarks
    //   }));
    // }
  };
  
  const handleCloseModal = () => {
    setSelectedRowId(null);
    setIsModalOpen(false);
    onClose(true);
  };
  useEffect(() => {
    if (checkBoxAll) {
      const a = Array.from({ length: pageNumbers }, (_, i) => i + 1).map(
        (number, i) => {
          return number;
        }
      );

      setActivePageCheckBoxSelect(a);
    }
  }, [pageNumbers, checkBoxAll]);
  const sortTable = (key) => {
    let newSortOrder = "asc";

    if (sortKey === key) {
      newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    }

    const sortedData = [...prospectData].sort((a, b) => {
      if (a[key] < b[key]) return sortOrder === "asc" ? -1 : 1;
      if (a[key] > b[key]) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });

    setSortKey(key);
    setSortOrder(newSortOrder);

    if (typeof setProspectData === "function") {
      setProspectData(sortedData);
    }
  };
  console.log(cityData);
  // Single Edit Module
  const handleFullSizeClick = () => {
    setSize("xl");
    onOpen();
  };
  const style = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflowY: "scroll",
    overflowX: "hidden",
  };

  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "200",
  };

  const modalStyleUpdate = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "9999",
  };

  const closeModal = {
    position: "absolute",
    top: "3%",
    right: "5%",
    cursor: "pointer",
  };
  //console.log(citiesDataSet);
  // const [showFactCheck, setShowFactCheck] = useState(false);
  // console.log(checkBoxSelected, currentPage, activePageCheckBoxSelect);
  return (
    <div>
      {!isLoading ? (
        tableColumnsProspect?.length !== 0 ? (
          <table className="table-auto border-collapse border border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
            {/* Table header */}
            <thead className="flex-nowrap text-center">
              <tr>
                <th className="border border-slate-300 whitespace-nowrap text-left">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {false ? (
                      <input
                        type="checkbox"
                        style={{
                          cursor: "pointer",
                          transform: "scale(1.3)",
                          marginRight: "5px",
                        }}
                        checked={checkBoxAll}
                        onChange={() => {
                          handleCheckbox(data.map((item) => item.id));
                          setActivePageCheckBoxSelect((prev) => {
                            if (prev.includes(currentPage)) {
                              return prev.filter(
                                (item) => item !== currentPage
                              ); // Return the filtered array
                            } else {
                              return [...prev, currentPage]; // Return a new array with the added item
                            }
                          });

                          setCheckBoxAll((prev) => {
                            if (prev === true) {
                              setCheckBoxSelected([]);
                            }
                            return !prev;
                          });
                        }}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        style={{
                          cursor: "pointer",
                          transform: "scale(1.3)",
                          marginRight: "5px",
                        }}
                        checked={
                          checkBoxSelected.length === 0
                            ? false
                            : activePageCheckBoxSelect.includes(currentPage)
                        }
                        onChange={() => {
                          setActivePageCheckBoxSelect((prev) => {
                            if (prev.includes(currentPage)) {
                              return prev.filter(
                                (item) => item !== currentPage
                              );
                            } else {
                              return [...prev, currentPage];
                            }
                          });
                          const idsToAdd = data.map((item) => item.id);
                          setCheckBoxSelected((prev) => {
                            const currentData = new Set(prev);
                            if (
                              activePageCheckBoxSelect.includes(currentPage)
                            ) {
                              // If the page is selected, remove its IDs
                              idsToAdd.forEach((item) => {
                                currentData.delete(item);
                              });
                            } else {
                              // If the page is not selected, add its IDs
                              idsToAdd.forEach((item) => {
                                currentData.add(item);
                              });
                            }
                            return Array.from(currentData);
                          });
                        }}
                      />
                    )}

                    {/* &nbsp;
                    <button 
                    onClick={toggleIcon}
                    checked={selectAllPages}
                    onChange={handleSelectAllPages}
                    checkBoxAll={checkBoxAll}
                    >
                      {showFactCheck ? (
                        <MdOutlineFactCheck size={20} />
                      ) : (
                        <MdCheckBoxOutlineBlank size={20} />
                      )}
                    </button> */}
                  </div>
                </th>
                <th className="border border-slate-300 whitespace-nowrap text-center">
                  Edit
                </th>
                {tableColumnsProspect.map(
                  (column, index) =>
                    column.label !== "Id" &&
                    column.label !== "DialCode"  &&
                    column.label!=="CallId"&&
                    column.label!=="MeetingId"&&
                    column.label!="MeetingTimezone"&&
                    column.label!="MeetingCanceledFlag"&&
                    column.label!="MeetingConductedFlag"&&
                    column.label!="CallConductedFlag"&&
                    column.label!="CallCanceledFlag"&&
                    column.isVisible === true && (
                      <th
                        className="border border-slate-300 whitespace-nowrap text-left"
                        key={column.key}
                        style={{ textAlign: "left" }}
                        draggable
                        onDragStart={(event) => handleDragStart(event, index)}
                        onDragOver={handleDragOver}
                        onDrop={(event) => handleDrop(event, index)}
                      >
                        {column.key === "Source" ? (
                          <>
                            <span>Lead Source</span>
                            <BiSort
                              style={{
                                marginLeft: "160px",
                                marginTop: "-22px",
                                cursor: "pointer",
                              }}
                              onClick={() => sortTable(column.key)}
                            />
                          </>
                        ) : column.key === "Region" ? (
                          <>
                            <span>Internal Region</span>
                            <BiSort
                              style={{
                                marginLeft: "160px",
                                marginTop: "-22px",
                                cursor: "pointer",
                              }}
                              onClick={() => sortTable(column.key)}
                            />
                          </>
                        ) : (
                          <>
                            <span style={{ cursor: "all-scroll" }}>
                              {column.label}
                            </span>
                            <BiSort
                              style={{
                                marginLeft: "160px",
                                marginTop: "-22px",
                                cursor: "pointer",
                              }}
                              onClick={() => sortTable(column.key)}
                            />
                          </>
                        )}
                      </th>
                    )
                )}
              </tr>
            </thead>
            {/* Table body */}
            <tbody>
              {data?.length !== 0 ? (
                data.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    style={
                      selectedRowId === row.id
                        ? { backgroundColor: "#e2e8f0" }
                        : {}
                    }
                  >
                    <td>
                      <Tooltip
                        label="Select single row"
                        bg="green"
                        color="white"
                        hasArrow
                        placement="top-start"
                      >
                        {false ? (
                          <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            checked={checkBoxAll && true}
                            onChange={() => {
                              handleCheckbox(row?.id);
                            }}
                          />
                        ) : (
                          <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            onChange={() => {
                              handleCheckbox(row?.id);
                            }}
                            checked={
                              Array.isArray(checkBoxSelected) &&
                              checkBoxSelected?.includes(row.id)
                            }
                          />
                        )}
                      </Tooltip>
                    </td>
                    <td>
                      <Tooltip
                        label="Edit Row"
                        bg="green"
                        color="white"
                        hasArrow
                        placement="right-start"
                      >
                        <IconButton
                          icon={<MdEdit />}
                          onClick={() => handleOpenModal(row?.id, row?.ProspectNumber)}
                          boxSize={6}
                          size={"20px"}
                        />
                      </Tooltip>
                    </td>
                    {tableColumnsProspect.map(
                      (column, columnIndex) =>
                        column.label !== "Id" &&
                        column.label !== "DialCode"  &&
                        column.label!=="CallId"&&
                        column.label!=="MeetingId"&&
                        column.label!="MeetingTimezone"&&
                        column.label!="MeetingCanceledFlag"&&
                        column.label!="MeetingConductedFlag"&&
                        column.label!="CallConductedFlag"&&
                        column.label!="CallCanceledFlag"&&
                        column.isVisible === true && (
                          <td
                            key={`${rowIndex}-${columnIndex}`}
                            style={{
                              textAlign: "left",
                              justifyContent: "space-evenly",
                            }}
                          >
                            {column.key === "Email" ? (
                              <a href={`mailto:${row[column.key]}`}>
                                {row[column.key]}
                              </a>
                            ) : column.key === "Phone" ? (
                              <>
                                {/* {console.log(row)} */}+{row["DialCode"]}-
                                {row["Phone"]}
                              </>
                            ) : column.key === "FollowUpDate" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "CreatedAt" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ): column.key === "ChangedAt" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ) : column.key === "LeadNumber" ? (
                              row["LeadNumber"] ? (
                                <Badge size={25} colorScheme="green">
                                  {row[column.key]}
                                </Badge>
                              ) : (
                                <Badge colorScheme="red">No Action</Badge>
                              )
                            ) : column.key === "ConversionFlag" ? (
                              row[column.key] === 1 ? (
                                <Badge colorScheme="green">Converted</Badge>
                              ) : (
                                <Badge colorScheme="red">Not Converted</Badge>
                              )
                            ) : column.key === "DeletionFlag" ? (
                              row[column.key] === 1 ? (
                                <Badge colorScheme="red">Deleted</Badge>
                              ) : (
                                <Badge colorScheme="green">Not Deleted</Badge>
                              )
                            ) : column.key === "FollowUpStatus" ? (
                              row["FollowUpStatus"] ? ( // Make sure "FollowUpStatus" is correctly accessible in the row data
                                row["FollowUpStatus"] === "LeadConverted" ? (
                                  <Badge colorScheme="green">
                                    Lead Converted
                                  </Badge>
                                ) : row["FollowUpStatus"] === "LeadDeleted" ? (
                                  <Badge colorScheme="green">
                                    Lead Deleted
                                  </Badge>
                                ) : row["FollowUpStatus"] === "NoAction" ? (
                                  <Badge colorScheme="red">No Action</Badge>
                                ) : row["FollowUpStatus"] === "pending" ? (
                                  <Badge colorScheme="red">Pending</Badge>
                                ) : row["FollowUpStatus"] ===
                                  "SystemGenerated" ? (
                                  <Badge colorScheme="yellow">
                                    System Generated
                                  </Badge>
                                ) : null
                              ) : null
                            ) :
                              column.key === "MeetingDate" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ): column.key === "CallDate" ? (
                              row[column.key] ? (
                                new Date(row[column.key]).toLocaleDateString()
                              ) : (
                                ""
                              )
                            ):
                            (
                              row[column.key]
                            )}
                          </td>
                        )
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={tableColumnsProspect.length}
                    style={{ textAlign: "center" }}
                  >
                    No Data Found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          <h1 style={{ textAlign: "center", color: "black" }}>
            No Data Found!
          </h1>
        )
      ) : (
        <p>Loading...</p>
      )}
      <>
        {isOpen && (
          <div className={`singleEditModal ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                // width: 500,
                height: 500,
              }}
              cancel="X"
              allowAnyClick={true}
            >
              <header
                style={{ float: "left", fontSize: "20px", fontWeight: "bold" }}
              >
                Edit Prospect Information
              </header>
              <div
                onClick={() => {
                  handleCloseModal(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              {selectedProspect && Object.keys(selectedProspect).length > 0 && (
                <form>
                  <Badge
                    variant="solid"
                    colorScheme="red"
                    fontSize="12pt"
                    ml={5}
                  >
                    Prospect Number : {selectedProspect["ProspectNumber"]}
                  </Badge>
                  <br />

                  <Grid templateColumns="repeat(5, 1fr)" gap={4}>
                    <GridItem colSpan={2}>
                      <FormControl>
                        <FormLabel>
                          Prospect Owner<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Input
                          type="text"
                          name="ProspectOwner"
                          defaultValue={
                            formData["ProspectOwner"] ||
                            selectedProspect["ProspectOwner"]
                          }
                          value={editedProspect["ProspectOwner"] || ""}
                          onChange={handleChange}
                          disabled
                        />
                        <FormLabel>
                          Contact Person<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Input
                          type="text"
                          defaultValue={
                            formData["ContactPerson"] ||
                            selectedProspect["ContactPerson"]
                          }
                          value={editedProspect["ContactPerson"] || ""}
                          onChange={handleChange}
                          name="ContactPerson"
                          disabled
                        />
                        <FormLabel>Phone</FormLabel>
                        <PhoneInput
                          name="Phone"
                          // defaultValue={
                          //   formData["CountryData"]
                          //     /? `+${formData["CountryData"].dialCode} - ${formData["Phone"]}`
                          //     : `+${selectedProspect["DialCode"]} - ${selectedProspect["Phone"]}`
                          // }
                          value={
                            `+${editedProspect["DialCode"]} - ${editedProspect["Phone"]}` ||
                            ""
                          }
                          onChange={(value, countryData) =>
                            handleChangePhone(value, countryData)
                          }
                        />
                        <FormLabel>City</FormLabel>
                        <Select
                          defaultValue={selectedProspect["City"]}
                          value={editedProspect["City"] || ""}
                          onChange={handleChange}
                          name="City"
                        >
                          {cityData.length > 0 ? (
                            cityData.map((item) => (
                              <option
                                key={item.City_code}
                                value={item.City_name}
                              >
                                {item.City_name}
                              </option>
                            ))
                          ) : (
                            <option value="">No cities available</option>
                          )}
                        </Select>
                        <FormLabel>Tier</FormLabel>
                        <Select
                          name="Tier"
                          defaultValue={
                            formData["Tier"] || selectedProspect["Tier"]
                          }
                          value={editedProspect["Tier"] || ""}
                          onChange={handleChange}
                        >
                          {gradeData.map((item) => (
                            <option value={item.Tier}>{item.Tier}</option>
                          ))}
                        </Select>{" "}
                        <FormLabel>Follow Up Date</FormLabel>
                        <Input
                          className="cursor-pointer"
                          type="date"
                          name="FollowUpDate"
                          defaultValue={
                            (
                              editedProspect["FollowUpDate"] ||
                              formData["FollowUpDate"] ||
                              selectedProspect["FollowUpDate"] ||
                              ""
                            ).split("T")[0] || ""
                          }
                          onChange={handleChange}
                        />
                        <FormLabel>Follow Up Interval</FormLabel>
                        <Input
                          type="text"
                          defaultValue={
                            formData["FollowUpInterval"] ||
                            selectedProspect["FollowUpInterval"]
                          }
                          value={editedProspect["FollowUpInterval"] || ""}
                          onChange={handleChange}
                          name="FollowUpInterval"
                        />
                        <FormLabel>Region</FormLabel>
                        <Select
                          name="Region"
                          defaultValue={
                            formData["Region"] || selectedProspect["Region"]
                          }
                          value={editedProspect["Region"] || ""}
                          onChange={handleChange}
                        >
                          {regionData.map((item) => (
                            <option value={item.Region}>{item.Region}</option>
                          ))}
                        </Select>
                        <FormLabel>Lead Source</FormLabel>
                        <Select
                          name="Source"
                          defaultValue={
                            formData["Source"] || selectedProspect["Source"]
                          }
                          value={editedProspect["Source"] || ""}
                          onChange={handleChange}
                        >
                          {leadSourceData.map((item) => (
                            <option value={item.Source}>{item.Source}</option>
                          ))}
                        </Select>
                        <Badge float="left" marginTop="5">
                          Meeting Info
                        </Badge>
                        <br />
                        <br />
                        <FormLabel>Meeting Date</FormLabel>
                        <Input
                        type="text"
                        name="MeetingDate"
                        defaultValue={
                          (formData["MeetingDate"] || selectedProspect["MeetingDate"] || "").split("T")[0]
                        }
                        value={(editedProspect["MeetingDate"] || "").split("T")[0]}
                        onChange={handleChange}
                        disabled
                      />
                        <FormLabel>Meeting Status</FormLabel>
                        <Select
                          defaultValue={formData["MeetingStatus"] || selectedProspect["MeetingStatus"]}
                          value={editedProspect["MeetingStatus"] || ""}
                          onChange={handleChange}
                          name="MeetingStatus"
                        >
                          <option value="">Select Meeting Status</option>
                          <option value="Conducted">Conducted</option>
                          <option value="Cancelled">Cancelled</option>
                        </Select>

                        <Badge float="left" marginTop="5">
                          Call Info
                        </Badge>
                        <br />
                        <br />
                        <FormLabel>Call Date</FormLabel>
                        <Input
                          type="date"
                          name="CallDate"
                          defaultValue={
                            (
                              formData["CallDate"] ||
                              selectedProspect["CallDate"] ||
                              ""
                            ).split("T")[0] || ""
                          }
                          value={(editedProspect["CallDate"] || "").split("T")[0]}
                          onChange={handleChange}
                          disabled
                        />
                        <FormLabel>Call Status</FormLabel>
                        <Select
                          defaultValue={formData["CallStatus"] || selectedProspect["CallStatus"]}
                          value={editedProspect["CallStatus"] || ""}
                          onChange={handleChange}
                          name="CallStatus"
                        >
                          <option value="">Select Call Status</option>
                          <option value="Conducted">Conducted</option>
                          <option value="Cancelled">Cancelled</option>
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem colStart={4} colEnd={6}>
                      <FormControl>
                        <FormLabel>
                          Company<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Input
                          type="text"
                          name="Company"
                          defaultValue={
                            formData["Company"] || selectedProspect["Company"]
                          }
                          value={editedProspect["Company"] || ""}
                          onChange={handleChange}
                          disabled
                        />
                        <FormLabel>
                          Email<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Input
                          type="text"
                          name="Email"
                          defaultValue={
                            formData["Email"] || selectedProspect["Email"]
                          }
                          value={editedProspect["Email"] || ""}
                          onChange={handleChange}
                          disabled
                        />
                        <FormLabel>
                          Country<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Select
                          defaultValue={
                            formData["Country"] || selectedProspect["Country"]
                          }
                          value={editedProspect["Country"] || ""}
                          onChange={handleChange}
                          name="Country"
                          disabled={true}
                        >
                          {countryData.map((item) => (
                            <option
                              key={item.Phone_code}
                              value={item.Country_name}
                            >
                              {item.Country_name}
                            </option>
                          ))}
                        </Select>

                        <FormLabel>Industry</FormLabel>
                        <Select
                          name="Industry"
                          defaultValue={
                            formData["Industry"] || selectedProspect["Industry"]
                          }
                          value={editedProspect["Industry"] || ""}
                          onChange={handleChange}
                        >
                          {industryData.map((item) => (
                            <option value={item.Industry}>
                              {item.Industry}
                            </option>
                          ))}
                        </Select>
                        <FormLabel>Tags</FormLabel>
                        <Select
                          defaultValue={
                            formData["Tags"] || selectedProspect["Tags"]
                          }
                          value={editedProspect["Tags"] || ""}
                          onChange={handleChange}
                          name="Tags"
                        >
                          {tagData.map((item) => (
                            <option value={item.Tags}>{item.Tags}</option>
                          ))}
                        </Select>

                        <FormLabel>Remarks</FormLabel>
                        <Input
                          type="text"
                          defaultValue={
                            formData["Remarks"] || selectedProspect["Remarks"]
                          }
                          value={editedProspect["Remarks"] || ""}
                          onChange={handleChange}
                          name="Remarks"
                        />
                        <FormLabel>Follow Up Status</FormLabel>
                        <Input
                          type="text"
                          defaultValue={
                            formData["FollowUpStatus"] ||
                            selectedProspect["FollowUpStatus"]
                          }
                          value={editedProspect["FollowUpStatus"] || ""}
                          onChange={handleChange}
                          name="FollowUpStatus"
                        />
                        <FormLabel>Notification Days</FormLabel>
                        <Input
                          type="text"
                          defaultValue={
                            formData["NotificationDays"] ||
                            selectedProspect["NotificationDays"]
                          }
                          value={editedProspect["NotificationDays"] || ""}
                          onChange={handleChange}
                          name="NotificationDays"
                        />
                         <FormLabel marginTop="39">Meeting Remarks</FormLabel>
                        <Input
                          type="text"
                          name="MeetingRemarks"
                          defaultValue={
                            formData["MeetingRemarks"] || selectedProspect["MeetingRemarks"]
                          }
                          value={editedProspect["MeetingRemarks"] || ""}
                          onChange={handleChange}
                          disabled
                        />
                        <FormLabel marginTop="39">Call Remarks</FormLabel>
                        <Input
                          type="text"
                          name="CallRemarks"
                          defaultValue={
                            formData["CallRemarks"] || selectedProspect["CallRemarks"]
                          }
                          value={editedProspect["CallRemarks"] || ""}
                          onChange={handleChange}
                          disabled
                        />
                         <FormLabel>Meeting Time</FormLabel>
                        <Input
                          type="text"
                          name="MeetingTime"
                          defaultValue={
                            formData["MeetingTime"] || selectedProspect["MeetingTime"]
                          }
                          value={editedProspect["MeetingTime"] || ""}
                          onChange={handleChange}
                          disabled
                        />
                          <FormLabel>Call Time</FormLabel>
                        <Input
                          type="text"
                          name="CallTime"
                          defaultValue={
                            formData["CallTime"] || selectedProspect["CallTime"]
                          }
                          value={editedProspect["CallTime"] || ""}
                          onChange={handleChange}
                          disabled
                        />
                      </FormControl>
                    </GridItem>
                  </Grid>
                  {!selectedProspect && (
                    <p>Data not available for the selected Prospect.</p>
                  )}
                </form>
              )}
              {/* <ModalFooter> */}
              <Stack
                spacing={4}
                direction="row"
                align="center"
                float="right"
                marginTop={5}
              >
                <Button onClick={handleCloseModal}>Cancel</Button>
                <Button
                  colorScheme="messenger"
                  onClick={() => {
                    handleSubmitUpdate();
                    handleCloseModal();
                  }}
                >
                  Save
                </Button>
              </Stack>
              {/* </ModalFooter>
            </ModalBody>
          </ModalContent>
        </Modal> */}
            </Rnd>
          </div>
        )}
      </>
    </div>
  );
};

export default DraggableProspectTable;
