import { React, useEffect,useState } from 'react'
import  DraggableReportTable  from './DraggableReportTable'
import moment from 'moment-timezone';
import {
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react'
import "../../assets/css/container.css";
export const ReportTableCollapse = ({
    leadData,
    prospectData,
    meetData,
    callData,
    conductedmeetData,
    canceledmeetData,
    conductedcallData,
    canceledcallData,
    setleadData,
    setprospectData,
    setmeetData,
    setcallData,
    setconductedmeetData,
    setcanceledmeetData,
    setconductedcallData,
    setcanceledcallData,
    handleIconClick,
}) => {


    return (
        <div style={{ margin: '20px' }}>
            <Accordion defaultIndex={[0]} allowMultiple>
                <AccordionItem>
                    <h2>
                        <AccordionButton _expanded={{ bg: 'rgb(23 37 84)', color: 'white' }}>
                            <Box as="span" flex='1' textAlign='left'>
                                Leads Report
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    {/* <AccordionPanel pb={4}>
                   
                    {leadData && leadData.map((lead, index) => (
    <li key={index}>
        <p>Lead Number: {lead.LeadNumber}</p>
        <p>Company: {lead.Company}</p>
        <p>Email: {lead.Email}</p>
        <p>Contact Person: {lead.ContactPerson}</p>
        <p>City: {lead.City}</p>
        <p>Remarks: {lead.Remarks}</p>
        <p>CreatedAt: {lead.CreatedAt}</p>
    </li>
))}


</AccordionPanel> */}
<AccordionPanel pb={4}>
    {leadData && leadData.length > 0 ? (
        <table className="table-auto border-collapse border border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
            <thead className="flex-nowrap text-center">
                <tr>
                    <th className="border border-slate-300 whitespace-nowrap text-left">Lead Owner Name</th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">Lead Number</th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">Company</th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">Email</th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">Contact Person</th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">City</th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">Remarks</th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">CreatedAt</th>
                </tr>
            </thead>
            <tbody>
                {leadData.map((lead, index) => (
                    <tr key={index}>
                        <td>{lead.LeadOwnerName}</td>
                        <td>{lead.LeadNumber}</td>
                        <td>{lead.Company}</td>
                        <td>{lead.Email}</td>
                        <td>{lead.ContactPerson}</td>
                        <td>{lead.City}</td>
                        <td>{lead.Remarks}</td>
                        <td>{moment(lead.CreatedAt).format('YYYY-MM-DD')}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    ) : (
        <p>No lead data available.</p>
    )}
</AccordionPanel>


                </AccordionItem>


                <AccordionItem>
                    <h2>
                        <AccordionButton _expanded={{ bg: 'rgb(23 37 84)', color: 'white' }}>
                            <Box as="span" flex='1' textAlign='left'>
                                Prospects Report
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                    {prospectData && prospectData.length > 0 ? (
        <table className="table-auto border-collapse border border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
            <thead className="flex-nowrap text-center">
                <tr>
                <th className="border border-slate-300 whitespace-nowrap text-left">Prospect Owner Name</th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">Prospect Number</th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">Company</th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">Email</th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">Contact Person</th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">City</th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">Remarks</th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">CreatedAt</th>
                </tr>
            </thead>
            <tbody>
                {prospectData.map((prospect, index) => (
                    <tr key={index}>
                         <td>{prospect.ProspectOwnerName}</td>
                        <td>{prospect.ProspectNumber}</td>
                        <td>{prospect.Company}</td>
                        <td>{prospect.Email}</td>
                        <td>{prospect.ContactPerson}</td>
                        <td>{prospect.City}</td>
                        <td>{prospect.Remarks}</td>
                        <td>{moment(prospect.CreatedAt).format('YYYY-MM-DD')}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    ) : (
        <p>No prospect data available.</p>
    )}
                    </AccordionPanel>
                </AccordionItem>


                <AccordionItem>
    <h2>
        <AccordionButton _expanded={{ bg: 'rgb(23 37 84)', color: 'white' }}>
            <Box as="span" flex='1' textAlign='left'>
                Meeting Report
            </Box>
            <AccordionIcon />
        </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
    {meetData && meetData.length > 0 ? (
        <table className="table-auto border-collapse border border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
            <thead className="flex-nowrap text-center">
                <tr>
                <th className="border border-slate-300 whitespace-nowrap text-left">Lead Owner Name</th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">Company</th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">Date</th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">Time</th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">Remarks</th>
                   
                </tr>
            </thead>
            <tbody>
                {meetData.map((meeting, index) => (
                    <tr key={index}>
                        <td>{meeting.LeadOwnerName}</td>
                        <td>{meeting.Company}</td>
                        <td>{moment(meeting.MeetingDate).format('YYYY-MM-DD')}</td>
                        <td>{meeting.MeetingTime}</td>
                        <td>{meeting.MeetingRemarks}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    ) : (
        <p>No meeting data available.</p>
    )}


    </AccordionPanel>
</AccordionItem>




                <AccordionItem>
                    <h2>
                        <AccordionButton _expanded={{ bg: 'rgb(23 37 84)', color: 'white' }}>
                            <Box as="span" flex='1' textAlign='left'>
                                Call Report
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
    {callData && callData.length > 0 ? (
        <table className="table-auto border-collapse border border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
            <thead className="flex-nowrap text-center">
                <tr>
                <th className="border border-slate-300 whitespace-nowrap text-left">Lead Owner Name</th>
                <th className="border border-slate-300 whitespace-nowrap text-left">Company</th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">Date</th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">Time  </th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">Remarks</th>
                   
                </tr>
            </thead>
            <tbody>
                {callData.map((calls, index) => (
                    <tr key={index}>
                        <td>{calls.LeadOwnerName}</td>
                        <td>{calls.Company}</td>
                        <td>{moment(calls.CallDate).format('YYYY-MM-DD')}</td>
                        <td>{calls.CallsTime}</td>
                        <td>{calls.CallRemarks}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    ) : (
        <p>No call data available.</p>
    )}

    </AccordionPanel>
                </AccordionItem>

{/* conductedmeeting table */}
<AccordionItem>
                    <h2>
                        <AccordionButton _expanded={{ bg: 'rgb(23 37 84)', color: 'white' }}>
                            <Box as="span" flex='1' textAlign='left'>
                                Meeting Report - Conducted
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
    {conductedmeetData && conductedmeetData.length > 0 ? (
        <table className="table-auto border-collapse border border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
            <thead className="flex-nowrap text-center">
                <tr>
                <th className="border border-slate-300 whitespace-nowrap text-left">Lead Owner Name</th>
                <th className="border border-slate-300 whitespace-nowrap text-left">Company</th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">Date</th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">Time  </th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">Remarks</th>
                   
                </tr>
            </thead>
            <tbody>
                {conductedmeetData.map((conductedmeets, index) => (
                    <tr key={index}>
                        <td>{conductedmeets.LeadOwnerName}</td>
                        <td>{conductedmeets.Company}</td>
                        <td>{moment(conductedmeets.MeetingDate).format('YYYY-MM-DD')}</td>
                        <td>{conductedmeets.MeetingTime}</td>
                        <td>{conductedmeets.MeetingRemarks}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    ) : (
        <p>No conducted meeting data available.</p>
    )}

    </AccordionPanel>
                </AccordionItem>


{/* canceledmeeting table */}
<AccordionItem>
                    <h2>
                        <AccordionButton _expanded={{ bg: 'rgb(23 37 84)', color: 'white' }}>
                            <Box as="span" flex='1' textAlign='left'>
                                Meeting Report - Cancelled
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
    {canceledmeetData && canceledmeetData.length > 0 ? (
        <table className="table-auto border-collapse border border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
            <thead className="flex-nowrap text-center">
                <tr>
                <th className="border border-slate-300 whitespace-nowrap text-left">Lead Owner Name</th>
                <th className="border border-slate-300 whitespace-nowrap text-left">Company</th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">Date</th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">Time  </th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">Remarks</th>
                   
                </tr>
            </thead>
            <tbody>
                {canceledmeetData.map((canceledmeets, index) => (
                    <tr key={index}>
                        <td>{canceledmeets.LeadOwnerName}</td>
                        <td>{canceledmeets.Company}</td>
                        <td>{moment(canceledmeets.MeetingDate).format('YYYY-MM-DD')}</td>
                        <td>{canceledmeets.MeetingTime}</td>
                        <td>{canceledmeets.MeetingRemarks}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    ) : (
        <p>No canceled meeting data available.</p>
    )}

    </AccordionPanel>
                </AccordionItem>

{/* conductedcall table */}
<AccordionItem>
                    <h2>
                        <AccordionButton _expanded={{ bg: 'rgb(23 37 84)', color: 'white' }}>
                            <Box as="span" flex='1' textAlign='left'>
                                Call Report - Conducted
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
    {conductedcallData && conductedcallData.length > 0 ? (
        <table className="table-auto border-collapse border border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
            <thead className="flex-nowrap text-center">
                <tr>
                <th className="border border-slate-300 whitespace-nowrap text-left">Lead Owner Name</th>
                <th className="border border-slate-300 whitespace-nowrap text-left">Company</th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">Date</th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">Time  </th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">Remarks</th>
                   
                </tr>
            </thead>
            <tbody>
                {conductedcallData.map((conductedcalls, index) => (
                    <tr key={index}>
                        <td>{conductedcalls.LeadOwnerName}</td>
                        <td>{conductedcalls.Company}</td>
                        <td>{moment(conductedcalls.CallDate).format('YYYY-MM-DD')}</td>
                        <td>{conductedcalls.CallsTime}</td>
                        <td>{conductedcalls.CallRemarks}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    ) : (
        <p>No conducted call data available.</p>
    )}

    </AccordionPanel>
                </AccordionItem>


{/* canceledmeeting table */}
<AccordionItem>
                    <h2>
                        <AccordionButton _expanded={{ bg: 'rgb(23 37 84)', color: 'white' }}>
                            <Box as="span" flex='1' textAlign='left'>
                                Call Report - Cancelled
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
    {canceledcallData && canceledcallData.length > 0 ? (
        <table className="table-auto border-collapse border border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
            <thead className="flex-nowrap text-center">
                <tr>
                <th className="border border-slate-300 whitespace-nowrap text-left">Lead Owner Name</th>
                <th className="border border-slate-300 whitespace-nowrap text-left">Company</th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">Date</th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">Time  </th>
                    <th className="border border-slate-300 whitespace-nowrap text-left">Remarks</th>
                   
                </tr>
            </thead>
            <tbody>
                {canceledcallData.map((canceledcalls, index) => (
                    <tr key={index}>
                        <td>{canceledcalls.LeadOwnerName}</td>
                        <td>{canceledcalls.Company}</td>
                        <td>{moment(canceledcalls.CallDate).format('YYYY-MM-DD')}</td>
                        <td>{canceledcalls.CallsTime}</td>
                        <td>{canceledcalls.CallRemarks}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    ) : (
        <p>No canceled call data available.</p>
    )}

    </AccordionPanel>
                </AccordionItem>

            </Accordion>
        </div>
    );
}

