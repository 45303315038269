import React from "react";
import { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Button,
} from "@chakra-ui/react";
import { MdDownloading } from "react-icons/md";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Header from "../Header/Header";

const ProspectTemplate = () => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadComplete, setDownloadComplete] = useState(false);
  const tableColumns = [
    "Company",
    "Email",
    "ContactPerson",
    "DialCode",
    "Phone",
    "Country",
    "City",
    "Industry",
    "Remarks",
    "Tier",
    "Tags",
    "Region",
    "Source",
    "FollowUpDate",
  ];

  const handleExportTemplate = () => {
    setIsDownloading(true);

    // Simulate an asynchronous download process
    setTimeout(() => {
      const prospectTemplate = [tableColumns];

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet(prospectTemplate);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Prospect Template");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const dataBlob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const currentDate = new Date().toISOString().split("T")[0];
      const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
      const finalFileName = `ProspectTemplate_${currentDate}_${currentTime}.xlsx`;

      saveAs(dataBlob, finalFileName);

      setIsDownloading(false);
      setDownloadComplete(true);
    }, 2000); // Adjust this timeout to match your actual download process
  };
  return (
    <>
      <Header />
      <Card align="center" mt={10}>
        <CardHeader>
          <Heading size="md">Prospect Template</Heading>
        </CardHeader>
        <CardBody>
          <Text>
            To access and utilize the prospect template, please click the
            following link to export the template
          </Text>
        </CardBody>
        <CardFooter>
          <Button
            leftIcon={<MdDownloading />}
            colorScheme="teal"
            aria-label="Export here"
            size="md"
            onClick={handleExportTemplate}
            isDisabled={isDownloading}
          >
            {isDownloading ? "Downloading..." : "Export Template"}
          </Button>
          {downloadComplete && (
            <Text mt={2} color="green">
              Download completed!
            </Text>
          )}
        </CardFooter>
      </Card>
    </>
  );
};

export default ProspectTemplate;
