export class Checkvalidation {
  constructor(
    checkBoxSelected,
    toast,
    onOpenMassupdate,
    onOpenReportDelete,
    onOpenReportLeadConvert,
    handleOpenEditor,
    onOpenReportOutlookEmail,
    check_leadMeet,
    check_leadCall,
  ) {
    this.checkBoxSelected = checkBoxSelected;
    this.toast = toast;
    this.onOpenMassupdate = onOpenMassupdate;
    this.onOpenReportDelete = onOpenReportDelete;
    this.onOpenReportLeadConvert = onOpenReportLeadConvert;
    this.handleOpenEditor = handleOpenEditor;
    this.onOpenReportOutlookEmail = onOpenReportOutlookEmail;
    this.check_leadMeet = check_leadMeet;
    this.check_leadCall = check_leadCall;
  }

  handleEditClickMassupdate() {
    if (this.checkBoxSelected.length === 0) {
      console.log("No row selected for deletion.");
      this.toast({
        title: "Please select a row",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
          bottom:'0px'
        },
      });
      return;
    }
    // If checkBoxSelected has at least one item selected continue with
    this.onOpenMassupdate();
  }

  handleEditClickMassdelete() {
    if (this.checkBoxSelected.length === 0) {
      console.log("No row selected for deletion.");
      this.toast({
        title: "Please select a row",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }
    this.onOpenReportDelete();
  }

  handleEditClickMassleadtoprospect() {
    if (this.checkBoxSelected.length === 0) {
      console.log("No row selected for deletion.");
      this.toast({
        title: "Please select a row",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }

    // If checkBoxSelected is true, you can continue with the  action.
    this.onOpenReportLeadConvert();
  }

  handleEditClickMassmail() {
    if (this.checkBoxSelected.length === 0) {
      console.log("No row selected for deletion.");
      this.toast({
        title: "Please select a row",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }
    // If checkBoxSelected is true, you can continue with the  action.
    this.handleOpenEditor();
  }

  handleEditClickOutlookMail() {
    if (this.checkBoxSelected.length === 0) {
      console.log("No row selected for deletion.");
      this.toast({
        title: "Please select a row",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }
    // If checkBoxSelected is true, you can continue with the  action.
    this.onOpenReportOutlookEmail();
  }

  handleEditClickMeeting() {
    if (this.checkBoxSelected.length === 0) {
      console.log("No row selected for editing.");
      this.toast({
        title: "Please select a row",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    } else if (this.checkBoxSelected.length > 1) {
      console.log("Select only one row for meeting.");
      this.toast({
        title: "Select only one row for meeting",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }
    // If checkBoxSelected is true and only one row is selected, you can continue with the action.
    this.check_leadMeet();
  }
  

  handleEditClickCalls() {
    if (this.checkBoxSelected.length === 0) {
      console.log("No row selected for call.");
      this.toast({
        title: "Please select a row",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    } else if (this.checkBoxSelected.length > 1) {
      console.log("Select only one row for call.");
      this.toast({
        title: "Select only one row for call",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return;
    }
    // If checkBoxSelected is true, you can continue with the  action.
    this.check_leadCall();
  }
 
}
