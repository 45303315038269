import { React, useState } from "react";
import MXM from "../../assets/img/MXM.mp4";
import { useNavigate } from "react-router-dom";
import Axios from "../../api-instance";
import { Tooltip, useToast } from "@chakra-ui/react";
import { BiShow, BiHide } from "react-icons/bi";
import "../../assets/css/login.css";

const Restpassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const toast = useToast();
  return (
    <>
      <div className="p-4 flex flex-shrink w-full bg-slate-200">
        <div className="rounded-2xl flex flex-shrink shadow-md sm:flex-row flex-col w-full">
          <div className="flex flex-1 w-full ">
            {/* <img
          className="object-cover overflow-hidden rounded-tl-2xl rounded-bl-2xl w-full"
          src={MXM}
          alt="image"
        /> */}
            <div className="container-control">
              <div className="rotating-text-wrapper">
                <h2>Welcome to Matrix Mindz</h2>
              </div>
              <video className="h-screen video-control" autoPlay muted loop>
                <source src={MXM} type="video/mp4" />
              </video>
            </div>
          </div>
          <div className="flex flex-1 flex-col justify-evenly  p-3.5 min-w-0">
            <div className="px-8 md:px-16">
              <form className="font-sans text-sm rounded w-full max-w-xs mx-auto pb-8">
                <label className="ml-2 text-md font-medium whitespace-nowrap">
                  Rest Password?
                </label>
                <Tooltip
                  hasArrow
                  label="New Password"
                  placement="right-end"
                  bg="gray.300"
                  color="black"
                >
                  <div className="relative border rounded mb-4 shadow appearance-none label-floating">
                    <input
                      className="w-full py-2 px-3 bg-slate-200 text-base leading-normal rounded cursor-pointer"
                      type="text"
                      placeholder="Enter your new password"
                      id="email"
                      value={email}
                    />
                  </div>
                </Tooltip>
                <Tooltip
                  hasArrow
                  label="Confirm Password"
                  placement="right-end"
                  bg="gray.300"
                  color="black"
                >
                  <div className="relative border rounded mb-4 shadow appearance-none label-floating">
                    <input
                      className="w-full py-2 px-3 bg-slate-200 text-base leading-normal rounded cursor-pointer"
                      type="text"
                      placeholder="Enter your confirm password"
                      id="email"
                      value={email}
                    />
                  </div>
                </Tooltip>
                <br />
                <div className="text-xs  text-[#002D74]">
                  <br />
                  <button
                    type="submit"
                    className="bg-[#172554] w-48 font-medium rounded-xl text-base text-white py-2 hover:scale-105 duration-300"
                  >
                    Submit
                  </button>
                </div>
              </form>
              <div className="mt-3 text-xs flex justify-between items-center text-[#002D74]"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Restpassword;
