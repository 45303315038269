import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import {
  Addenquiry,
  Addlead,
  Addprospect,
  AddVendor,
  Dashboard,
  Lead,
  Login,
  Prospect,
  Register,
  Industry,
  Vendor,
  // Emailverify,
  Addrate,
  Emailverify,
  Checkmail,
  Restpassword,
  // Emailverify,
  Report,
  Home,
  Job,
  IncompleteTask,
  Update,
  Booking,
  Addbooking
} from "../page";
import {
  Userprofile,
  LeadTemplate,
  ProspectTemplate,
  Error,
} from "../components";
import { useEffect } from "react";

export default function RootRouter() {
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (window.location.pathname === '/' || window.location.pathname === '') {
  //    return navigate('/dashboard')
  //   }
  // }, [navigate])

  return (
    <Routes>
      <Route
        path="/"
        element={
        <Navigate to="/dashboard" replace />}
      />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgetpassword" element={<Emailverify />} />
      <Route path="/otpverfiy" element={<Checkmail />} />
      <Route path="/restpassword" element={<Restpassword />} />

      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/lead"
        element={
          <ProtectedRoute>
            <Lead />
          </ProtectedRoute>
        }
      />
      <Route
        path="/prospect"
        element={
          <ProtectedRoute>
            <Prospect />
          </ProtectedRoute>
        }
      />

<Route
        path="/booking"
        element={<ProtectedRoute> 
          {/* <Booking /> */}
          </ProtectedRoute>}
      />

<Route
        path="/addbooking"
        element={<ProtectedRoute> 
          {/* <Addbooking /> */}
          </ProtectedRoute>}
      />

      <Route
        path="/report"
        element={
          <ProtectedRoute>
            <Report />
          </ProtectedRoute>
        }
      />
      <Route
        path="/opertion_home"
        element={
          <ProtectedRoute>
            {/* <Home /> */}
          </ProtectedRoute>
        }
      />
      <Route
        path="/opertion_job"
        element={
          <ProtectedRoute>
            {/* <Job /> */}
          </ProtectedRoute>
        }
      />

      <Route
        path="/opertion_incomplete_task"
        element={
          <ProtectedRoute>
            {/* <IncompleteTask /> */}
          </ProtectedRoute>
        }
      />

      <Route
        path="/opertion_update"
        element={
          <ProtectedRoute>
            {/* <Update /> */}
          </ProtectedRoute>
        }
      />

      <Route
        path="/enquiry"
        element={<ProtectedRoute>{/* <Enquiry /> */}</ProtectedRoute>}
      />
      <Route
        path="/vendor"
        element={<ProtectedRoute>{/* <Vendor /> */}</ProtectedRoute>}
      />


      <Route path="/addlead" element={<Addlead />} />
      <Route path="/addprospect" element={<Addprospect />} />
      <Route path="/addenquiry" element={<Addenquiry />} />
      <Route path="/addvendor" element={<AddVendor />} />
      {/* <Route path="/addindustry" element={<Addindustry />} /> */}
      <Route path="/addrate" element={<Addrate />} />
      <Route path="/userprofile" element={<Userprofile />} />
      <Route path="/leadtemplate" element={<LeadTemplate />} />
      <Route path="/prospecttemplate" element={<ProspectTemplate />} />
      <Route path="*" element={<Error />} />
    </Routes>
  );
}
