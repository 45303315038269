import React from "react";
import "./error.css"; // Assuming you have a file named "error.css" for your styles
import error_img from "../../assets/img/error_img.png";

const Error = () => {
  return (
    <div className="bg-slate-200 h-screen">
      <div className="error">
        <img
          src={error_img}
          width="50%"
          height="100"
          alt="404"
          className="imgerror"
        />
        <h1>Oops, looks like the page is lost.</h1>
        <p>This is not a fault, just an accident that was not intentional.</p>
        <p>Contact : Gopi & Manali</p>
        <p>All Rights Reserved © Matrix Mindz</p>
      </div>
    </div>
  );
};

export default Error;
