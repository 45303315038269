import React, { useState, useEffect, useRef } from "react";
import Axios from "../api-instance";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const toast = useToast();
  const navigate = useNavigate();

  const toastRef = useRef();
  const token = localStorage.getItem("token");

  const [isValidUser, setIsValidUser] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        if (!token) {
          setIsValidUser(false);
          navigate("/login");
        }
        const { data } = await Axios.get("/token", {
          headers: { Authorization: JSON.parse(token) },
        });

        if (data.success) {
          setIsValidUser(true);
        } else {
          navigate("/login");
        }
      } catch (error) {
        if (error.response?.data === "Unauthorized") {
          navigate("/login");
        }
        // toast.close(toastRef.current);
        // toastRef.current = toast({
        //   title: error.response?.data,
        //   position: "bottom-center",
        //   isClosable: true,
        //   status: "error",
        //   containerStyle: {
        //     width: "98.5vw",
        //     maxWidth: "98.5vw",
        //     alignContent: "center",
        //   },
        // });
      }
    };
    getData();
  }, []);

  return <div>{isValidUser && children}</div>;
};

export default ProtectedRoute;
