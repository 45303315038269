import { useEffect, useState } from "react";

export default function useLocalStorage(name, initialValue) {
  const [value, setValue] = useState(() => {
    const jsonValue = localStorage.getItem(name);
    if (jsonValue != null) return JSON.parse(jsonValue);
    if (typeof initialValue === "function") {
      return initialValue();
    } else {
      return initialValue;
    }
  });

  useEffect(() => {
    localStorage.setItem(name, JSON.stringify(value));
  }, [name, value]);

  return [value, setValue];
}
