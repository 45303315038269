const constant = {
  // port: "http://13.233.117.132/service",
  //port: "http://3.108.227.64/service", //Live 
  //   // port: "http://3.110.174.248/service"
  //port: "http://13.233.117.132/service",
  // dashbordEndpoint:"http://localhost:5003",
  // prospectEndpoint: "http://localhost:5006",
  // port: "http://localhost:5006",
  // masterDataEndpoint: "http://localhost:5012"
 // port :"http://52.66.245.28/service/"
  //  port: "http://localhost:5000",
    port: "https://matrixmindz.com/service",
  //   port2: "http://3.110.174.248/service/prospects",
  //   port: "http://3.110.174.248/service/leads",
  //   // port: "https://b6ab-117-222-138-174.ngrok-free.app"
};
// const constants = {
//   prospectEndpoint: "http://3.110.174.248/service/prospects",
//   leadEndpoint: "http://3.110.174.248/service/leads",
// };

export default constant;



//Leads Page API Should be change
//  /leads/leads/get_lead http://13.233.117.132/

