/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import Draggable from "react-draggable";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FileSaver } from "file-saver";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { read, utils, writeFile } from "xlsx";
import { color } from "framer-motion";
import moment, { utc } from "moment/moment";
import { Rnd } from "react-rnd";

// External module
import Axios from "../../api-instance.js";
import "../../assets/css/container.css";
import { DraggableProspectTable } from "../../components";
import { Header } from "../../components";
import { Checkvalidation } from "./helpers/Checkvalidation";
import { Massupload } from "./helpers/Massupload";
// Internal modules
import "./Prospect.css";
import Selectallpages from "./Selectallpages";

// Chakara ui modules
import { useDisclosure } from "@chakra-ui/react";
import { Flex, Select, useToast, Box, Text, Tooltip } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerFooter,
  DrawerBody,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from "@chakra-ui/react";
import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
// Icons import
import { MdDownloading } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { HiOutlineMail } from "react-icons/hi";
import { LiaFileUploadSolid } from "react-icons/lia";
import { MdOutlineDownloadForOffline } from "react-icons/md";
import { GoMoveToEnd } from "react-icons/go";
import { FaFilter } from "react-icons/fa";
import { TbAdjustmentsHorizontal } from "react-icons/tb";
import { BiSolidMinusCircle } from "react-icons/bi";
import { IoMdAddCircle } from "react-icons/io";
import { FiArrowLeftCircle } from "react-icons/fi";
import { FiArrowRightCircle } from "react-icons/fi";
import { BsFiletypeExe } from "react-icons/bs";
import { SiMicrosoftoutlook } from "react-icons/si";
import { MdSelectAll } from "react-icons/md";
import { CgClose } from "react-icons/cg";
import { BiHide } from "react-icons/bi";
import { BiShow } from "react-icons/bi";
import { MdAddCall } from "react-icons/md";
import { SiGooglemeet } from "react-icons/si";
import { MdOutlineVideoCall } from "react-icons/md";
import { MdOutlineAddIcCall } from "react-icons/md";
const Prospect = () => {
  const navigate = useNavigate();
  const [size, setSize] = React.useState("xl");
  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );
  const [scrollBehavior, setScrollBehavior] = React.useState("inside");
  const [overlay, setOverlay] = React.useState(<OverlayOne />);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const {
    isOpen: isOpenDownload,
    onOpen: onOpenDownload,
    onClose: onCloseDownload,
  } = useDisclosure();
  const {
    isOpen: isOpenManagecolumn,
    onOpen: onOpenManagecolumn,
    onClose: onCloseManagecolumn,
  } = useDisclosure();
  const {
    isOpen: isOpenFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();
  const {
    isOpen: isOpenReportDelete,
    onOpen: onOpenReportDelete,
    onClose: onCloseReportDelete,
  } = useDisclosure();
  const {
    isOpen: isOpenMassupdate,
    onOpen: onOpenMassupdate,
    onClose: onCloseMassupdate,
  } = useDisclosure();
  const {
    isOpen: isOpenReportMassupdate,
    onOpen: onOpenReportMassupdate,
    onClose: onCloseReportMassupdate,
  } = useDisclosure();
  const {
    isOpen: isOpenReportMassUpload,
    onOpen: onOpenReportMassUpload,
    onClose: onCloseReportMassUpload,
  } = useDisclosure();
  const {
    isOpen: isOpenReportMassEmail,
    onOpen: onOpenReportMassEmail,
    onClose: onCloseReportMassEmail,
  } = useDisclosure();
  const {
    isOpen: isOpenReportOutlookEmail,
    onOpen: onOpenReportOutlookEmail,
    onClose: onCloseReportOutlookEmail,
  } = useDisclosure();

  const {
    isOpen: isOpenMeetingProspect,
    onOpen: onOpenMeetingProspect,
    onClose: onCloseMeetingProspect,
  } = useDisclosure();

  const {
    isOpen: isOpenCallsProspect,
    onOpen: onOpenCallsProspect,
    onClose: onCloseCallsProspect,
  } = useDisclosure();

  // Prospect Top
  const toast = useToast();
  const searchRef = useRef(null);
  const [isActionOpen, setActionOpen] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isManagecolumnOpen, setManagecolumnOpen] = useState(false);
  const [prospectData, setProspectData] = useState([]);
  const [selectAllPages, setSelectAllPages] = useState(false);
  const [checkBoxAll, setCheckBoxAll] = useState(false);
  const [exportData, setExportData] = useState([]);
  const cancelRef = React.useRef();
  const [prospectnumber, setProspectNumber] = useState([]);
  const [movies, setMovies] = useState([]);
  const [checkBoxSelected, setCheckBoxSelected] = useState([]);
  const [excelOpen, setExcelOpen] = useState(false);
  const [handleCheckboxFilter, setHandleCheckboxFilter] = useState("OR");
  const [updatedConditions, setUpdatedConditions] = useState([]);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [columns2, setColumns2] = useState([]);
  const [data, setData] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadComplete, setDownloadComplete] = useState(false);
  const [prospectWarning, setProspectWarning] = useState(false);
  const [selectedExportOption, setSelectedExportOption] = useState("");
  const [selectedOption, setSelectedOption] = useState("Select a field");
  const [updatedValue, setUpdatedValue] = useState("");
  const [istoOpen, setIsToOpen] = useState(false);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [isdelete, setIsdelete] = useState(false);
  const [isconvert, setIsconvert] = useState(false);
  // Column Toggle
  const [columns, setColumns] = useState({
    id: true,
    ProspectNumber: true,
    Company: true,
    ContactPerson: true,
    DialCode: true,
    Phone: true,
    Email: true,
    Country: true,
    State: false,
    ProspectOwner: true,
    ProspectOwnerName: true,
    City: true,
    Tier: true,
    Tags: true,
    Industry: true,
    Region: true,
    Source: true,
    FollowUpDate: true,
    FollowUpStatus: true,
    NotificationDays: true,
    FollowUpInterval: true,
    LeadNumber: true,
    DeletionFlag: true,
    ConversionFlag: true,
    Remarks: true,
    CreatedAt: true,
    ChangedAt: true,
    CallId:true,
    MeetingId:true,
    MeetingDate:true,
    MeetingTimezone:true,
    MeetingTime: true,
    MeetingRemarks:true,
    MeetingStatus:true,
    CallDate:true,
    CallTime:true,
    CallRemarks:true,
    CallStatus:true,
    meetingCanceledFlag:true,
    meetingConductedFlag:true,
    callConductedFlag:true,
    callCanceledFlag:true,
  });
  const [tableColumnsProspect, settableColumnsProspect] = useState([]);

  useEffect(() => {
    getData();
  }, [isdelete, isconvert]); // isdelete state changes

  const handleRecordDelete = () => {
    setIsdelete(!isdelete);
  };
  const handleRecordConvert = () => {
    setIsconvert(!isconvert);
  };
  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    setHandleCheckboxFilter(value);
  };


  // Export data

  const handleExportOptionChange = (e) => {
    setSelectedExportOption(e.target.value);
    setDownloadComplete(false);
  };
  const handleExport = () => {
    setIsDownloading(true);
    setDownloadComplete(false);

    if (selectedExportOption === "Excel") {
      // Simulate the download process with a setTimeout (you should use your actual export functions here)
      setTimeout(() => {
        exportToExcel(exportData, "Prospect");
        setIsDownloading(false);
        setDownloadComplete(true);
      }, 2000); // 2 seconds delay for demonstration purposes
    } else if (selectedExportOption === "CSV") {
      setTimeout(() => {
        exportToCSV(exportData, "Prospect");
        setIsDownloading(false);
        setDownloadComplete(true);
      }, 2000);
    } else if (selectedExportOption === "PDF") {
      setTimeout(() => {
        exportToPDF(exportData, "Prospect");
        setIsDownloading(false);
        setDownloadComplete(true);
      }, 2000);
    } else if (selectedExportOption === "Text") {
      setTimeout(() => {
        exportToText(exportData, "Prospect");
        setIsDownloading(false);
        setDownloadComplete(true);
      }, 2000);
    }
  };
  const exportToExcel = (data, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const dataArray = data.map((item) => [
      item.ProspectNumber,
      item.Company,
      item.ContactPerson,
      item.Email,
      item.Country,
      //item.State,
      item.City,
      item.DialCode,
      item.Phone,
      item.ProspectOwner,
      item.ProspectOwnerName,
      item.Industry,
      item.Tier,
      item.Tags,
      item.Region,
      item.Source,
      item.FollowUpDate,
      item.FollowUpStatus,
      item.NotificationDays,
      item.FollowUpInterval,
      item.Remarks,
    ]);
    const ws = XLSX.utils.aoa_to_sheet([
      [
        "ProspectNumber",
        "Company",
        "ContactPerson",
        "Email",
        "Country",
        //"State",
        "City",
        "DialCode",
        "Phone",
        "ProspectOwner",
        "ProspectOwnerName",
        "Industry",
        "Tier",
        "Tags",
        "Region",
        "Source",
        "FollowUpDate",
        "FollowUpStatus",
        "NotificationDays",
        "FollowUpInterval",
        "Remarks",
      ],
      ...dataArray,
    ]);
    console.log(exportToExcel);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Prospects");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: fileType });
    //const uniqueId = Date.now();
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `${fileName}_${currentDate}_${currentTime}${fileExtension}`;

    saveAs(dataBlob, finalFileName);
  };
  const exportToCSV = (data, fileName) => {
    const dataArray = data.map((item) => [
      item.ProspectNumber,
      item.Company,
      item.ContactPerson,
      item.Email,
      item.Country,
      //item.State,
      item.City,
      item.DialCode,
      item.Phone,
      item.ProspectOwner,
      item.ProspectOwnerName,
      item.Industry,
      item.Tier,
      item.Tags,
      item.Region,
      item.Source,
      item.FollowUpDate,
      item.FollowUpStatus,
      item.NotificationDays,
      item.FollowUpInterval,
      item.Remarks,
    ]);

    const csvContent = [
      [
        "ProspectNumber",
        "Company",
        "ContactPerson",
        "Email",
        "Country",
        //"State",
        "City",
        "DialCode",
        "Phone",

        "ProspectOwner",
        "ProspectOwnerName",
        "Industry",
        "Tier",
        "Tags",
        "Region",
        "Source",
        "FollowUpDate",
        "FollowUpStatus",
        "NotificationDays",
        "FollowUpInterval",
        "Remarks",
      ],
      ...dataArray.map((row) => row.join(",")),
    ].join("\n");

    const fileType = "text/csv;charset=utf-8;";
    const fileExtension = ".csv";

    const csvDataBlob = new Blob([csvContent], {
      type: fileType,
    });

    //const uniqueId = Date.now();
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `${fileName}_${currentDate}_${currentTime}${fileExtension}`;

    saveAs(csvDataBlob, finalFileName);
  };
  // Make sure to import 'jsPDF' and 'jspdf-autotable' libraries before using this code

  const exportToPDF = (data, fileName) => {
    const unit = "pt";
    const size = "A2";
    const orientation = "portrait";

    const doc = new jsPDF(orientation, unit, size);
    const tableColumnsProspect = [
      "ProspectNumber",
      "Company",
      "ContactPerson",
      "Email",
      "Country",
      //"State",
      "City",
      "DialCode",
      "Phone",

      "ProspectOwner",
      "ProspectOwnerName",
      "Industry",
      "Tier",
      "Tags",
      "Region",
      "Source",
      "FollowUpDate",
      "FollowUpStatus",
      "NotificationDays",
      "FollowUpInterval",
      "Remarks",
    ];

    const tableRows = data.map((item) => {
      return tableColumnsProspect.map((column) => item[column]);
    });

    const marginLeft = 40;
    const marginTop = 40;
    const titleFontSize = 15;

    doc.setFontSize(titleFontSize);

    const title = "Prospects Report";
    doc.text(title, marginLeft, marginTop);

    const tableOptions = {
      startY: marginTop + titleFontSize + 20,
      head: [tableColumnsProspect],
      body: tableRows,
      didDrawCell: (data) => {
        doc.rect(
          data.cell.x,
          data.cell.y,
          data.cell.width,
          data.cell.height,
          "S"
        );
      },
    };

    doc.autoTable(tableOptions);

    //const uniqueId = Date.now();
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `${fileName}_${currentDate}_${currentTime}.pdf`;

    doc.save(finalFileName);
  };
  const exportToText = (data, fileName) => {
    const tableColumnsProspect = [
      "ProspectNumber",
      "Company",
      "ContactPerson",
      "Email",
      "Country",
      //"State",
      "City",
      "DialCode",
      "Phone",

      "ProspectOwner",
      "ProspectOwnerName",
      "Industry",
      "Tier",
      "Tags",
      "Region",
      "Source",
      "FollowUpDate",
      "FollowUpStatus",
      "NotificationDays",
      "FollowUpInterval",
      "Remarks",
    ];

    const tableRows = data.map((item) => {
      return Object.values(item).join(",");
    });

    const textContent = [tableColumnsProspect.join(",")]
      .concat(tableRows)
      .join("\n");

    const fileType = "text/plain;charset=utf-8;";
    const fileExtension = "txt";

    const textDataBlob = new Blob([textContent], {
      type: fileType,
    });
    //const uniqueId = Date.now();
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `${fileName}_${currentDate}_${currentTime}.${fileExtension}`;
    saveAs(textDataBlob, finalFileName);
  };

  const handleCheckboxColumn = (event, item) => {
    settableColumnsProspect((prevData) => {
      const newData = prevData.map((dataItem) =>
        dataItem.key === item.key
          ? { ...dataItem, isVisible: !dataItem.isVisible }
          : dataItem
      );
      return newData;
    });

    const newData = tableColumnsProspect.map((dataItem) =>
      dataItem.key === item.key
        ? { ...dataItem, isVisible: !dataItem.isVisible }
        : dataItem
    );

    localStorage.setItem("tableColumnsProspect", JSON.stringify(newData));
  };
  const handleCheckbox = (id) => {
    if (Array.isArray(id)) {
      setCheckBoxSelected(id);
    } else {
      setCheckBoxSelected((prev) => {
        if (Array.isArray(prev)) {
          if (prev.includes(id)) {
            return prev.filter((item) => item !== id);
          } else {
            return [...prev, id];
          }
        } else {
          return [id];
        }
      });
    }
  };
console.log(checkBoxSelected);
  // Timezone API
  const [timezoneData, setTimezoneData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await Axios.get(`/masterdata/get_timezone`);
        setTimezoneData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Admin API
  const [userdata, setUserdata] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await Axios.get(
          `/user/get_user?userId=${
            JSON.parse(localStorage.getItem("user")).userId
          }`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: JSON.parse(localStorage.getItem("token")),
            },
          }
        );

        const userDataList = response?.data?.data || [];
        setUserdata(userDataList);
      } catch (error) {
        // Handle error
      }
    };

    getData();
  }, []);

  // Extracting the roles from user data
  const userRoles = userdata.map(user => user.Role);

  // Check if the user has the 'Admin' role
  const isAdmin = userRoles.includes('Admin');
// End Admin API

const getData = async () => {
    try {
      setIsLoading(true);
      const { data } = await Axios.get(
        `/prospects/get_prospect?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }&isDeletedProspect=${isdelete}&isConvertedProspect=${isconvert}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.data?.length === 0) {
        setProspectData([]);
        return setColumns2([]);
      }
      const array1 = Object.keys(columns).filter((key) => columns[key]);

      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible:
            item === "Tier"
              ? false
              : item === "FollowUpDate"
              ? false
              : item === "State"
              ? false
              : item === "FollowUpStatus"
              ? false
              : item === "NotificationDays"
              ? false
              : item === "FollowUpInterval"
              ? false
              : item === "Tags"
              ? false
              : item === "Remarks"
              ? false
              : item === "Industry"
              ? false
              : item === "LeadNumber"
              ? false
              : item === "DeletionFlag"
              ? false
              : item === "ConversionFlag"
              ? false
              : item === "Region"
              ? false
              : item === "Source"
              ? false
              : item === "CreatedAt" 
              ? false
              : item === "ChangedAt"
              ? false
              : item === "ChangedAt"
                                          ? false
                                          : item === "MeetingDate"
                                          ? false
                                          : item === "MeetingTime"
                                          ? false
                                          : item === "MeetingRemark"
                                          ? false
                                          : item === "CallDate"
                                          ? false
                                          : item === "CallTime"
                                          ? false
                                          : item === "CallRemark"
                                          ? false
                                          : item === "CallStatus"
                                          ? false
                                          : item === "MeetingStatus"
                                          ? false
              : true,
        };
      });
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );

      setColumns2(filteredDataHeader);

      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};

        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });

        return filteredItem;
      });
      setProspectData(filteredData);
      setExportData(data.data);
    } catch (error) {
      console.log(error.message);
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (window.localStorage.getItem("prospectfilter")?.length === 0) {
      return setProspectData([]);
    }

    if (window.localStorage.getItem("prospectfilter")) {
      getFilterData();
    } else {
      getData();
    }
  }, []);

  const toggleActionDropdown = () => {
    setActionOpen(!isActionOpen);
  };
  const handleActionSelection = () => {
    setActionOpen(false);
    toggleActionDropdown();
  };
  // Filter Data
  const [filterRows, setFilterRows] = useState(() => {
    return JSON.parse(localStorage.getItem("prospectfilterRows"))
      ? JSON.parse(localStorage.getItem("prospectfilterRows"))
      : [0];
  });
  const [filterConditions, setFilterConditions] = useState(() => {
    const savedConditions = JSON.parse(
      localStorage.getItem("prospectfilterConditions")
    );
    return savedConditions || {};
  });

  const [inputValues, setInputValues] = useState({ ...filterConditions });
  const [selectValues, setSelectValues] = useState({ ...filterConditions });

  const addFilterRow = () => {
    setFilterRows((prevFilterRows) => [
      ...prevFilterRows,
      prevFilterRows?.length,
    ]);
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [filterRows?.length]: {
        condition: "",
        value: "",
      },
    }));
  };

  useEffect(() => {
    localStorage.setItem("prospectfilterRows", JSON.stringify(filterRows));
    localStorage.setItem(
      "prospectfilterConditions",
      JSON.stringify(filterConditions)
    );
  }, [filterRows, filterConditions]);

  const removeFilterRow = (index) => {
    const newFilterRows = filterRows.filter((rowIndex) => rowIndex !== index);
    setFilterRows(newFilterRows);

    setFilterConditions((prevConditions) => {
      const { [index]: removedCondition, ...restConditions } = prevConditions;
      return restConditions;
    });
  };
  const handleSubmitFilter = async () => {
    localStorage.setItem(
      "prospectfilterConditions",
      JSON.stringify(filterConditions)
    );
    // console.log(filterConditions)
    const transformedConditions = {};
    console.log({ filterRows });
    filterRows.forEach((index) => {
      const column = document.getElementById(`column-select-${index}`).value;
      const condition = document.getElementById(`filter-type-${index}`).value;
      const value = document.getElementById(`value-input-${index}`).value;
      console.log(column, condition, value);
      if (column && condition && value) {
        transformedConditions[column + index] = {
          condition,
          value,
        };
      }
    });
    console.log(transformedConditions);
    const a = JSON.stringify(transformedConditions, null, 2);
    const sendData = JSON.parse(a);
    const dataSet = {
      userId: JSON.parse(localStorage.getItem("user")).userId,
    };
    const { data } = await Axios.post(
      `/prospects/mass_filter_prospect?userId=${
        JSON.parse(localStorage.getItem("user")).userId
      }&filterType=${handleCheckboxFilter}&isDeleted=${isdelete}&isConverted=${isconvert}`,
      { sendData },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      }
    );
    if (data?.data?.length === 0) {
      setFilterOpen(false);
      localStorage.setItem("prospectfilter", JSON.stringify([]));
      return setProspectData([]);
    }
    console.log(sendData);
    const array1 = Object.keys(columns).filter((key) => columns[key]);

    const header = Object.keys(data?.data[0])?.map((item) => {
      return {
        key: item,
        label: item.charAt(0).toUpperCase() + item.slice(1),
        isVisible:
          item === "Tier"
            ? false
            : item === "FollowUpDate"
            ? false
            : item === "State"
            ? false
            : item === "FollowUpStatus"
            ? false
            : item === "NotificationDays"
            ? false
            : item === "FollowUpInterval"
            ? false
            : item === "Tags"
            ? false
            : item === "Remarks"
            ? false
            : item === "Industry"
            ? false
            : item === "LeadNumber"
            ? false
            : item === "DeletionFlag"
            ? false
            : item === "ConversionFlag"
            ? false
            : item === "Region"
            ? false
            : item === "Source"
            ? false
              : item === "CreatedAt" 
              ? false
              : item === "ChangedAt"
              ? false
              : item === "MeetingDate"
              ? false
              : item === "MeetingTime"
              ? false
              : item === "MeetingRemark"
              ? false
              : item === "CallDate"
              ? false
              : item === "CallTime"
              ? false
              : item === "CallRemark"
              ? false
              : item === "CallStatus"
                                          ? false
                                          : item === "MeetingStatus"
                                          ? false
              : true,
      };
    });

    const filteredDataHeader = header.filter((obj) => array1.includes(obj.key));

    setColumns2(filteredDataHeader);

    const filteredData = data?.data?.map((item) => {
      const filteredItem = {};

      Object.keys(item).forEach((key) => {
        if (array1.includes(key)) {
          filteredItem[key] = item[key];
        }
      });
      return filteredItem;
    });

    setProspectData(filteredData);
    console.log(filteredData, "asdsad");

    localStorage.setItem("prospectfilter", JSON.stringify(filteredData));
    setFilterOpen(false);
  };

  const handleFilterConditionSelect = (event, index) => {
    const selectedValue = event.target.value;
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        condition: selectedValue,
      },
    }));
  };
  const handleFilterValueChange = (event, index) => {
    const selectedValue = event.target.value;
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        value: selectedValue,
      },
    }));
  };

  const getFilterData = async (sendData) => {
    try {
      const data = {
        data: JSON.parse(window.localStorage.getItem("prospectfilter")),
      };

      if (data?.data?.length === 0) {
        setFilterOpen(false);
        return setProspectData([]);
      }

      const array1 = Object.keys(columns).filter((key) => columns[key]);

      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible:
            item === "Tier"
              ? false
              : item === "FollowUpDate"
              ? false
              : item === "State"
              ? false
              : item === "FollowUpStatus"
              ? false
              : item === "NotificationDays"
              ? false
              : item === "FollowUpInterval"
              ? false
              : item === "Tags"
              ? false
              : item === "Remarks"
              ? false
              : item === "Industry"
              ? false
              : item === "LeadNumber"
              ? false
              : item === "DeletionFlag"
              ? false
              : item === "ConversionFlag"
              ? false
              : item === "Region"
              ? false
              : item === "Source"
              ? false
              : item === "CreatedAt" 
              ? false
              : item === "ChangedAt"
              ? false
              : item === "MeetingDate"
              ? false
              : item === "MeetingTime"
              ? false
              : item === "MeetingRemark"
              ? false
              : item === "CallDate"
              ? false
              : item === "CallTime"
              ? false
              : item === "CallRemark"
              ? false
              : item === "CallStatus"
                                          ? false
                                          : item === "MeetingStatus"
                                          ? false
              : true,
        };
      });

      // // Filter the data based on the keys in filterKeys array
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );

      setColumns2(filteredDataHeader);

      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};

        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });
        return filteredItem;
      });

      setProspectData(filteredData);

      setFilterOpen(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleClearFilters = () => {
    const inputs = document.querySelectorAll(".value-input");
    const newInputValues = { ...inputValues };
    inputs.forEach((input) => {
      const index = inputValues;
      newInputValues[index] = input.value;
      input.value = "";
    });

    const selects = document.querySelectorAll(".column-select, .filter-type");
    selects.forEach((select) => {
      select.value = "select";
    });

    setFilterConditions({});
    setFilterOpen(false);
    setFilterRows([0]);
    getData();
    setInputValues(newInputValues);
    localStorage.removeItem("prospectfilterConditions");
    window.localStorage.removeItem("prospectfilter");
    window.localStorage.removeItem("prospectfilterRows");
    // localStorage.setItem("filterInputValues", JSON.stringify(newInputValues));
  };

  useEffect(() => {
    const array1 = columns && Object?.keys(columns);
    const array2 = prospectData[0] && Object?.keys(prospectData[0]);

    const savedInputValues = localStorage.getItem("filterInputValues");
    if (savedInputValues) {
      setInputValues(JSON.parse(savedInputValues));
    }

    if (window.localStorage.getItem("prospectfilter")?.length === 0) {
      return setProspectData([]);
    }

    if (window.localStorage.getItem("prospectfilter")) {
      getFilterData();
    } else {
      getData();
    }
  }, [columns]);

  // Mass Update Input Type
  // const [formData, setFormData] = useState({
  //   PhoneWithCode: "",
  //   CountryData: "",
  // });
  // const handleChangePhone = (value, countryData) => {
  //   if (countryData && countryData.dialCode) {
  //     const dialCodeLength = countryData.dialCode.length;
  //     const Phone = value.slice(dialCodeLength);

  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       Phone,
  //       PhoneWithCode: value,
  //       CountryData: countryData,
  //     }));
  //   }
  // };

  const handleOptionChange = (newValue) => {
    setSelectedOption(newValue);
  };

  const handleSubmitUpdate = async () => {
    try {
      console.log(updatedValue, checkBoxSelected, selectedOption);
      const filters = filterRows
        .map((index) => {
          console.log({ index });
          try {
            const field = document.getElementById(
              `column-select-${index}`
            ).value;
            const condition = document.getElementById(
              `filter-type-${index}`
            ).value;
            const value = document.getElementById(`value-input-${index}`).value;
            return { field, condition, value };
          } catch (error) {
            return null;
          }
        })
        .filter((filter) => filter !== null);
      console.log(filters);
      const { data } = await Axios.put(
        `/prospects/update_prospect?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          searchString: searchRef.current?.value || "",
          filters,
          filterType: handleCheckboxFilter,
          id: selectAllPages
            ? prospectData.map((item) => item.id)
            : checkBoxSelected,
          selectedAllPages: selectAllPages,
          update: {
            field: selectedOption,
            value: updatedValue,
            // DialCode: formData.CountryData.dialCode, // Include dial code
            // Phone: formData.Phone, // Include updated phone number
            // FollowUpDate: formData.FollowUpDate,
          },
          userId: JSON.parse(localStorage.getItem("user")).userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      if (data?.success) {
        toast({
          title: "Prospects Updated Successfully!",
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected(false);
        setUpdatedValue(""); // Clear the input field
        handleOptionChange("Select a field"); // Reset the select option
        getData();
      } else {
        toast({
          title: data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      console.log(error.message);
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
    }
  };
  console.log(selectAllPages);
  const handleSubmitDelete = async () => {
    try {
      console.log(
        updatedValue,
        checkBoxSelected,
        selectAllPages,
        selectedOption
      );
      const filters = filterRows
        .map((index) => {
          console.log({ index });
          try {
            const field = document.getElementById(
              `column-select-${index}`
            ).value;
            const condition = document.getElementById(
              `filter-type-${index}`
            ).value;
            const value = document.getElementById(`value-input-${index}`).value;
            return { field, condition, value };
          } catch (error) {
            return null;
          }
        })
        .filter((filter) => filter !== null);
      console.log(filters);
      const { data } = await Axios.post(
        `/prospects/delete_prospect?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          searchString: searchRef.current?.value || "",
          filters,
          filterType: handleCheckboxFilter,
          id: selectAllPages
            ? prospectData.map((item) => item.id)
            : checkBoxSelected,
          selectedAllPages: selectAllPages,
          userId: JSON.parse(localStorage.getItem("user")).userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      if (data?.success) {
        if (data?.data.message === "LeadNumber exists in leads table") {
          const prospects = data?.data.data;
          if (prospects.length === 1) {
            const leadNumber = prospects[0].LeadNumber;
            const prospectNumber = prospects[0].ProspectNumber;
            setProspectWarning(
              `Prospect ${prospectNumber} exists for Lead ${leadNumber}, do you want to delete the corresponding prospect!`
            );
          } else {
            const prospectCount = prospects.length;
            setProspectWarning(
              `${prospectCount} lead prospects exist, do you want to delete them!`
            );
          }
          return;
        }
        toast({
          title: "Prospects Deleted Successfully!",
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected(false);
      } else {
        toast({
          title: data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected(false);
      }
    } catch (error) {
      console.log(error.message);
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
    }
    console.log(selectAllPages);
    if (selectAllPages) {
      localStorage.removeItem("prospectfilter");
      localStorage.removeItem("prospectfilterConditions");
      localStorage.removeItem("prospectfilterRows");
      getFilterData();
      getData();
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
      setFilterRows([0]);
      setFilterConditions({});
      return;
    }

    if (localStorage.getItem("prospectfilter")) {
      const storedData = JSON.parse(
        localStorage.getItem("prospectfilter") || []
      );
      console.log(storedData);

      //Define the ID of the item you want to remove
      const idToRemove = selectAllPages
        ? prospectData.map((item) => item.id)
        : checkBoxSelected; // Replace with the ID you want to remove
      const newArray = storedData.filter(
        (item) => !idToRemove.includes(item.id)
      );
      // Update the array in localStorage
      localStorage.setItem("prospectfilter", JSON.stringify(newArray));
      if (window.localStorage.getItem("prospectfilter")) {
        getFilterData();
      } else {
        getData();
        getFilterData();
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
    } else {
      getFilterData();
      getData();
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
    }
  };
  const handleSubmitDeleteWithLead = async (e) => {
    try {
      if (e.target.value === "WITHOUT") {
        const filters = filterRows
          .map((index) => {
            try {
              const field = document.getElementById(
                `column-select-${index}`
              ).value;
              const condition = document.getElementById(
                `filter-type-${index}`
              ).value;
              const value = document.getElementById(
                `value-input-${index}`
              ).value;
              return { field, condition, value };
            } catch (error) {
              return null;
            }
          })
          .filter((filter) => filter !== null);

        const { data } = await Axios.post(
          `/prospects/delete_prospect_with_lead?userId=${
            JSON.parse(localStorage.getItem("user")).userId
          }&type=WITHOUT`,
          {
            searchString: searchRef.current?.value || "",
            filters,
            filterType: handleCheckboxFilter,
            id: selectAllPages
              ? prospectData.map((item) => item.id)
              : checkBoxSelected,
            selectedAllPages: selectAllPages,
            userId: JSON.parse(localStorage.getItem("user")).userId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: JSON.parse(localStorage.getItem("token")),
            },
          }
        );
        if (data?.success) {
          toast({
            title: `Prospect  ${data?.leadNumber} Deleted Successfully!`,
            position: "bottom-center",
            isClosable: true,
            status: "success",
            containerStyle: {
              width: "98.5vw",
              maxWidth: "98.5vw",
              alignContent: "center",
            },
          });
          setSelectAllPages(false);
          setCheckBoxAll(false);
          setCheckBoxSelected(false);
          onCloseReportDelete();
        } else {
          toast({
            title: data?.message,
            position: "bottom-center",
            isClosable: true,
            status: "error",
            containerStyle: {
              width: "98.5vw",
              maxWidth: "98.5vw",
              alignContent: "center",
            },
          });
          setSelectAllPages(false);
          setCheckBoxAll(false);
          setCheckBoxSelected(false);
          onCloseReportDelete();
        }
      } else if (e.target.value === "WITH") {
        const filters = filterRows
          .map((index) => {
            try {
              const field = document.getElementById(
                `column-select-${index}`
              ).value;
              const condition = document.getElementById(
                `filter-type-${index}`
              ).value;
              const value = document.getElementById(
                `value-input-${index}`
              ).value;
              return { field, condition, value };
            } catch (error) {
              return null;
            }
          })
          .filter((filter) => filter !== null);

        const { data } = await Axios.post(
          `/prospects/delete_prospect_with_lead?userId=${
            JSON.parse(localStorage.getItem("user")).userId
          }&type=WITH`,
          {
            searchString: searchRef.current?.value || "",
            filters,
            filterType: handleCheckboxFilter,
            id: selectAllPages
              ? prospectData.map((item) => item.id)
              : checkBoxSelected,
            selectedAllPages: selectAllPages,
            userId: JSON.parse(localStorage.getItem("user")).userId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: JSON.parse(localStorage.getItem("token")),
            },
          }
        );
        if (data?.success) {
          toast({
            title: "Prospects Deleted Successfully!",
            position: "bottom-center",
            isClosable: true,
            status: "success",
            containerStyle: {
              width: "98.5vw",
              maxWidth: "98.5vw",
              alignContent: "center",
            },
          });
          setSelectAllPages(false);
          setCheckBoxAll(false);
          setCheckBoxSelected(false);
          onCloseReportDelete();
        } else {
          toast({
            title: data?.message,
            position: "bottom-center",
            isClosable: true,
            status: "error",
            containerStyle: {
              width: "98.5vw",
              maxWidth: "98.5vw",
              alignContent: "center",
            },
          });
          setSelectAllPages(false);
          setCheckBoxAll(false);
          setCheckBoxSelected(false);
          onCloseReportDelete();
        }
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
      onCloseReportDelete();
    }

    if (selectAllPages) {
      localStorage.removeItem("prospectfilter");
      localStorage.removeItem("prospectfilterConditions");
      localStorage.removeItem("prospectfilterRows");
      getFilterData();
      getData();
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
      setFilterRows([0]);
      setFilterConditions({});
      return;
    }

    if (localStorage.getItem("prospectfilter")) {
      const storedData = JSON.parse(
        localStorage.getItem("prospectfilter") || []
      );

      //Define the ID of the item you want to remove
      const idToRemove = selectAllPages
        ? prospectData.map((item) => item.id)
        : checkBoxSelected; // Replace with the ID you want to remove
      const newArray = storedData.filter(
        (item) => !idToRemove.includes(item.id)
      );
      //Update the array in localStorage
      localStorage.setItem("prospectfilter", JSON.stringify(newArray));
      if (window.localStorage.getItem("prospectfilter")) {
        getFilterData();
      } else {
        getData();
        getFilterData();
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
    } else {
      getFilterData();
      getData();
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
    }
  };
  //Mass email

  const handleOpenEditor = (event) => {
    if (event) {
      event.preventDefault(); // Prevent the default link behavior
    }
    setIsEditorOpen(true);
  };
  const handleCloseEditor = () => {
    setIsEditorOpen(false);
  };
  const handleSubmitMassEmail = async () => {
    try {
      const selectedIds = checkBoxSelected;
      const recipients = prospectData
        .filter((item) => selectedIds.includes(item.id))
        .map((item) => item.Email);
      console.log(recipients);
      const emailData = {
        recipients,
        subject,
        body,
      };
      const response = await Axios.post("/emails/send_email", emailData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      });
      const { data } = response;
      if (data.success) {
        toast({
          title: "Email sent successfully!",
          position: " bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        setSelectAllPages(false);
        setCheckBoxAll(false);
        setCheckBoxSelected(false);
      } else {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
      setBody("");
      setIsEditorOpen(false);
      setSubject("");
    } catch (error) {
      if (error.response.data.message) {
        toast({
          title: error.response.data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error.response.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
    setSelectAllPages(false);
    setCheckBoxAll(false);
    setCheckBoxSelected(false);
  };
  // Windows application Mail
  const handleSubmitOutlookEmail = async () => {
    const recipients = prospectData
      .filter((item) => checkBoxSelected.includes(item.id))
      .map((item) => item.Email);
    if (recipients.length === 0) {
      console.log("No recipients selected.");
      return;
    }

    const subject = "Your subject here";
    const body = "Your email body here";

    const emailAddresses = recipients.join(",");
    const mailtoLink =
      "mailto:" +
      emailAddresses +
      "?subject=" +
      encodeURIComponent(subject) +
      "&body=" +
      encodeURIComponent(body);

    // Open the mail client with the mailtoLink
    window.location.href = mailtoLink;

    // Reset checkboxes and other state variables here
    setSelectAllPages(false);
    setCheckBoxAll(false);
    setCheckBoxSelected([]);
  };
  // Mass Upload Excel
  function isValidEmail(email) {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  }
  const handleSubmit = async (event) => {
    const validateData = () => {
      const requiredColumns = [
        "Company",
        "ContactPerson",
        "Email",
        "Country",
        // "State",
        "City",
      ];
      let isValid = true;
      const missingColumns = [];
      const invalidEmailRows = [];

      for (let index = 0; index < movies.length; index++) {
        const movie = movies[index];
        let hasInvalidEmail = false; // Flag to track invalid emails in a row
        for (const column of requiredColumns) {
          if (!movie[column]) {
            if (!missingColumns.includes(column)) {
              missingColumns.push(column);
            }
            isValid = false;
          }
        }
        // Check if email is valid
        if (movie.Email && !isValidEmail(movie.Email)) {
          isValid = false;
          hasInvalidEmail = true;
        }

        if (hasInvalidEmail) {
          invalidEmailRows.push(index + 1); // Store the row number with invalid email
        }
      }
      if (missingColumns.length > 0) {
        const errorTitle = `${missingColumns.join(", ")} ${
          missingColumns.length > 1 ? "are" : "is"
        } required for all row data`;
        toast({
          title: errorTitle,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }

      if (invalidEmailRows.length > 0) {
        toast({
          title: `${
            invalidEmailRows.length
          } Invalid email address (Row Numbers: ${invalidEmailRows.join(
            ", "
          )})`,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }

      return isValid;
    };

    if (event) {
    }
    if (!validateData()) {
      return;
    }
    const movieData = movies?.map((item, i) => {
      console.log(moment(item.FollowUpDate).utc().format(), item.FollowUpDate);
      const date = new Date((item.FollowUpDate - 25569) * 86400 * 1000);
      const utcString = moment.utc(date).format();
      return {
        ...item,
        FollowUpDate: utcString,
        ProspectNumber: "P" + (prospectnumber + i).toString().padStart(8, "0"),
        ProspectOwner: JSON.parse(localStorage.getItem("user")).userId,
      };
    });
    const dataSet = {
      userId: JSON.parse(localStorage.getItem("user")).userId,
      movies: movieData,
    };
    try {
      const response = await Axios.post(
        `/prospects/add_multiple_prospect?userId=?${JSON.parse(localStorage.getItem("user")).userId
        }`,
        dataSet,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      console.log(response);

      if (response.data.success) {
        // const dataLength = response.data.data.length;
        // Show success toast
        toast({
          title: response.data.data,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        // const dataErrorLength = response.data.data.slice(
        //   0,
        //   dataErrorLength - 1
        // );
        // Show error toast
        toast({
          title: response.data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      console.log(error.message);
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } finally {
      //handleCloseExcel();
      onClose();
      getFilterData();
      getData();
    }
  };
  //   try {
  //     const response = await Axios.post(
  //       `/prospects/add_multiple_prospect?userId=?${
  //         JSON.parse(localStorage.getItem("user")).userId
  //       }`,
  //       dataSet,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: JSON.parse(localStorage.getItem("token")),
  //         },
  //       }
  //     );
  //     onClose();
  //     if (response.data.success) {
  //       toast({
  //         title: response.data.data,
  //         position: "bottom-center",
  //         isClosable: true,
  //         status: "success",
  //         containerStyle: {
  //           width: "98.5vw",
  //           maxWidth: "98.5vw",
  //           alignContent: "center",
  //         },
  //       });
  //       handleCloseExcel();
  //     } else {
  //       toast({
  //         title: response.data.message,
  //         position: "bottom-center",
  //         isClosable: true,
  //         status: "error",
  //         containerStyle: {
  //           width: "98.5vw",
  //           maxWidth: "98.5vw",
  //           alignContent: "center",
  //         },
  //       });
  //       handleCloseExcel();
  //     }
  //   } catch (error) {
  //     console.log(error.message);
  //     if (error?.response?.data?.message) {
  //       toast({
  //         title: error?.response?.data?.message,
  //         position: "bottom-center",
  //         isClosable: true,
  //         status: "error",
  //         containerStyle: {
  //           width: "98.5vw",
  //           maxWidth: "98.5vw",
  //           alignContent: "center",
  //         },
  //       });
  //     } else if (error?.response?.statusText) {
  //       toast({
  //         title: error?.response?.statusText,
  //         position: "bottom-center",
  //         isClosable: true,
  //         status: "error",
  //         containerStyle: {
  //           width: "98.5vw",
  //           maxWidth: "98.5vw",
  //           alignContent: "center",
  //         },
  //       });
  //     } else {
  //       toast({
  //         title: error?.message,
  //         position: "bottom-center",
  //         isClosable: true,
  //         status: "error",
  //         containerStyle: {
  //           width: "98.5vw",
  //           maxWidth: "98.5vw",
  //           alignContent: "center",
  //         },
  //       });
  //     }
  //   }
  //   getFilterData();
  //   getData();
  // };
  const handleSearch = async (event) => {
    const queryData = event.target.value;

    const dataSet = {
      userId: JSON.parse(localStorage.getItem("user")).userId,
    };

    const { data } = await Axios.get(
      `/prospects/search_prospect?userId=${
        JSON.parse(localStorage.getItem("user")).userId
      }&query=${queryData}&isDeleted=${isdelete}&isConverted=${isconvert}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      }
    );

    if (data?.data?.length === 0) {
      return setProspectData([]);
    }

    const array1 = Object.keys(columns).filter((key) => columns[key]);

    const header = Object.keys(data?.data[0])?.map((item) => {
      return {
        key: item,
        label: item.charAt(0).toUpperCase() + item.slice(1),
        isVisible:
          item === "Tier"
            ? false
            : item === "State"
            ? false
            : item === "FollowUpDate"
            ? false
            : item === "FollowUpStatus"
            ? false
            : item === "NotificationDays"
            ? false
            : item === "FollowUpInterval"
            ? false
            : item === "Tags"
            ? false
            : item === "Remarks"
            ? false
            : item === "Industry"
            ? false
            : item === "LeadNumber"
            ? false
            : item === "DeletionFlag"
            ? false
            : item === "ConversionFlag"
            ? false
            : item === "Region"
            ? false
            : item === "Source"
            ? false
            : item === "CreatedAt" 
            ? false
            : item === "ChangedAt"
            ? false
            : item === "MeetingDate"
            ? false
            : item === "MeetingTime"
            ? false
            : item === "MeetingRemark"
            ? false
            : item === "CallDate"
            ? false
            : item === "CallTime"
            ? false
            : item === "CallRemark"
            ? false
            : item === "CallStatus"
                                          ? false
                                          : item === "MeetingStatus"
                                          ? false
            : true,
      };
    });
    // // Filter the data based on the keys in filterKeys array
    const filteredDataHeader = header.filter((obj) => array1.includes(obj.key));

    setColumns2(filteredDataHeader);

    const filteredData = data?.data?.map((item) => {
      const filteredItem = {};

      Object.keys(item).forEach((key) => {
        if (array1.includes(key)) {
          filteredItem[key] = item[key];
        }
      });

      return filteredItem;
    });

    setProspectData(filteredData);
  };
  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const availableItemsPerPage = [5, 10, 15, 20, 50, 80, 100];

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < pageNumbers ? prevPage + 1 : prevPage
    );
  };

  const pageNumbers = Math.ceil(prospectData?.length / itemsPerPage);

  const renderPageNumbers = () => {
    const pagesToShow = 5; // Adjust this number to control how many page numbers are displayed at once.

    const firstPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const lastPage = Math.min(pageNumbers, firstPage + pagesToShow - 1);
    return (
      <>
        {Array.from(
          { length: lastPage - firstPage + 1 },
          (_, i) => firstPage + i
        ).map((number) => (
          <li
            key={number}
            onClick={() => setCurrentPage(number)}
            data-selected={currentPage === number}
            className="py-1 px-2 block data-[selected=true]:font-bold border-gray-200 dark:border-gray-700 dark:text-white cursor-pointer"
          >
            {number}
          </li>
        ))}
      </>
    );
  };

  // filter Part Combination
  const [showOptions, setShowOptions] = useState(false);
  const [showPhoneOptions, setShowPhoneOptions] = useState(false);

  const handleFilterTypeSelect = (event, index) => {
    const selectedValue = event.target.value;
    setShowOptions(selectedValue !== "select");
    setShowPhoneOptions(
      selectedValue === "Phone" || selectedValue === "FollowUpDate"
    );

    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        column: selectedValue,
      },
    }));
  };
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await Axios.get("/prospects/get_prospectseries", {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        });

        setProspectNumber(data?.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, [prospectnumber]);
  function excelDateToJSDate(excelDate) {
    const jsDate = new Date(
      (excelDate - 25569) * 86400 * 1000
    ).toLocaleDateString("en-US", { timeZone: "UTC" });
    return jsDate;
  }

  useEffect(() => {
    if (localStorage.getItem("tableColumnsProspect")) {
      settableColumnsProspect(
        JSON.parse(localStorage.getItem("tableColumnsProspect"))
      );
    } else {
      settableColumnsProspect(columns2);
    }
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [columns2]);

  //console.log(columns2);

  const handleDragStart = (event, index) => {
    event.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, dropIndex) => {
    event.preventDefault();

    const dragIndex = parseInt(event.dataTransfer.getData("text/plain"));
    const updatedColumns = [...tableColumnsProspect];

    const [draggedColumn] = updatedColumns.splice(dragIndex, 1);
    updatedColumns.splice(dropIndex, 0, draggedColumn);
    localStorage.setItem(
      "tableColumnsProspect",
      JSON.stringify(updatedColumns)
    );
    settableColumnsProspect(updatedColumns);
  };
  const style = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflow: "hidden",
  };
  const columnstyle = {
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflowY: "auto",
    scrollbarWidth: "thin", // Set the width of the scrollbar to thin
  scrollbarColor: "rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.1)"
  };
  
  const backdropStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Dull black color with opacity
    zIndex: "199", // Lower zIndex than the modal to ensure the modal stays on top
  };
  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "0%",
    zIndex: "200",
  };

  const modalStyleUpdate = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "200",
  };

  const closeModal = {
    position: "absolute",
    top: "3%",
    right: "5%",
    cursor: "pointer",
  };

   //check icon onclick if meeting exist
 const check_prospectMeet = () => {
  const getId = selectAllPages ? prospectData.map((item) => item.id) : checkBoxSelected;
  handle_prospectmeet(getId);
};

const handle_prospectmeet = async (getId) => {
  try {
    const { data } = await Axios.post(
      `/prospectsMeeting/check_prospectmeet?userId=${
        JSON.parse(localStorage.getItem("user")).userId
      }`,
      {
        id: getId
      }
    );
    if (data.success) {
      onOpenMeetingProspect(); // Call onOpenMeeting if the request is successful
    } else {
      toast({
        title: data.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  } catch (error) {
    console.error(error);
    toast({
      title: "An error occurred.",
      position: "bottom-center",
      isClosable: true,
      status: "error",
      containerStyle: {
        width: "98.5vw",
        maxWidth: "98.5vw",
        alignContent: "center",
      },
    });
  }
};
//check icon onclick if calls exists
const check_prospectCall = () => {
  const getId = selectAllPages ? prospectData.map((item) => item.id) : checkBoxSelected;
  handle_prospectcall(getId);
};

const handle_prospectcall = async (getId) => {
  try {
    const { data } = await Axios.post(
      `/prospectsCall/check_prospectcall?userId=${
        JSON.parse(localStorage.getItem("user")).userId
      }`,
      {
        id: getId
      }
    );
    if (data.success) {
      onOpenCallsProspect(); // Call onOpenCalls if the request is successful
    } else {
      toast({
        title: data.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  } catch (error) {
    console.error(error);
    toast({
      title: "An error occurred.",
      position: "bottom-center",
      isClosable: true,
      status: "error",
      containerStyle: {
        width: "98.5vw",
        maxWidth: "98.5vw",
        alignContent: "center",
      },
    });
  }
};

 //class declaration
 const checkValidation = new Checkvalidation(
  checkBoxSelected,
  toast,
  onOpenMassupdate,
  onOpenReportDelete,
  handleOpenEditor,
  onOpenReportOutlookEmail,
  check_prospectCall,
  check_prospectMeet,
);
const MassUpload = new Massupload(
  setExcelOpen,
  setMovies,
  excelOpen,
  read,
  utils
);

  // Grade API
  const [gradeData, setGradeData] = useState([]);
  // Fetch grade data on component mount
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await Axios.get("/masterdata/get_grade");
        setGradeData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Tags API
  const [tagData, setTagData] = useState([]);
  // Fetch grade data on component mount
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await Axios.get("/masterdata/get_tag");
        setTagData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // Industry API
  const [industryData, setIndustryData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await Axios.get("/masterdata/get_industry");
        setIndustryData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
//Meeting
const [MeetingDate, setMeetingDate] = useState('');
  const [MeetingTime, setMeetingTime] = useState('');
  const [MeetingRemarks, setMeetingRemarks] = useState('');
  const [MeetingTimezone,setMeetingTimezone]=useState('');

  const handleMeetingDateChange = (e) => {
    setMeetingDate(e.target.value);
  };


  const handleMeetingTimeChange = (e) => {
    setMeetingTime(e.target.value);
  };

  const handleMeetingTimezoneChange = (e) => {
    setMeetingTimezone(e.target.value);
  };

  const handleMeetingRemarksChange = (e) => {
    setMeetingRemarks(e.target.value);
  };


  const handleSubmitMeeting = async () => {
    try {
      const meeting = {
        MeetingDate,
        MeetingTime,
        MeetingTimezone,
        MeetingRemarks,
      };
      const filters = filterRows
        .map((index) => {
          try {
            const field = document.getElementById(
              `column-select-${index}`
            ).value;
            const condition = document.getElementById(
              `filter-type-${index}`
            ).value;
            const value = document.getElementById(`value-input-${index}`).value;
            return { field, condition, value };
          } catch (error) {
            return null;
          }
        })
        .filter((filter) => filter !== null);
  console.log(meeting);
      const { data } = await Axios.post(
        `/prospectsMeeting/add_meeting?userId=${JSON.parse(localStorage.getItem("user")).userId}`,
        {
          searchString: searchRef.current?.value || "",
          filters,
          filterType: handleCheckboxFilter,
          meeting,
          id: selectAllPages ? prospectData.map((item) => item.id) : checkBoxSelected,
          selectedAllPages: selectAllPages,
          userId: JSON.parse(localStorage.getItem("user")).userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      console.log(data);
      if (data.success) {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
      else {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: "Meeting Updated Successfully!",
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
      onCloseReportDelete();
    }
 
    if (selectAllPages) {
      localStorage.removeItem("prospectfilter");
      localStorage.removeItem("prospectfilterConditions");
      localStorage.removeItem("prospectfilterRows");
      getFilterData();
      getData();
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
      setFilterRows([0]);
      setFilterConditions({});
      return;
    }
 
    if (localStorage.getItem("prospectfilter")) {
      const storedData = JSON.parse(localStorage.getItem("prospectfilter") || []);
 
      //Define the ID of the item you want to remove
      const idToRemove = selectAllPages ? prospectData.map((item) => item.id) : checkBoxSelected; // Replace with the ID you want to remove
      const newArray = storedData.filter((item) => !idToRemove.includes(item.id));
      //Update the array in localStorage
      localStorage.setItem("prospectfilter", JSON.stringify(newArray));
      if (window.localStorage.getItem("prospectfilter")) {
        getFilterData();
      } else {
        getData();
        getFilterData();
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
    } else {
      getFilterData();
      getData();
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
    }
    setMeetingDate('');
    setMeetingTime('');
    setMeetingTimezone('');
    setMeetingRemarks('');
  };


  const handleClearMeeting = () => {
    // Handle clearing the meeting data
    setMeetingDate('');
    setMeetingTime('');
    setMeetingTimezone('');
    setMeetingRemarks('');
  };


//Calls
const [CallDate, setCallDate] = useState('');
const [CallTime, setCallTime] = useState('');
const [CallRemarks, setCallRemarks] = useState('');
const [CallTimezone,setCallTimezone]=useState('');

  const handleCallDateChange = (e) => {
    setCallDate(e.target.value);
  };


  const handleCallTimeChange = (e) => {
    setCallTime(e.target.value);
  };


  const handleCallRemarksChange = (e) => {
    setCallRemarks(e.target.value);
  };
  const handleCallTimezoneChange = (e) => {
    setCallTimezone(e.target.value);
  };

  const handleSubmitCalls = async () => {
    try {
      const call = {
        CallDate,
        CallTime,
        CallTimezone,
        CallRemarks,
      };
      const filters = filterRows
        .map((index) => {
          try {
            const field = document.getElementById(
              `column-select-${index}`
            ).value;
            const condition = document.getElementById(
              `filter-type-${index}`
            ).value;
            const value = document.getElementById(`value-input-${index}`).value;
            return { field, condition, value };
          } catch (error) {
            return null;
          }
        })
        .filter((filter) => filter !== null);
  console.log(call);
      const { data } = await Axios.post(
        `/prospectsCall/add_call?userId=${JSON.parse(localStorage.getItem("user")).userId}`,
        {
          searchString: searchRef.current?.value || "",
          filters,
          filterType: handleCheckboxFilter,
          call,
          id: selectAllPages ? prospectData.map((item) => item.id) : checkBoxSelected,
          selectedAllPages: selectAllPages,
          userId: JSON.parse(localStorage.getItem("user")).userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      console.log(data);
      if (data.success) {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
      else {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: "Call added Successfully!",
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
      onCloseReportDelete();
    }
 
    if (selectAllPages) {
      localStorage.removeItem("prospectfilter");
      localStorage.removeItem("prospectfilterConditions");
      localStorage.removeItem("prospectfilterRows");
      getFilterData();
      getData();
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
      setFilterRows([0]);
      setFilterConditions({});
      return;
    }
 
    if (localStorage.getItem("prospectfilter")) {
      const storedData = JSON.parse(localStorage.getItem("prospectfilter") || []);
 
      //Define the ID of the item you want to remove
      const idToRemove = selectAllPages ? prospectData.map((item) => item.id) : checkBoxSelected; // Replace with the ID you want to remove
      const newArray = storedData.filter((item) => !idToRemove.includes(item.id));
      //Update the array in localStorage
      localStorage.setItem("prospectfilter", JSON.stringify(newArray));
      if (window.localStorage.getItem("prospectfilter")) {
        getFilterData();
      } else {
        getData();
        getFilterData();
      }
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
    } else {
      getFilterData();
      getData();
      setSelectAllPages(false);
      setCheckBoxAll(false);
      setCheckBoxSelected(false);
    }
    setCallDate('');
    setCallTime('');
    setCallTimezone('');
    setCallRemarks('');
  };


  const handleClearCalls = () => {
    // Handle clearing the call data
    setCallDate('');
    setCallTime('');
    setCallTimezone('');
    setCallRemarks('');
  };



  return (
    <>
      <Header />
      <>
        <>
          {isOpen && (
            <>
            <div style={backdropStyle}></div> 
            <div className={`MassUploadModal ${scrollBehavior}`}>
              <Rnd
                style={style}
                default={{
                  x: 0,
                  y: 0,
                  width: 1000,
                  // height: 200
                }}
                cancel="X"
                allowAnyClick={true}
              >
                <header
                  style={{
                    float: "left",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  Mass Upload
                </header>
                <div
                  className="closemodule"
                  onClick={() => {
                    onClose(false);
                    setExcelOpen(false);
                  }}
                  style={closeModal}
                >
                  <CgClose />
                </div>
                <br />
                {excelOpen ? (
                  <div
                    style={{
                      width: "100%",
                      maxHeight: "400px",
                      overflowY: "auto",
                    }}
                  >
                    <TableContainer>
                      <Table variant="simple">
                        <div style={{ position: "sticky", top: 0, zIndex: 1 }}>
                          <Thead>
                            <Tr>
                              {/* <Th className="dark:text-white bg-slate-200 dark:bg-slate-700 dark:border-gray-600">
                                ProspectNumber
                              </Th> */}
                              {movies?.length > 0 &&
                                Object.keys(movies[0]).map((item, index) => (
                                  <Th
                                    key={index}
                                    className="dark:text-white bg-slate-200 dark:bg-slate-700 dark:border-gray-600"
                                  >
                                    {item}
                                  </Th>
                                ))}
                              {/* <Th className="dark:text-white bg-slate-200 dark:bg-slate-700 dark:border-gray-600">
                                ProspectOwner
                              </Th> */}
                            </Tr>
                          </Thead>

                          <Tbody>
                            {movies?.map((mainItem, i) => (
                              <Tr key={i}>
                                {/* <Td>
                                  {"P" +
                                    (prospectnumber + i)
                                      .toString()
                                      .padStart(8, "0")}
                                </Td> */}
                                {Object.keys(movies[0]).map((item, index) => (
                                  <Td key={index}>
                                    {item === "FollowUpDate"
                                      ? excelDateToJSDate(mainItem[item])
                                      : mainItem[item]}
                                  </Td>
                                ))}
                                {/* <Td>
                                  {
                                    JSON.parse(localStorage.getItem("user"))
                                      .userId
                                  }
                                </Td> */}
                              </Tr>
                            ))}
                          </Tbody>
                        </div>
                      </Table>
                    </TableContainer>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                       <Button
                      onClick={() => {
                        onOpenReportMassUpload();
                        onClose(false);
                        setExcelOpen(false);
                        MassUpload.handleCloseExcel();
                      }}
                        colorScheme="blue"
                        mr={3}
                        mt={5}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div
                      style={{
                        marginTop: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <input
                        type="file"
                        name="file"
                        className="custom-file-input"
                        id="inputGroupFile"
                        required
                        onChange={(event) => {
                          MassUpload.handleImport(event);
                        }}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        style={{ display: "none" }}
                      />
                      <label
                        className="fileUploadContainer"
                        htmlFor="inputGroupFile"
                      >
                        <label
                          className="custom-file-label bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded my-2 dark:bg-blue-950"
                          style={{ cursor: "pointer" }}
                          htmlFor="inputGroupFile"
                        >
                          Upload the file
                        </label>
                      </label>
                    </div>
                  </div>
                )}
              </Rnd>
            </div>
            </>
          )}
        </>

        <div className="mx-auto  bg-blue-950 text-white flex flex-wrap dark:bg-indigo-900">
          <FaFilter
            size={25}
            onClick={isOpenFilter ? onCloseFilter : onOpenFilter}
            style={{ cursor: "pointer", marginTop: "15", marginLeft: "10" }}
          />
          &nbsp;&nbsp;
          <>
            <div className="pt-2 ml-10 relative mx-auto text-gray-600">
              <input
                className="border-2  border-gray-300 bg-white h-8 px-5 pr-10 rounded-lg text-sm focus:outline-none"
                ref={searchRef}
                type="search"
                name="search"
                placeholder="Search"
                onChange={handleSearch}
              />
            </div>
          </>
          &nbsp;
          <TbAdjustmentsHorizontal
            onClick={
              isOpenManagecolumn ? onCloseManagecolumn : onOpenManagecolumn
            }
            size={25}
            style={{ cursor: "pointer", marginTop: "15", marginRight: "30" }}
          />
          &nbsp;
          <div className="relative inline-block">
            <button className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded my-2 dark:bg-blue-950">
              <Link to="/addprospect">Create Prospect</Link>
            </button>
          </div>
          &nbsp;&nbsp;
          <div className="relative inline-block">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded my-2 dark:bg-blue-950"
              onClick={toggleActionDropdown}
            >
              Actions
            </button>
            &nbsp;&nbsp;
            {isActionOpen && (
              <div className="absolute right-0 mt-1 py-2 w-48 bg-white border rounded shadow-lg dark:bg-slate-700 z-[9999]">
                <a className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white">
                  <FiEdit size={20} />
                  <span
                    className="flex-grow cursor-pointer"
                    onClick={() => {
                      checkValidation.handleEditClickMassupdate();
                      handleActionSelection();
                    }}
                  >
                    Mass Update
                  </span>
                </a>
                <a
                  className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white"
                  onClick={() => {
                    checkValidation.handleEditClickMassdelete();
                  }}
                >
                  <RiDeleteBinLine size={20} />
                  <span
                    className="flex-grow cursor-pointer"
                    onClick={handleActionSelection}
                  >
                    Mass Delete
                  </span>
                </a>

                <a
                  className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white"
                  onClick={() => {
                    checkValidation.handleEditClickMassmail();
                  }}
                >
                  <HiOutlineMail size={20} />
                  <span
                    className="flex-grow cursor-pointer"
                    onClick={handleActionSelection}
                  >
                    Mass Email
                  </span>
                </a>
                <a className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white">
                  <LiaFileUploadSolid size={20} />
                  <span
                    className="flex-grow cursor-pointer"
                    onClick={() => {
                      onOpen();
                      toggleActionDropdown();
                      handleActionSelection();
                    }}
                  >
                    Mass Upload
                  </span>
                </a>
                {/* <a
                  className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white"
                  onClick={() => {
                    onOpenDownload();
                    setOverlay(<OverlayOne />);
                    toggleActionDropdown();
                  }}
                >
                  <MdOutlineDownloadForOffline size={20} />

                  <span
                    className="flex-grow cursor-pointer"
                    onClick={handleActionSelection}
                  >
                    Export Data
                  </span>
                </a> */}
                {isAdmin && ( <a
                className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white"
                onClick={() => {
                  onOpenDownload();
                  setOverlay(<OverlayOne />);
                  toggleActionDropdown();
                }}
              >
                <MdOutlineDownloadForOffline size={20} />

                <span
                  className="flex-grow cursor-pointer"
                  onClick={handleActionSelection}
                >
                  Export Data
                </span>
              </a>)}
                <a
                  className="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-slate-500 dark:text-white"
                  onClick={() => {
                    toggleActionDropdown();
                    {
                      navigate("/prospecttemplate");
                    }
                  }}
                >
                  <BsFiletypeExe size={20} />
                  <span
                    className="flex-grow cursor-pointer"
                    onClick={handleActionSelection}
                  >
                    Export Prospect Template
                  </span>
                </a>
              </div>
            )}
          </div>
          <>
            {isOpenFilter && (
              <>
              <div style={backdropStyle}></div> 
              <div className={`FilterModal`}>
         {/* {${scrollBehavior}} */}
                <Rnd
                  style={style}
                  default={{
                    x: 0,
                    y: 0,
                    width: 600,
                    // height: 200,
                  }}
                  cancel="X"
                  allowAnyClick={true}
                >
                  <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                    Criteria
                  </header>
                  <div
                    className="closemodule"
                    onClick={() => {
                      onCloseFilter(false);
                    }}
                    style={closeModal}
                  >
                    <CgClose />
                  </div>
                  <br />

                  <FormControl>
                    {filterRows.map((index) => (
                      <Flex key={index}>
                        <Select
                          id={`column-select-${index}`}
                          placeholder="Select"
                          onChange={(e) => handleFilterTypeSelect(e, index)}
                          value={filterConditions[index]?.column || "select"}
                        >
                          <option value="ProspectNumber">
                            Prospect Number
                          </option>
                          <option value="Company">Company</option>
                          <option value="ContactPerson">Contact Person</option>
                          <option value="Phone">Phone</option>
                          <option value="Email">Email</option>
                          <option value="Tier">Tier</option>
                          <option value="City">City</option>
                          <option value="State">State</option>
                          <option value="Country">Country</option>
                          <option value="Industry">Industry</option>
                          <option value="Tags">Tags</option>
                          <option value="Region">Region</option>
                          <option value="Source">Source</option>
                          <option value="Remarks">Remarks</option>
                          <option value="FollowUpDate">Follow up Date</option>
                          <option value="FollowUpStatus">
                            Follow Up Status
                          </option>
                          <option value="NotificationDays">
                            Notification Day
                          </option>
                          <option value="FollowUpInterval">
                            Follow Up Interval
                          </option>
                          <option value="ProspectOwner">Prospect Owner</option>
                          <option value="ProspectOwnerName">
                            Prospect Owner Name
                          </option>
                          <option value="DeletionFlag">Deletion Flag</option>
                          <option value="ConversionFlag">
                            Conversion Flag
                          </option>
                          <option value="CreatedAt">CreatedAt</option>
                        <option value="ChangedAt">ChangedAt</option>
                        </Select>
                        &nbsp;&nbsp;
                        <Select
                        id={`filter-type-${index}`}
                        onChange={(e) => handleFilterConditionSelect(e, index)}
                        value={filterConditions[index]?.condition || "select"}
                        placeholder="Select"
                      >
                        {filterConditions[index]?.column === "CreatedAt" ||
                          filterConditions[index]?.column === "ChangedAt" ? (
                          <>
                            <option value="equalsDate">=</option>
                            <option value="not-equalsDate">!=</option>
                            <option value="greaterDate">&gt;</option>
                            <option value="lesserDate">&lt;</option>
                            <option value="greater-equalDate">&gt;=</option>
                            <option value="lesser-equalDate">&lt;=</option>
                          </>
                        ) : filterConditions[index]?.column === "ProspectOwnerName" ? (
                          <>
                            <option value="isprospectname">is</option>
                            <option value="containsprospectname">contains</option>
                            <option value="startsWithprospectname">starts with</option>
                            <option value="endsWithprospectname">ends with</option>
                          </>
                        ) : filterConditions[index]?.column === "Phone" ||
                          filterConditions[index]?.column === "FollowUpInterval" ||
                          filterConditions[index]?.column === "NotificationDays" ||
                          filterConditions[index]?.column === "FollowUpDate" ||
                          filterConditions[index]?.column === "DeletionFlag" ||
                          filterConditions[index]?.column === "ConversionFlag" ? (
                          <>
                            <option value="equals">=</option>
                            <option value="not-equals">!=</option>
                            <option value="greater">&gt;</option>
                            <option value="lesser">&lt;</option>
                            <option value="greater-equal">&gt;=</option>
                            <option value="lesser-equal">&lt;=</option>
                          </>
                        ) : (
                          <>
                            <option value="is">is</option>
                            <option value="contains">contains</option>
                            <option value="startsWith">starts with</option>
                            <option value="endsWith">ends with</option>
                          </>
                        )}
                      </Select>
                        &nbsp;&nbsp;
                        {/* <Input
                          type="text"
                          id={`value-input-${index}`}
                          value={filterConditions[index]?.value || ""}
                          onChange={(e) => handleFilterValueChange(e, index)}
                        /> */}
                         {["FollowUpDate", "ChangedAt", "CreatedAt"].includes(filterConditions[index]?.column) ? (
      <Input
        type="date"
        id={`value-input-${index}`}
        value={filterConditions[index]?.value || ""}
        onChange={(e) => handleFilterValueChange(e, index)}
      />
    ) : (
      <Input
        type="text"
        id={`value-input-${index}`}
        value={filterConditions[index]?.value || ""}
        onChange={(e) => handleFilterValueChange(e, index)}
      />
    )}
                        &nbsp;&nbsp;{" "}
                        <IoMdAddCircle
                          className="cursor-pointer"
                          size={70}
                          style={{ marginTop: "-15px", color: "green" }}
                          onClick={() => addFilterRow()}
                        />
                        {filterRows?.length > 1 && index > 0 && (
                          <BiSolidMinusCircle
                            className="cursor-pointer"
                            size={70}
                            style={{ marginTop: "-15px", color: "red" }}
                            onClick={() => removeFilterRow(index)}
                          />
                        )}
                      </Flex>
                    ))}
                  </FormControl>

                  <div style={{ float: "right", display: "block" }}>
                    <Button
                      colorScheme="green"
                      onClick={() => {
                        handleSubmitFilter();
                        onCloseFilter();
                      }}
                      mr={3}
                    >
                      Apply
                    </Button>
                    <Button
                      colorScheme="red"
                      onClick={() => {
                        handleClearFilters();
                        onCloseFilter();
                      }}
                    >
                      Clear
                    </Button>
                  </div>
                  <div
                    style={{
                      float: "left",
                      marginTop: "10px",
                      display: "block",
                    }}
                  >
                    <RadioGroup
                      style={{ flex: 1 }}
                      value={handleCheckboxFilter}
                    >
                      <Stack direction="row">
                        <Radio value="AND" onChange={handleCheckboxChange}>
                          All
                        </Radio>
                        <Radio value="OR" onChange={handleCheckboxChange}>
                          Any
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </div>
                </Rnd>
              </div>
              </>
            )}
          </>
          <>
            {isOpenManagecolumn && (
              <>
              <div style={backdropStyle}></div> 
              <div style={modalStyleUpdate}>
                <Rnd
                  style={columnstyle}
                  default={{
                    x: 0,
                    y: 0,
                    width: 400,
                  height: 500,
                  }}
                  cancel="X"
                  allowAnyClick={true}
                >
                  <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                    Manage Column
                  </header>
                  <div
                    onClick={() => {
                      onCloseManagecolumn(false);
                    }}
                    style={closeModal}
                  >
                    <CgClose />
                  </div>
                  <br />
                  {tableColumnsProspect.map(
                    (label, index) =>
                      label.key !== "id" &&
                      label.key !== "DialCode" && 
                      label.key!=="CallId"&&
                      label.key!=="MeetingId"&&
                      label.key!="MeetingTimezone"&&
                      label.key!="meetingCanceledFlag"&&
                      label.key!="meetingConductedFlag"&&
                      label.key!="callConductedFlag"&&
                      label.key!="callCanceledFlag"&&(
                        <label
                          key={index}
                          className="flex items-center"
                          draggable
                          onDragStart={(event) => handleDragStart(event, index)}
                          onDragOver={handleDragOver}
                          onDrop={(event) => handleDrop(event, index)}
                        >
                          <input
                            type="checkbox"
                            // checked={label.label}
                            className="cursor-pointer"
                            disabled={
                              label.key === "ProspectNumber"
                                ? true
                                : label.key === "ContactPerson"
                                ? true
                                : label.key === "Company"
                                ? true
                                : label.key === "Phone"
                                ? true
                                : label.key === "Country"
                                ? true
                                : label.key === "ProspectOwner"
                                ? true
                                : label.key === "ProspectOwnerName"
                                ? true
                                : null
                            }
                            checked={label.isVisible}
                            onChange={(event) => {
                              handleCheckboxColumn(event, label);
                            }}
                          />
                          <span
                            className="ml-1"
                            style={{ cursor: "all-scroll" }}
                          >
                            {label.label}
                          </span>
                          {label.isDisabled && (
                            <span style={{ color: "red" }}> *</span>
                          )}
                        </label>
                      )
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                      float: "right",
                    }}
                  >
                    <Button
                      onClick={() => {
                        onCloseManagecolumn(false);
                      }}
                      colorScheme="green"
                    >
                      OK
                    </Button>
                    <Button
                      onClick={() => {
                        onCloseManagecolumn(false);
                      }}
                      colorScheme="red"
                      ml={3}
                    >
                      Cancel
                    </Button>
                  </div>
                </Rnd>
              </div>
              </>
            )}
          </>
        </div>
      </>
      <div className="mx-auto  bg-blue-950 text-black flex flex-wrap dark:bg-indigo-900">
        <div className="header-3 md:w-full bg-slate-200 text-black dark:text-white dark:bg-slate-800">
          {/* Next page button */}
          <div
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-200 dark:bg-slate-700 dark:text-white text-black inline-block w-50"
            style={{ float: "right", marginRight: "5px" }}
          >
            <FiArrowRightCircle
              className="cursor-pointer w-6 h-6"
              onClick={goToNextPage}
              disabled={currentPage === pageNumbers}
            />
          </div>

          {/* Previous page button */}
          <div
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-200 text-black dark:bg-slate-700 dark:text-white inline-block w-50"
            style={{ float: "right", marginRight: "10px" }}
          >
            <FiArrowLeftCircle
              className="cursor-pointer w-6 h-6"
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
            />
          </div>

          {/* Page numbers */}
          <p
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700  dark:text-white inline-block  w-50"
            style={{ float: "right", marginRight: "20px" }}
          >
            <a className="relative inline-flex items-center">
              {renderPageNumbers()}
            </a>
          </p>

          {/* Items per page dropdown */}
          <select
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 inline-block  w-50"
            style={{ float: "right", marginRight: "20px", cursor: "pointer" }}
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
          >
            {availableItemsPerPage.map((option) => (
              <option key={option} value={option}>
                {option} Records Per Page
              </option>
            ))}
          </select>

          {/* Icons */}

          <Tooltip hasArrow label="Meeting" bg="gray.300" color="black">
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm  dark:text-white text-black inline-block w-50 delete">
              {/* <SiGooglemeet
                size={20}
                onClick={() => {
                  //checkValidation.handleEditClickMeeting();
                  onOpenMeeting();
                  
                }}
              /> */}
              <MdOutlineVideoCall
                size={24}
                onClick={() => {
                  checkValidation.handleEditClickMeeting();
                  //onOpenMeeting();
                }}
              />
            </div>
          </Tooltip>

          <Tooltip hasArrow label="Call" bg="gray.300" color="black">
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm  dark:text-white text-black inline-block w-50 delete">
            <MdOutlineAddIcCall
                size={20}
                onClick={() => {
                  checkValidation.handleEditClickCalls();
                  //onOpenCalls();
                }}
              />
            </div>
          </Tooltip>

          {isconvert ? (
            <Tooltip
              hasArrow
              label="Show converted records"
              bg="gray.300"
              color="black"
            >
              <div className="py-1 px-2 my-2 border-gray-200 rounded-full text-sm dark:text-white text-black inline-block w-50 mail">
                <BiHide size={24} onClick={handleRecordConvert} />
              </div>
            </Tooltip>
          ) : (
            <Tooltip
              hasArrow
              label="Hide converted records"
              bg="gray.300"
              color="black"
            >
              <div className="py-1 px-2 my-2 border-gray-200 rounded-full text-sm dark:text-white text-black inline-block w-50 mail">
                <BiShow size={24} onClick={handleRecordConvert} />
              </div>
            </Tooltip>
          )}
          <Tooltip hasArrow label="Delete" bg="gray.300" color="black">
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm  dark:text-white text-black inline-block w-50 delete">
              <RiDeleteBinLine 
              size={20}
              onClick={() => {
                checkValidation.handleEditClickMassdelete();
              }}

               />
            </div>
          </Tooltip>
          <Tooltip hasArrow label="Server Mail" bg="gray.300" color="black">
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 mail">
              <HiOutlineMail 
               size={20}
               onClick={() => {
                checkValidation.handleEditClickMassmail();
              }}
                />
            </div>
          </Tooltip>
          <Tooltip hasArrow label="Outlook Mail" bg="gray.300" color="black">
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 mail">
              <SiMicrosoftoutlook
                onClick={() => {
                  checkValidation.handleEditClickOutlookMail();
                }}
                size={20}
              />
            </div>
          </Tooltip>
          <Tooltip hasArrow label="Mass Update" bg="gray.300" color="black">
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 update">
              <FiEdit size={20} 
               onClick={() => {
                checkValidation.handleEditClickMassupdate();
              }}
               />
            </div>
          </Tooltip>

          {isdelete ? (
            <Tooltip
              hasArrow
              label="Show deleted records"
              bg="gray.300"
              color="black"
            >
              <div className="py-1 px-2 my-2 border-gray-200 rounded-full text-sm dark:text-white text-black inline-block w-50 mail">
                <BiShow size={24} onClick={handleRecordDelete} />
              </div>
            </Tooltip>
          ) : (
            <Tooltip
              hasArrow
              label="Hide deleted records"
              bg="gray.300"
              color="black"
            >
              <div className="py-1 px-2 my-2 border-gray-200 rounded-full text-sm dark:text-white text-black inline-block w-50 mail">
                <BiHide size={24} onClick={handleRecordDelete} />
              </div>
            </Tooltip>
          )}

          <Tooltip
            hasArrow
            label="Select all pages"
            bg="gray.300"
            color="black"
          >
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm   dark:text-white text-black inline-block w-50 mail">
              <MdSelectAll
                onClick={() => {
                  handleCheckbox(prospectData.map((item) => item.id));
                  setCheckBoxAll((prev) => {
                    if (prev === true) {
                      setCheckBoxSelected([]);
                    }
                    return !prev;
                  });
                }}
                size={24}
              />
            </div>
          </Tooltip>
          {/* Record count */}
          <p className="py-1 px-2 my-2" style={{ float: "left" }}>
            <p className="text-sm text-gray-700 dark:text-white">
              Showing{" "}
              <span className="font-medium">
                {currentPage === 1 ? 1 : indexOfFirstItem + 1}
              </span>{" "}
              to{" "}
              <span className="font-medium">
                {Math.min(indexOfLastItem, prospectData?.length)}
              </span>{" "}
              of <span className="font-medium">{prospectData?.length}</span>{" "}
              records
            </p>
            <p style={{ float: "left", marginTop: "10px" }}>
              <Selectallpages
                selectAllPages={selectAllPages}
                setSelectAllPages={setSelectAllPages}
                checkBoxAll={checkBoxAll}
              />
            </p>
          </p>
        </div>

        <div className="table-container">
          <DraggableProspectTable
            isLoading={isLoading}
            columns={columns2}
            prospectData={prospectData}
            getData={getData}
            data={prospectData.slice(indexOfFirstItem, indexOfLastItem)}
            setProspectData={setProspectData}
            handleCheckbox={handleCheckbox}
            checkBoxSelected={checkBoxSelected}
            setCheckBoxSelected={setCheckBoxSelected}
            setCheckBoxAll={setCheckBoxAll}
            checkBoxAll={checkBoxAll}
            handleDragOver={handleDragOver}
            handleDragStart={handleDragStart}
            handleDrop={handleDrop}
            tableColumnsProspect={tableColumnsProspect}
            currentPage={currentPage}
            pageNumbers={pageNumbers}
          />
        </div>

        <>
          {
            <div>
              {isOpenMassupdate && (
                <>
                <div style={backdropStyle}></div> 
                <div className={`MassUpdateModal ${scrollBehavior}`}>
                  <Rnd
                    style={style}
                    default={{
                      x: 0,
                      y: 0,
                      width: 500,
                      // height: 200
                    }}
                    cancel="X"
                    allowAnyClick={true}
                  >
                    <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                      Mass Update
                    </header>
                    <div
                      onClick={() => {
                        onCloseMassupdate(false);
                      }}
                      style={closeModal}
                    >
                      <CgClose />
                    </div>
                    <br />
                    <FormControl>
                      <Flex>
                        <Select
                          mt={7}
                          className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          value={selectedOption}
                          onChange={(e) => handleOptionChange(e.target.value)}
                        >
                          <option value="Select a field">Select a field</option>
                          <option value="Phone">Phone</option>
                          <option value="City">City</option>
                          <option value="Industry">Industry</option>
                          <option value="Tags">Tags</option>
                          <option value="Region">Region</option>
                          <option value="Source">Source</option>
                          <option value="Remarks">Remarks</option>
                          <option value="FollowUpDate">Follow Up Date</option>
                          <option value="NotificationDays">
                            Notification Day
                          </option>
                          <option value="FollowUpInterval">
                            Follow Up Interval
                          </option>
                        </Select>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {selectedOption === "Tier" ? (
                          <Select
                            mt={7}
                            onChange={(e) => setUpdatedValue(e.target.value)}
                            value={updatedValue}
                            className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          >
                            <option>Select Tier</option>
                            {gradeData.map((data) => (
                              <option value={data.Tier}>{data.Tier}</option>
                            ))}
                          </Select>
                        ) : selectedOption === "Tags" ? (
                          <Select
                            mt={7}
                            onChange={(e) => setUpdatedValue(e.target.value)}
                            value={updatedValue}
                            className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          >
                            <option>Select Tag</option>
                            {tagData.map((data) => (
                              <option value={data.Tags}>{data.Tags}</option>
                            ))}
                          </Select>
                        ) : selectedOption === "Industry" ? (
                          <Select
                            mt={7}
                            onChange={(e) => setUpdatedValue(e.target.value)}
                            value={updatedValue}
                            className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          >
                            <option>Select Industry</option>
                            {industryData.map((data) => (
                              <option value={data.Industry}>
                                {data.Industry}
                              </option>
                            ))}
                          </Select>
                        ) : selectedOption === "FollowUpDate" ? (
                          <Input
                            mt={7}
                            onChange={(e) => setUpdatedValue(e.target.value)}
                            value={updatedValue}
                            type="date"
                            name={selectedOption}
                            id="updateField"
                            className="text-black bg-white dark:bg-gray-950 dark:text-white"
                          />
                        ) : (
                          <Input
                            mt={7}
                            onChange={(e) => setUpdatedValue(e.target.value)}
                            value={updatedValue}
                            type="text"
                            name={selectedOption}
                            id="updateField"
                            className="text-black bg-white dark:bg-gray-950 dark:text-white"
                            disabled={selectedOption === "Select a field"}
                          />
                        )}
                      </Flex>
                    </FormControl>
                    <br />
                    <Button
                      colorScheme="green"
                      onClick={() => {
                        onOpenReportMassupdate();
                        onCloseMassupdate();
                      }}
                      mr={3}
                    >
                      Submit
                    </Button>
                  </Rnd>
                </div>
                </>
              )}
            </div>
          }
        </>
      </div>

      <>
        {isOpenReportDelete && (
          <>
          <div style={backdropStyle}></div> 
          <div className={`MassDeleteModule ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 600,
                // height: 200
              }}
              cancel="X"
              allowAnyClick={true}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Delete Leads
              </header>
              <div
                onClick={() => {
                  onCloseReportDelete(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              <FormControl>
                {prospectWarning
                  ? prospectWarning
                  : "Are you sure you want to delete the prospects?"}
              </FormControl>
              <br />
              {prospectWarning ? (
                <>
                  <Button
                    colorScheme="green"
                    onClick={handleSubmitDeleteWithLead}
                    ml={3}
                    value="WITH"
                  >
                    Delete with lead
                  </Button>
                  <Button
                    ref={cancelRef}
                    onClick={handleSubmitDeleteWithLead}
                    ml={3}
                    style={{ background: "red", color: "white" }}
                    value="WITHOUT"
                  >
                    Delete without lead
                  </Button>
                </>
              ) : (
                <>
                  <Button colorScheme="green" onClick={handleSubmitDelete}>
                    Delete
                  </Button>
                  <Button
                    ref={cancelRef}
                    onClick={onCloseReportDelete}
                    style={{ background: "red", color: "white" }}
                    ml={3}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Rnd>
          </div>
          </>
        )}
      </>
      <>
        <>
          {isOpenReportMassupdate && (
            <>
            <div style={backdropStyle}></div> 
            <div className={`MassUpdateModalConfirm ${scrollBehavior}`}>
              <Rnd
                style={style}
                default={{
                  x: 0,
                  y: 0,
                  width: 600,
                  // height: 200
                }}
                cancel="X"
                allowAnyClick={true}
              >
                <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Update Prospect
                </header>
                <div
                  onClick={() => {
                    onCloseReportMassupdate(false);
                  }}
                  style={closeModal}
                >
                  <CgClose />
                </div>
                <br />
                <FormControl>
                  {" "}
                  Are you sure you want to Update the prospect?
                </FormControl>
                <br />
                <Button
                  colorScheme="green"
                  onClick={() => {
                    handleSubmitUpdate();
                    onCloseReportMassupdate();
                  }}
                >
                  Update
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    onCloseReportMassupdate();
                  }}
                  ml={3}
                >
                  Cancel
                </Button>
              </Rnd>
            </div>
            </>
          )}
        </>
      </>

      <>
        {isOpenReportMassUpload && (
          <>
          <div style={backdropStyle}></div> 
          <div className={`MassUploadModalConfirm ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 600,
                // height: 200
              }}
              cancel="X"
              allowAnyClick={true}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Mass Upload Prospect
              </header>
              <div
                onClick={() => {
                  onCloseReportMassUpload(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              <FormControl>
                {" "}
                Are you sure you want to Mass Upload the prospect?
              </FormControl>
              <br />
              <Button
                colorScheme="green"
                onClick={() => {
                  handleSubmit();
                  onCloseReportMassUpload();
                }}
              >
                Submit
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  onCloseReportMassUpload();
                }}
                ml={3}
              >
                Cancel
              </Button>
            </Rnd>
          </div>
          </>
        )}{" "}
      </>
      <>
        {isOpenReportMassEmail && (
          <>
          <div style={backdropStyle}></div> 
          <div className={`MassMailConfirmModule ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 600,
                // height: 200
              }}
              cancel="X"
              allowAnyClick={true}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Send Mail
              </header>
              <div
                onClick={() => {
                  onCloseReportMassEmail(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              <FormControl>
                {" "}
                Are you sure you want to Send the mail?
              </FormControl>
              <br />
              <Button
                colorScheme="green"
                onClick={() => {
                  handleSubmitMassEmail();
                  onCloseReportMassEmail();
                }}
              >
                Submit
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  onCloseReportMassEmail();
                }}
                ml={3}
              >
                Cancel
              </Button>
            </Rnd>
          </div>
          </>
        )}
      </>
      <>
        {isOpenReportOutlookEmail && (
          <>
          <div style={backdropStyle}></div> 
          <div className={`OultlookMailModule ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 600,
                // height: 200
              }}
              cancel="X"
              allowAnyClick={true}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Send Mail
              </header>
              <div
                onClick={() => {
                  onCloseReportOutlookEmail(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              <FormControl>
                {" "}
                Are you sure you want to send the mail?
              </FormControl>
              <br />
              <Button
                colorScheme="green"
                onClick={() => {
                  handleSubmitOutlookEmail();
                  onCloseReportOutlookEmail();
                }}
              >
                Submit
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  onCloseReportOutlookEmail();
                }}
                ml={3}
              >
                Cancel
              </Button>
            </Rnd>
          </div>
          </>
        )}
      </>
      <>
        {isEditorOpen && (
          <>
          <div style={backdropStyle}></div> 
          <div className={`EditMailModule ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 600,
                // height: 200
              }}
              cancel="X"
              allowAnyClick={true}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Compose Email
              </header>
              <div
                onClick={() => {
                  handleCloseEditor(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              {/* Subject input */}
              <FormControl id="subject">
                <FormLabel>Subject</FormLabel>
                <Input
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </FormControl>

              {/* Editor */}
              <Editor
                value={body}
                onEditorChange={setBody}
                init={{
                  height: 300,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat | help",
                }}
              />
              {/* </ModalBody>
            <ModalFooter> */}
              <br />
              <Button colorScheme="red" mr={2} onClick={handleCloseEditor}>
                Cancel
              </Button>
              <Button
                colorScheme="green"
                onClick={() => {
                  handleSubmitMassEmail();
                  handleCloseEditor();
                }}
              >
                Submit
              </Button>
            </Rnd>
          </div>
          </>
        )}
      </>
      <>
        {isOpenDownload && (
          <>
          <div style={backdropStyle}></div> 
          <div className={`DownloadExcelModule ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 500,
                // height: 200
              }}
              cancel="X"
              allowAnyClick={true}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Do you wanna to export data?
              </header>
              <div
                onClick={() => {
                  onCloseDownload(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />
              <FormControl>
                <Flex alignItems="center ">
                  {" "}
                  {/* Use alignItems to vertically center the contents */}
                  <FormLabel marginLeft="100">Select</FormLabel>{" "}
                  {/* Push FormLabel to the left */}
                  <Select
                    w={60}
                    mt={-1}
                    placeholder="Select option"
                    size="md"
                    ref={initialRef}
                    value={selectedExportOption}
                    onChange={handleExportOptionChange}
                  >
                    <option value="Excel">Excel</option>
                    <option value="CSV">CSV</option>
                    <option value="PDF">PDF</option>
                    <option value="Text">Text</option>
                  </Select>
                </Flex>
              </FormControl>
              <br />
              <Button
                colorScheme="green"
                onClick={() => {
                  handleExport();
                }}
                disabled={isDownloading}
                mr={3}
              >
                {isDownloading ? "Downloading..." : "Export Data"}
              </Button>
              {downloadComplete && (
                <Text mt={2} color="green">
                  Download completed!
                </Text>
              )}
            </Rnd>
          </div>
          </>
        )}
      </>
      <>
  {isOpenMeetingProspect && (
    <>
    <div style={backdropStyle}></div> 
    <div className={`MeetingModal ${scrollBehavior}`}>
      <Rnd
        style={style}
        default={{
          x: 0,
          y: 0,
          width: 700,
        }}
        cancel="X"
        allowAnyClick={true}
      >
        <header style={{ fontSize: "20px", fontWeight: "bold" }}>
          Meeting
        </header>
        <div
          className="closemodule"
          onClick={() => {
            onCloseMeetingProspect(false);
          }}
          style={closeModal}
        >
          <CgClose />
        </div>
        <br />
        <FormControl>
          <Flex>
            &nbsp; &nbsp;
            <input
              type="date"
              name="MeetingDate"
              value={MeetingDate}
              onChange={handleMeetingDateChange}
              style={{ marginRight: '30px' }}
            />&nbsp; &nbsp;
            <input
              type="time"
              name="MeetingTime"
              value={MeetingTime}
              onChange={handleMeetingTimeChange}
              style={{ marginRight: '10px' }}
            />&nbsp; &nbsp;
            <Select
              name="MeetingTimezone"
              value={MeetingTimezone}
              onChange={handleMeetingTimezoneChange}
              style={{ width: '200px', marginRight: '10px' }}
            >
              <option value="">select timezone</option>
              {timezoneData.map((data) => (
                              <option value={data.low}>
                                {data.low}
                              </option>
                            ))}
            </Select>
            <Input
              type="text"
              name="MeetingRemarks"
              value={MeetingRemarks}
              onChange={handleMeetingRemarksChange}
              placeholder="Enter Meeting Remarks"
              style={{ marginRight: '30px' }}
            />
          </Flex>
          <br />
          <div style={{ float: 'right', display: 'block' }}>
            <Button
              colorScheme="green"
              onClick={() => {
                handleSubmitMeeting();
                onCloseMeetingProspect();
              }}
              mr={3}
            >
              Submit
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                handleClearMeeting();
                onCloseMeetingProspect();
              }}
            >
              Cancel
            </Button>
          </div>
          <div style={{ float: 'left', marginTop: '10px', display: 'block' }}></div>
        </FormControl>
      </Rnd>
    </div>
    </>
  )}
</>

        <>
          {( isOpenCallsProspect  &&(
            <>
            <div style={backdropStyle}></div> 
      <div className={`CallsModal ${scrollBehavior}`}>
       <Rnd
                style={style}
                default={{
                  x: 0, 
                  y: 0,
                  width:700,
                  //height: 200,
                }}
                cancel="X"
                allowAnyClick={true}
              >
                <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Calls
                </header>
                <div
                  className="closemodule"
                  onClick={() => {
                    onCloseCallsProspect(false);
                  }}
                  style={closeModal}
                >
                  <CgClose />
                </div>
                <br />
                  <FormControl>
            <Flex>
            &nbsp; &nbsp;
              <input
                type="date"
                name="CallDate"
                value={CallDate}
                onChange={handleCallDateChange}
                style={{ marginRight: '30px' }}
              />&nbsp; &nbsp;
              <input
                type="time"
                name="CallTime"
                value={CallTime}
                onChange={handleCallTimeChange}
                style={{ marginRight: '10px' }}
              />&nbsp; &nbsp;
              <Select
              name="CallTimezone"
              value={CallTimezone}
              onChange={handleCallTimezoneChange}
              style={{ width: '200px', marginRight: '10px' }}
            >
              <option value="">select timezone</option>
              {timezoneData.map((data) => (
                              <option value={data.low}>
                                {data.low}
                              </option>
                            ))}
            </Select>
              <Input
                type="text"
                name="CallRemarks"
                value={CallRemarks}
                onChange={handleCallRemarksChange}
                placeholder="Enter Call Remarks"
                style={{ marginRight: '30px' }}
              />
            </Flex>
            <br />
            <div style={{ float: 'right', display: 'block' }}>
              <Button
                colorScheme="green"
                onClick={() => {
                  handleSubmitCalls();
                  onCloseCallsProspect();
                }}
                mr={3}
              >
                Submit
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  handleClearCalls();
                  onCloseCallsProspect();
                }}
              >
                Cancel
              </Button>
            </div>
            <div style={{ float: 'left', marginTop: '10px', display: 'block' }}></div>
          </FormControl>
        </Rnd>
      </div>
      </>
          )
    )}

        </>
    </>
  );
};

export default Prospect;