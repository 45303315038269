export const themeColors = {
  "black-primary": "#000000",
  "black-shady": "#484848",
  "white": "#FBFBFB",
  "green": "#098C2E",
  "orange": "#8B5401",
  "pastel-red": "#FBCBC4",
  "red": "#8B1A01",
  "bright-red": "#FF0000",
  "gray": "#AEAEAE",
  "gray-light": "#E0E0E0",
  "light-cyan": "#EDF0F4",
  "sky-blue": "#009CF5",
};

export const chartColors = [
  "#91aecf",
  "#c0c9db",
  "#e07313",
  "#e3a26d",
  "#348932",
  "#75b975",
  "#a72020",
  "#d17f7e",
  "#735c9f",
  "#a992b6",
  "#6a4a42",
  "#b38b86",
  "#d86dab",
  "#f4a1c5",
  "#757575",
  "#ababab",
  "#9fbd2b",
  "#c4c490",
  "#139fbf",
  "#84c6d6",
  "#26284f",
  "#44467a",
  "#5c63a6",
  "#878fae",
  "#4d703d",
  "#6b8f58",
  "#94aa7f",
  "#7f673b",
  "#a7924a",
  "#d5bb69",
  "#9c4e4b",
  "#b8595c",
  "#cd8993",
  "#e2b0b8",
  "#663762",
  "#8a5e82",
  "#b789b4",
  "#ccb6ca",
];
