import { BsArrowRight } from "react-icons/bs";
import { Card } from "./Card";
import { useNavigate } from "react-router-dom";

export default function TotalCount({
  totalCount = 0,
  currentCount = 0,
  totalConverted = 0,
  totalDeleted = 0,
  selected,
}) {
  const navigate = useNavigate();
  const path = selected === "Prospects" ? "/prospect" : "/lead";

  return (
    <Card>
      <div className="flex flex-wrap justify-between mr-2">
        <div className="flex gap-4 items-center text-medium text-black text-left">
          Total number of {selected}:{" "}
          <span className="text-heading3 font-semibold">{totalCount}</span>
          <BsArrowRight
            onClick={() => navigate(path)}
            className="text-2xl text-blue-sky cursor-pointer"
          />
        </div>

        <ShowData text="Open" value={currentCount} selectedScreen={selected} />
        {selected !== "Prospects" && (
          <ShowData
            text="Total Converted"
            value={totalConverted}
            selectedScreen={selected}
          />
        )}
        <ShowData
          text="Total Deleted"
          value={totalDeleted}
          selectedScreen={selected}
        />
      </div>
    </Card>
  );
}

function ShowData({ text, value, selectedScreen }) {
  return (
    <div className="flex gap-4 items-center text-base text-black text-left">
      {text} {selectedScreen}: <span className="text-heading3 ">{value}</span>
    </div>
  );
}
