import { useEffect, useMemo, useState } from "react";
import { themeColors } from "../../Utils/colors";
import moment, { weekdays } from "moment";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { Table } from "../../components";
import { useLocalStorage } from "../../hooks";
// import { BiMinus } from "react-icons/bi";
// import { MdAdd } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { Card } from "./Card";
import Axios from "../../api-instance";
import { useQuery } from "@tanstack/react-query";
import { formatDate, defaultOmnitedColumns } from "./helper";

export default function Followup({ selectedScreen = "Leads" }) {
  const startOfCurrentWeek = moment().startOf("isoWeek").isoWeekday(1);

  const [columns, setColumns] = useLocalStorage(
    "DASHBOARD_FOLLOWUP_TABLE_COLUMNS",
    []
  );

  const [showContent, setShowContent] = useState(true);
  const [startOfWeek, setStartOfWeek] = useState(startOfCurrentWeek);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [weekDays, setWeekDays] = useState([]);

  const [showContentIcon, setShowContentIcon] = useState(<IoIosArrowUp />);

  // Toggle the content and change the icon when the button is clicked
  const toggleContent = () => {
    setShowContent((prev) => !prev);
    setShowContentIcon((prevIcon) =>
      prevIcon.type === IoIosArrowUp ? <IoIosArrowDown /> : <IoIosArrowUp />
    );
  };

  const selectedDateIsInCurrentWeek = selectedDate.isBetween(
    weekDays[0]?.moment,
    weekDays.at(-1)?.moment,
    undefined,
    "[]"
  );

  const followupUrl = `/dashboard/${selectedScreen.toLowerCase()}Followup`;
  const followupQuery = useQuery({
    queryKey: [
      selectedScreen,
      "followup",
      { FollowupDate: moment(selectedDate).format("YYYY-MM-DD") },
    ],
    queryFn: () =>
      Axios.get(
        `${followupUrl}?FollowupDate=${moment(selectedDate).format(
          "YYYY-MM-DD"
        )}&userId=${JSON.parse(localStorage.getItem("user"))?.userId}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      ),
  });

  // prospectsFollowupCountWeekly
  const weeklyAPIRoute = `${selectedScreen.toLowerCase()}FollowupCountWeekly`;
  const followupCountUrl = `/dashboard/${weeklyAPIRoute}?from=${weekDays[0]?.moment.format(
    "YYYY-MM-DD"
  )}&to=${weekDays.at(-1)?.moment.format("YYYY-MM-DD")}&userId=${
    JSON.parse(localStorage.getItem("user"))?.userId
  }`;
  const followupCountQuery = useQuery({
    queryKey: [
      selectedScreen,
      "followupCount",
      {
        from: weekDays[0]?.moment.format("YYYY-MM-DD"),
        to: weekDays.at(-1)?.moment.format("YYYY-MM-DD"),
      },
    ],
    queryFn: () =>
      Axios.get(followupCountUrl, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      }),
    onSuccess: (data) => {
      const { result } = data.data;
      const modifiedWeeks = weekDays.map((date, ind) => ({
        ...date,
        count: result[ind]?.count || 0,
      }));
      setWeekDays(modifiedWeeks);
    },
    enabled:
      weekDays[0]?.moment.format("YYYY-MM-DD") &&
      weekDays.at(-1)?.moment.format("YYYY-MM-DD")
        ? true
        : false,
  });

  const data = useMemo(() => {
    return formatDate(followupQuery?.data?.data?.result || []);
  }, [followupQuery?.data?.data?.result]);

  useEffect(() => {
    currentWeek();
  }, []);

  useEffect(() => {
    if (!data.length) return;
    initialColumnSetup(data, selectedScreen);
  }, [data]);

  function initialColumnSetup(data) {
    const columns = Object.keys(data[0]).map((key) => ({
      name: key,
      visible: true,
    }));

    let ownerColumnName =
      selectedScreen === "Leads" ? "LeadOwnerName" : "ProspectOwnerName";

    const ownerColumnIndex = columns.findIndex(
      (column) => column.name === ownerColumnName
    );

    // If the ownerColumnName is not already at the beginning, move it to the front
    if (ownerColumnIndex > 0) {
      const ownerColumn = columns.splice(ownerColumnIndex, 1)[0];
      columns.unshift(ownerColumn);
    }

    const omitted = [...defaultOmnitedColumns];
    const filteredColumns = columns.filter(
      (column) => !omitted.includes(column.name)
    );

    setColumns(filteredColumns);
  }

  function currentWeek() {
    const weekDays = Array.from({ length: 7 }, (_, i) => ({
      moment: startOfCurrentWeek.clone().add(i, "d"),
    }));
    setSelectedDate(moment());
    setStartOfWeek(startOfCurrentWeek);
    setWeekDays(weekDays);
  }

  function prevWeek() {
    const startOfPrevWeek = startOfWeek.clone().subtract(1, "w");
    const weekDays = Array.from({ length: 7 }, (_, i) => ({
      moment: startOfPrevWeek.clone().add(i, "d"),
    }));
    setStartOfWeek(startOfPrevWeek);
    setWeekDays(weekDays);
  }

  function nextWeek() {
    const startOfNextWeek = startOfWeek.clone().add(1, "w");
    const weekDays = Array.from({ length: 7 }, (_, i) => ({
      moment: startOfNextWeek.clone().add(i, "d"),
    }));
    setStartOfWeek(startOfNextWeek);
    setWeekDays(weekDays);
  }

  function onDateClick(date) {
    setSelectedDate(date);
  }

  return (
    <Card className="flex flex-col gap-4">
      <div className="flex justify-between h-9">
        <span className="flex items-center gap-6">
          <span className="flex items-center gap-6">
            <span className="text-medium font-semibold">Follow-up Dates</span>
            <span
              onClick={toggleContent}
              className="flex-center w-6 h-6 p-1 rounded-full active:bg-gray-light cursor-pointer"
            >
              {showContentIcon}
            </span>
          </span>
          {(!moment().isSame(selectedDate, "day") ||
            !selectedDateIsInCurrentWeek) && (
            <div
              onClick={currentWeek}
              className="flex justify-center items-center py-2 px-3 rounded-full bg-green-half cursor-pointer select-none"
              style={{ backgroundColor: "#98FB98" }}
            >
              {moment().format("D MMM")}
            </div>
          )}
        </span>
        <span className="flex items-center gap-6">
          {/* <BsThreeDotsVertical className="text-icon-base text-black-shady cursor-pointer" /> */}
          <div className="flex gap-2">
            <MdKeyboardArrowLeft
              title="Previous Week"
              onClick={prevWeek}
              className="text-2xl text-black-shady rounded-md cursor-pointer select-none border border-gray-light"
            />
            <MdKeyboardArrowRight
              title="Next Week"
              onClick={nextWeek}
              className="text-2xl text-black-shady rounded-md cursor-pointer select-none border border-gray-light"
            />
          </div>
        </span>
      </div>
      <div
        data-hide={!showContent}
        className="max-h-max flex flex-col gap-4 overflow-hidden transition-[height] duration-500 data-[hide=true]:max-h-0"
      >
        <div class="flex overflow-x-auto scrollbar-none pb-4 gap-7">
          {weekDays.map((item, index) => {
            return (
              (item.moment.isoWeekday() < 6 || item.count > 0) && (
                <DateCard
                  key={index}
                  status=""
                  countLoading={followupCountQuery.isLoading}
                  date={item.moment.format("D MMM")}
                  day={item.moment.format("dddd")}
                  count={item.count || 0}
                  selected={selectedDate.isSame(item.moment, "day")}
                  onClick={() => onDateClick(item.moment)}
                />
              )
            );
          })}
        </div>
        <div className="max-width-table overflow-x-auto">
          <Table
            {...{
              columns,
              setColumns,
              data,
              enableGrapScroll: true,
              enableSort: false,
            }}
          />
        </div>
      </div>
    </Card>
  );
}

function DateCard({
  status,
  countLoading,
  date,
  day,
  count,
  selected,
  onClick,
}) {
  const cardStyle = {
    approved: { backgroundColor: themeColors["half-green"] },
    pending: { backgroundColor: themeColors["half-orange"] },
  };

  const cardColor = selected ? cardStyle["approved"] : cardStyle[status];

  return (
    <div
      onClick={onClick}
      data-selected={selected}
      style={cardColor}
      data-disabled={count === 0 && !selected && !countLoading}
      className="flex-shrink-0 w-36 h-20 p-3 text-black-primary rounded-md shadow-sm cursor-pointer hover:shadow-lg data-[selected=true]:shadow-md data-[disabled=true]:text-gray-primary"
    >
      <div className="flex justify-between text-normal">
        <span>{date}</span>
        <span className="font-light">{day}</span>
      </div>
      <div className="flex-center text-heading2 font-semibold text-center">
        {countLoading ? (
          <div className="w-4 h-4 mt-3 rounded-full border-2 border-transparent border-x-gray-dark animate-spin" />
        ) : (
          count
        )}
      </div>
    </div>
  );
}
