export function PrimaryButton({ className, selected, onClick, children }) {
  return (
    <button
      onClick={onClick}
      data-selected={selected}
      className={`${className} bg-white-half text-normal py-2 px-6 rounded-lg outline-none hover:bg-white-primary data-[selected=true]:bg-blue-sky data-[selected=true]:text-white data-[selected=true]:hover:bg-blue-sky`}
    >
      {children}
    </button>
  );
}
