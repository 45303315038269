import { useState } from "react";

const styleObj = {
  "top-left": { top: 0, right: 0 },
  "top-right": { top: 0, left: 0 },
  "bottom-left": { top: "100%", right: 0 },
  "bottom-right": { top: "100%", left: 0 },
};

export default function Menu({
  children,
  data,
  className = "",
  position = "bottom-left",
  toggleType = "hover",
}) {
  const [show, setShow] = useState(false);

  const onMouseEnter = () => {
    if (toggleType === "hover") setShow(true);
  };

  const onMouseLeave = () => {
    setShow(false);
  };

  const onClick = () => {
    if (toggleType === "active") setShow(true);
  };

  return (
    <div
      className={`${className} relative`}
      {...{ onMouseEnter, onMouseLeave, onClick }}
    >
      {children}
      {show && (
        <div
          style={styleObj[position]}
          className={`absolute flex-col min-w-[170px] rounded-lg bg-white-primary overflow-hidden shadow-xl z-10`}
        >
          {data.map((item, index) => (
            <Child data={item} key={index} onClick={item.onClick} />
          ))}
        </div>
      )}
    </div>
  );
}

function Child({ data, onClick }) {
  const { text, icon } = data;
  return (
    <div
      onClick={onClick}
      className="flex-1 flex items-center justify-center px-2 py-3 text-sm text-black-primary cursor-pointer hover:bg-gray-light"
    >
      {icon && <span>{icon}</span>}
      <span>{text}</span>
    </div>
  );
}
