import { useEffect, useRef, useState } from "react";

export default function useTable({
  tableRef,
  columns,
  setColumns,
  data,
  setData,
  enableDrag,
  enableGrapScroll,
}) {
  const grabbing = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  const [drag, setDrag] = useState(enableDrag);
  const [dragging, setDragging] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const [lastSortedColumn, setLastSortedColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc"); // 1 for asc, -1 for desc

  useEffect(() => {
    const modifiedColumns = columns.filter((column) => {
      if (drag) return true;
      return column?.visible;
    });
    setTableColumns(modifiedColumns);
  }, [columns, drag]);

  useEffect(() => {
    setDrag(enableDrag);
  }, [enableDrag]);

  const onDragStart = (event, index) => {
    event.dataTransfer.setData("text/plain", index);
    setDragging(true);
  };

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const onDrop = (event, dropIndex) => {
    event.preventDefault();

    const dragIndex = parseInt(event.dataTransfer.getData("text/plain"));
    const updatedColumns = [...tableColumns];

    const [draggedColumn] = updatedColumns.splice(dragIndex, 1);
    updatedColumns.splice(dropIndex, 0, draggedColumn);

    setTableColumns(updatedColumns);
    setColumns([...updatedColumns]);
    setDragging(false);
  };

  const onMouseDown = (event) => {
    grabbing.current = true;
    startX.current = event.pageX - tableRef.current.offsetLeft;
    scrollLeft.current = tableRef.current.scrollLeft;
  };

  const onMouseUp = () => {
    grabbing.current = false;
  };

  const onMouseMove = (event) => {
    if (!grabbing.current || !enableGrapScroll) return;
    event.preventDefault();
    const x = event.pageX - tableRef.current.offsetLeft;
    const walk = (x - startX.current) * 1;
    tableRef.current.scrollLeft = scrollLeft.current - walk;
  };

  const sortTable = (columnName) => {
    let newSortOrder = "asc";

    if (lastSortedColumn === columnName) {
      newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    }

    const sortedData = [...data].sort((a, b) => {
      if (a[columnName] < b[columnName]) return sortOrder === "asc" ? -1 : 1;
      if (a[columnName] > b[columnName]) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });

    setLastSortedColumn(columnName);
    setSortOrder(newSortOrder);

    setData(sortedData);
  };

  const toggleColumnVisibility = (columnIndex) => {
    const updatedColumns = [...tableColumns];
    updatedColumns[columnIndex].visible = !updatedColumns[columnIndex].visible;
    setTableColumns(updatedColumns);
    setColumns([...updatedColumns]);
  };

  return {
    dragging,
    lastSortedColumn,
    onDragOver,
    onDragStart,
    onDrop,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    sortOrder,
    sortTable,
    tableColumns,
    toggleColumnVisibility,
  };
}
