import React, { useState } from "react";
import Sidebar from "./helpers/Sidebar";

const Home=({
    // selectedButton,
    // setSelectedButton
})=>{
    //const [selectedButton, setSelectedButton] = useState();
    return(
        <>
        <div className="containerw-1/2 md:w-full bg-blue-950 text-white flex flex-wrap dark:bg-indigo-900">
              {/* <Sidebar
                selectedButton={selectedButton}
                setSelectedButton={setSelectedButton}
              /> */}
                </div>
                Home page
</>

    )
}
export default Home;