import React from "react";
import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import Draggable from "react-draggable";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FileSaver } from "file-saver";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { read, utils, writeFile } from "xlsx";
import { color, px } from "framer-motion";
import moment, { utc } from "moment/moment";
import { Rnd } from "react-rnd";
import debounce from "lodash/debounce";
// External module
import Axios from "../../api-instance.js";
import "../../assets/css/container.css";
// import "./helpers/report.css";
import { DraggableReportTable } from "../../components";
import { Header } from "../../components";
// Internal modules
// import "./Lead.css";
import Sidebar from "./helpers/Sidebar.js";
import Selectallpages from "./Selectallpages";
// import { Checkvalidation } from "./helpers/Checkvalidation";
// import { Massupload } from "./helpers/Massupload";
// Chakara ui modules
import { useDisclosure } from "@chakra-ui/react";
import { Flex, Select, useToast, Box, Text, Tooltip } from "@chakra-ui/react";
import { Card, CardHeader, CardBody, CardFooter } from "@chakra-ui/react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerFooter,
  DrawerBody,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from "@chakra-ui/react";
import { Radio, RadioGroup, Stack, StackDivider } from "@chakra-ui/react";
// Icons import
import { MdDownloading, MdPrint, MdDownloadForOffline } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { HiOutlineMail } from "react-icons/hi";
import { LiaFileUploadSolid } from "react-icons/lia";
import { MdOutlineDownloadForOffline } from "react-icons/md";
import { GoMoveToEnd } from "react-icons/go";
import { FaFilter } from "react-icons/fa";
import { TbAdjustmentsHorizontal, TbTruckReturn } from "react-icons/tb";
import { BiSolidMinusCircle } from "react-icons/bi";
import { IoMdAddCircle, IoMdFastforward } from "react-icons/io";
import { FiArrowLeftCircle } from "react-icons/fi";
import { FiArrowRightCircle } from "react-icons/fi";
import { BsFiletypeExe } from "react-icons/bs";
import { SiMicrosoftoutlook } from "react-icons/si";
import { MdSelectAll } from "react-icons/md";
import { CgClose } from "react-icons/cg";
import { BiHide } from "react-icons/bi";
import { BiShow } from "react-icons/bi";
import { ReportTableCollapse } from "../../components/DraggableReportTable/ReportTableCollapse.js";
const Report = () => {
  // State Variables
  const navigate = useNavigate();
  const deleterecordshowhide = true;
  const [scrollBehavior, setScrollBehavior] = React.useState("inside");
  const [size, setSize] = React.useState("xl");
  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );
  const [overlay, setOverlay] = React.useState(<OverlayOne />);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const {
    isOpen: isOpenFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();
  const {
    isOpen: isOpenManagecolumn,
    onOpen: onOpenManagecolumn,
    onClose: onCloseManagecolumn,
  } = useDisclosure();
  const {
    isOpen: isOpenDownloadAllReport,
    onOpen: onOpenDownloadAllReport,
    onClose: onCloseDownloadAllReport,
  } = useDisclosure();

  const {
    isOpen: isOpenDownload,
    onOpen: onOpenDownload,
    onClose: onCloseDownload,
  } = useDisclosure();

  const cancelRef = React.useRef();
  const searchRef = useRef(null);
  const printAreaRef = useRef(null);

  const [movies, setMovies] = useState([]);
  const [selectAllPages, setSelectAllPages] = useState(false);
  const [checkBoxSelected, setCheckBoxSelected] = useState([]);
  const [checkBoxAll, setCheckBoxAll] = useState(false);
  const [excelOpen, setExcelOpen] = useState(false);
  const toast = useToast();
  const [isActionOpen, setActionOpen] = useState(false);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isManagecolumnOpen, setManagecolumnOpen] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [columns2, setColumns2] = useState([]);
  const [handleCheckboxFilter, setHandleCheckboxFilter] = useState("OR");
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState("Select a field");
  const [updatedValue, setUpdatedValue] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [leadData, setleadData] = useState();
  const [prospectData, setprospectData] = useState();
  const [meetData, setmeetData] = useState();
  const [callData, setcallData] = useState();
  const [conductedmeetData, setconductedmeetData] = useState();
  const [canceledmeetData, setcanceledmeetData] = useState();
  const [conductedcallData, setconductedcallData] = useState();
  const [canceledcallData, setcanceledcallData] = useState();
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadComplete, setDownloadComplete] = useState(false);
  const [selectedExportOption, setSelectedExportOption] = useState("");
  const [exportData, setExportData] = useState([]);
  const [fromDate, setfromDate] = useState();
  const [toDate, settoDate] = useState();
  const [transformedArrayState, setTransformedArrayState] = useState([]);
  const [selectedAllReportExportOption, setselectedAllReportExportOption] =
    useState("");
  const [FilterData, setFilterData] = useState([]);
  const [Intialvalue, setIntialvalue] = useState([]);
  const [userdata, setUserdata] = useState([]);

  // Column Toggle
  const [columns, setColumns] = useState({
    Id: true,
    Leads: true,
    Prospects: true,
    Meetings: true,
    LeadOwnerName: true,
    Calls: true,
    fromDate: true,
    toDate: true,
    LeadIds: true,
    MeetingIds: true,
    ProspectIds: true,
    CallIds: true,
    ConductedMeetings: true,
    CancelledMeetings: true,
    ConductedCalls: true,
    CancelledCalls: true,
    ConductedMeetingIds: true,
    CanceledMeetingIds: true,
    ConductedCallIds: true,
    CanceledCallIds: true,
  });
  const [tableColumnsReport, settableColumnsReport] = useState([]);

  useEffect(() => {
    getData();
  }, []); //  state changes
  // Fetch user data from an API when the component mounts
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await Axios.get(
          `user/get_user?userId=${
            JSON.parse(localStorage.getItem("user")).userId
          }`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: JSON.parse(localStorage.getItem("token")),
            },
          }
        );

        setUserdata(response?.data?.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };

    getData();
  }, []);
  const UserName =
    userdata.length > 0
      ? `${userdata[0].firstname} ${userdata[0].lastname}`
      : "";

  // Export data
  const handleExportOptionChange = (e) => {
    setSelectedExportOption(e.target.value);
    setDownloadComplete(false);
  };
  const handleExport = () => {
    setIsDownloading(true);
    setDownloadComplete(false);

    if (selectedExportOption === "Excel") {
      // Simulate the download process with a setTimeout
      setTimeout(() => {
        exportToExcel(
          exportData,
          leadData,
          prospectData,
          meetData,
          callData,
          conductedmeetData,
          canceledmeetData,
          conductedcallData,
          canceledcallData,
          "DSR",
          transformedArrayState,
          UserName
        );
        setIsDownloading(false);
        setDownloadComplete(true);
      }, 2000); // 2 seconds delay for demonstration purposes
    }
    // else if (selectedExportOption === "CSV") {
    //     setTimeout(() => {
    //         const dataSets = [
    //             {
    //                 name: "DSR Data",
    //                 headers: ["LeadOwnerName", "Id", "Leads", "Prospects", "Meetings","Calls"],
    //                 data: exportData.map(item => [item.LeadOwnerName, item.Id, item.Leads, item.Prospects, item.Meetings,item.Calls])
    //             },
    //             {
    //                 name: "Lead Data",
    //                 headers: ["LeadOwnerName", "LeadNumber", "Company", "Email", "ContactPerson", "City", "Remarks", "CreatedAt"],
    //                 data: leadData.map(item => [item.LeadOwnerName, item.LeadNumber, item.Company, item.Email, item.ContactPerson, item.City, item.Remarks, moment(item.CreatedAt).format('YYYY-MM-DD')])
    //             },
    //             {
    //                 name: "Prospect Data",
    //                 headers: ["ProspectOwnerName", "LeadNumber", "Company", "Email", "ContactPerson", "City", "Remarks", "CreatedAt"],
    //                 data: prospectData.map(item => [item.ProspectOwnerName, item.ProspectNumber, item.Company, item.Email, item.ContactPerson, item.City, item.Remarks, moment(item.CreatedAt).format('YYYY-MM-DD')])
    //             },
    //             {
    //                 name:"Meeting Data",
    //                 headers:["LeadOwnerName", "Meeting Id","Meeting Date","Time","Meeting Remarks"],
    //                 data: meetData.map(item => [item.LeadOwnerName,item.MeetingId, moment(item.MeetingDate).format('YYYY-MM-DD'),moment.utc(item.Time).format('HH:mm:ss   '),item.MeetingRemarks])
    //             },
    //             {
    //                 name:"Call Data",
    //                 headers:["LeadOwnerName", "Meeting Id","Meeting Date","Time","Meeting Remarks"],
    //                 data: callData.map(item => [item.LeadOwnerName,item.callid, moment(item.callDate).format('YYYY-MM-DD'),moment.utc(item.time).format('HH:mm:ss   '),item.callRemarks])
    //             }
    //         ];
    //         exportToCSV(dataSets, "DSR",transformedArrayState);
    //         setIsDownloading(false);
    //         setDownloadComplete(true);
    //     }, 2000);
    // }
    else if (selectedExportOption === "PDF") {
      setTimeout(() => {
        exportToPDF(
          exportData,
          leadData,
          prospectData,
          meetData,
          callData,
          conductedmeetData,
          canceledmeetData,
          conductedcallData,
          canceledcallData,
          "DSR",
          transformedArrayState,
          UserName
        );
        setIsDownloading(false);
        setDownloadComplete(true);
      }, 2000);
    }
    // else if (selectedExportOption === "Text") {
    //   setTimeout(() => {
    //     const dataSets = [
    //         {
    //             name: "DSR Data",
    //             headers: ["LeadOwnerName", "Id", "Leads", "Prospects", "Meetings"],
    //             data: exportData.map(item => [item.LeadOwnerName, item.Id, item.Leads, item.Prospects, item.Meetings])
    //         },
    //         {
    //             name: "Lead Data",
    //             headers: ["LeadOwnerName", "LeadNumber", "Company", "Email", "ContactPerson", "City", "Remarks", "CreatedAt"],
    //             data: leadData.map(item => [item.LeadOwnerName, item.LeadNumber, item.Company, item.Email, item.ContactPerson, item.City, item.Remarks, moment(item.CreatedAt).format('YYYY-MM-DD')])
    //         },
    //         {
    //             name: "Prospect Data",
    //             headers: ["ProspectOwnerName", "LeadNumber", "Company", "Email", "ContactPerson", "City", "Remarks", "CreatedAt"],
    //             data: leadData.map(item => [item.LeadOwnerName, item.ProspectNumber, item.Company, item.Email, item.ContactPerson, item.City, item.Remarks, moment(item.CreatedAt).format('YYYY-MM-DD')])
    //         },
    //         {
    //             name:"Meeting Data",
    //             headers:["LeadOwnerName", "Meeting Id","Meeting Date","Time","Meeting Remarks"],
    //             data: meetData.map(item => [item.LeadOwnerName,item.MeetingId, moment(item.MeetingDate).format('YYYY-MM-DD'),moment.utc(item.Time).format('HH:mm:ss   '),item.MeetingRemarks])
    //         },
    //         {
    //             name:"Call Data",
    //             headers:["LeadOwnerName", "Meeting Id","Meeting Date","Time","Meeting Remarks"],
    //             data: callData.map(item => [item.LeadOwnerName,item.callid, moment(item.callDate).format('YYYY-MM-DD'),moment.utc(item.time).format('HH:mm:ss   '),item.callRemarks])
    //         }
    //     ];
    //     exportToText(dataSets, "DSR",transformedArrayState);
    //     setIsDownloading(false);
    //     setDownloadComplete(true);
    //   }, 2000);
    // }
  };
  function getHeaders(sheetName) {
    switch (sheetName) {
      case "DSR Data":
        return;
      case "Lead Data":
        return [
          "LeadOwnerName",
          "Lead Number",
          "Company",
          "Email",
          "Contact Person",
          "City",
          "Remarks",
          "Created At [YYYY-MM-DD]",
        ];
      case "Prospect Data":
        return [
          "ProspectOwnerName",
          "Prospect Number",
          "Company",
          "Email",
          "Contact Person",
          "City",
          "Remarks",
          "Created At [YYYY-MM-DD]",
        ];
      case "Meeting Data":
        // You need to adjust this based on the structure of your meetData
        return [
          "LeadOwnerName",
          "Company",
          "Meeting Date [YYYY-MM-DD]",
          "Meeting Time   ",
          "Meeting Remarks",
        ];
      case "Call Data":
        // You need to adjust this based on the structure of your meetData
        return [
          "LeadOwnerName",
          "Company",
          "Call Date [YYYY-MM-DD]",
          "Call Time   ",
          "Call Remarks",
        ];
      case "Meeting Data-Conducted":
        // You need to adjust this based on the structure of your meetData
        return [
          "LeadOwnerName",
          "Company",
          "Meeting Date [YYYY-MM-DD]",
          "Meeting Time   ",
          "Meeting Remarks",
        ];
      case "Meeting Data-Cancelled":
        // You need to adjust this based on the structure of your meetData
        return [
          "LeadOwnerName",
          "Company",
          "Meeting Date [YYYY-MM-DD]",
          "Meeting Time   ",
          "Meeting Remarks",
        ];
      case "Call Data-Conducted":
        // You need to adjust this based on the structure of your meetData
        return [
          "LeadOwnerName",
          "Company",
          "Call Date [YYYY-MM-DD]",
          "Call Time   ",
          "Call Remarks",
        ];
      case "Call Data-Cancelled":
        // You need to adjust this based on the structure of your meetData
        return [
          "LeadOwnerName",
          "Company",
          "Call Date [YYYY-MM-DD]",
          "Call Time   ",
          "Call Remarks",
        ];
      default:
        return [];
    }
  }
  const exportToExcel = (
    data,
    leadData,
    prospectData,
    meetData,
    callData,
    conductedmeetData,
    canceledmeetData,
    conductedcallData,
    canceledcallData,
    fileName,
    transformedArrayState,
    UserName
  ) => {
    // Check if data is an array and has elements
    if (!Array.isArray(data) || data.length === 0) {
      console.log("No data available.");
      return;
    }

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const dsrData = [];
    dsrData.push(["Downloaded by:", UserName]);

    if (transformedArrayState.length === 0) {
      // If transformedArrayState is empty, consider the current week's Monday date as fromDate
      const fromDate = moment().startOf("isoWeek").format("YYYY-MM-DD");
      // Consider the current date as toDate
      const toDate = moment().format("YYYY-MM-DD");
      // Push From Date and To Date rows
      dsrData.push(["From Date", fromDate, "", "", ""]);
      dsrData.push(["To Date", toDate, "", "", ""]);
    } else {
      transformedArrayState.forEach((dateSet) => {
        // Push From Date and To Date rows
        dsrData.push(["From Date", dateSet.fromDate, "", "", ""]);
        dsrData.push(["To Date", dateSet.toDate, "", "", ""]);

        // Push DSR Data headers
      });
    }
    const dsrHeaderRow = [
      "LeadOwnerName",
      "Id",
      "Leads",
      "Prospects",
      "Meetings",
      "Calls",
      "Conducted Meeting",
      "Cancelled Meeting",
      "Conducted Calls",
      "Cancelled Calls",
    ];
    dsrData.push(dsrHeaderRow);

    // Push DSR Data rows
    data.forEach((item) => {
      const dsrDataRow = [
        item.LeadOwnerName,
        item.Id,
        item.Leads,
        item.Prospects,
        item.Meetings,
        item.Calls,
        item.ConductedMeetings,
        item.CancelledMeetings,
        item.ConductedCalls,
        item.CancelledCalls,
      ];
      dsrData.push(dsrDataRow);
    });
    const dataArrays = {
      "DSR Data": dsrData,
      "Lead Data": leadData
        ? leadData.map((item) => [
            item.LeadOwnerName,
            item.LeadNumber,
            item.Company,
            item.Email,
            item.ContactPerson,
            item.City,
            item.Remarks,
            moment(item.CreatedAt).format("YYYY-MM-DD"),
          ])
        : [],
      "Prospect Data": prospectData
        ? prospectData.map((item) => [
            item.ProspectOwnerName,
            item.ProspectNumber,
            item.Company,
            item.Email,
            item.ContactPerson,
            item.City,
            item.Remarks,
            moment(item.CreatedAt).format("YYYY-MM-DD"),
          ])
        : [],
      "Meeting Data": meetData
        ? meetData.map((item) => [
            item.LeadOwnerName,
            item.Company,
            moment(item.MeetingDate).format("YYYY-MM-DD"),
            item.MeetingTime,
            item.MeetingRemarks,
          ])
        : [],
      "Call Data": callData
        ? callData.map((item) => [
            item.LeadOwnerName,
            item.Company,
            moment(item.CallDate).format("YYYY-MM-DD"),
            item.CallsTime,
            item.CallRemarks,
          ])
        : [],
      "Meeting Data-Conducted": conductedmeetData
        ? conductedmeetData.map((item) => [
            item.LeadOwnerName,
            item.Company,
            moment(item.MeetingDate).format("YYYY-MM-DD"),
            item.MeetingTime,
            item.MeetingRemarks,
          ])
        : [],
      "Meeting Data-Cancelled": canceledmeetData
        ? canceledmeetData.map((item) => [
            item.LeadOwnerName,
            item.Company,
            moment(item.MeetingDate).format("YYYY-MM-DD"),
            item.MeetingTime,
            item.MeetingRemarks,
          ])
        : [],
      "Call Data-Conducted": conductedcallData
        ? conductedcallData.map((item) => [
            item.LeadOwnerName,
            item.Company,
            moment(item.CallDate).format("YYYY-MM-DD"),
            item.CallsTime,
            item.CallRemarks,
          ])
        : [],
      "Call Data-Cancelled": canceledcallData
        ? canceledcallData.map((item) => [
            item.LeadOwnerName,
            item.Company,
            moment(item.CallDate).format("YYYY-MM-DD"),
            item.CallsTime,
            item.CallRemarks,
          ])
        : [],
    };

    const wb = XLSX.utils.book_new();

    Object.entries(dataArrays).forEach(([sheetName, dataArray]) => {
      if (dataArray.length > 0) {
        const headers = getHeaders(sheetName); // Dynamically get headers based on sheetName
        const ws = XLSX.utils.aoa_to_sheet([headers, ...dataArray]);
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
      }
    });

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: fileType });

    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `${fileName}_${currentDate}_${currentTime}${fileExtension}`;
    saveAs(dataBlob, finalFileName);
  };

  // const exportToCSV = (dataSets, fileName, transformedArrayState) => {
  //     let csvContent = '';

  //     // Generate fromDate and toDate rows
  //     transformedArrayState.forEach((dateSet, index) => {
  //         csvContent += `"From Date","${dateSet.fromDate}"\n`;
  //         csvContent += `"To Date","${dateSet.toDate}"\n\n`;
  //     });

  //     // If transformedArrayState is empty, consider the current week's Monday date as fromDate
  //     // and the current date as toDate
  //     if (transformedArrayState.length === 0) {
  //         const fromDate = moment().startOf('isoWeek').format('YYYY-MM-DD');
  //         const toDate = moment().format('YYYY-MM-DD');
  //         csvContent += `"From Date","${fromDate}"\n`;
  //         csvContent += `"To Date","${toDate}"\n\n`;
  //     }

  //     // Function to generate CSV content for a given dataset
  //     const generateCSVContent = ({ name, headers, data }) => {
  //         let content = `"${name}"\n`;
  //         if (headers && headers.length > 0) {
  //             content += `"${headers.join('","')}"\n`;
  //         }
  //         if (data && data.length > 0) {
  //             content += data.map(row => `"${row.join('","')}"`).join('\n');
  //         }
  //         content += '\n\n';
  //         return content;
  //     };

  //     // Iterate over each dataset and generate CSV content
  //     dataSets.forEach(dataSet => {
  //         csvContent += generateCSVContent(dataSet);
  //     });

  //     const fileType = "text/csv;charset=utf-8;";
  //     const fileExtension = ".csv";

  //     const csvDataBlob = new Blob([csvContent], {
  //         type: fileType,
  //     });

  //     const currentDate = new Date().toISOString().split("T")[0];
  //     const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
  //     const finalFileName = `${fileName}_${currentDate}_${currentTime}${fileExtension}`;

  //     saveAs(csvDataBlob, finalFileName);
  // };

  const exportToPDF = (
    data,
    leadData,
    prospectData,
    meetData,
    callData,
    conductedmeetData,
    canceledmeetData,
    conductedcallData,
    canceledcallData,
    fileName,
    transformedArrayState,
    UserName
  ) => {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";

    const doc = new jsPDF(orientation, unit, size);
    const titleFontSize = 8;
    const marginLeft = 40;
    const marginTop = 40;

    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");

    const downloadedByContent = `Downloaded by: ${UserName}`;
    doc.setFontSize(titleFontSize);
    doc.text(downloadedByContent, marginLeft, marginTop);
    let startY = marginTop + titleFontSize + 10; // Update startY position

    const generateTable = (title, rowData, columnsToPrint, startY) => {
      doc.setFontSize(titleFontSize);
      doc.text(title, marginLeft, startY);

      // Check if rowData is an array and has elements
      if (!Array.isArray(rowData) || rowData.length === 0) {
        doc.text("No data available", marginLeft, startY + 20);
        return;
      }

      const tableColumns = columnsToPrint.map((column) => {
        return {
          header: column.label || column,
          dataKey: column.key || column,
        };
      });

      const filteredRows = rowData.map((item) => {
        const filteredItem = {};
        columnsToPrint.forEach((column) => {
          // If the key is "Time", format it using moment.js to GMT with AM/PM
          if (
            column.key === "CreatedAt" ||
            column.key === "MeetingDate" ||
            (column.key === "CallDate" && moment(item[column.key]).isValid())
          ) {
            // If the key is a date, keep it as date format
            filteredItem[column.key] = moment(item[column.key]).format(
              "YYYY-MM-DD"
            );
          } else {
            filteredItem[column.key || column] = item[column.key || column];
          }
        });
        return filteredItem;
      });

      const tableRows = filteredRows.map((item) => Object.values(item));

      doc.autoTable({
        startY: startY + 10,
        head: [tableColumns.map((column) => column.header)],
        body: tableRows,
        didDrawCell: (data) => {
          doc.rect(
            data.cell.x,
            data.cell.y,
            data.cell.width,
            data.cell.height,
            "S"
          );
        },
        styles: {
          fontSize: 8, // Font size for the table content
        },
        headStyles: {
          fontSize: 8, // Font size for the table heading
          fontStyle: "bold", // Make the table heading bold
        },
      });
    };
    // let startY = marginTop;
    let transformedArrayContent = "";
    if (transformedArrayState.length === 0) {
      const currentDate = new Date();
      const currentDay = currentDate.getDay();
      const fromMonday = new Date(currentDate);
      fromMonday.setDate(
        currentDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1)
      );
      const toDate = currentDate.toISOString().split("T")[0];
      const fromDate = fromMonday.toISOString().split("T")[0];
      transformedArrayContent = `From Date        : ${fromDate}\nTo Date            : ${toDate}\n\n`;
    } else {
      transformedArrayContent = transformedArrayState
        .map(
          (item) =>
            `From Date        : ${item.fromDate}\nTo Date            : ${item.toDate}\n\n`
        )
        .join("");
    }

    // Split transformedArrayContent into separate lines and render each line individually
    const lines = transformedArrayContent.split("\n");
    lines.forEach((line) => {
      doc.setFontSize(titleFontSize);
      doc.text(line, marginLeft, startY);
      startY += titleFontSize; // Move startY position for the next line
    });

    // Update startY position after rendering transformedArrayState content
    startY += 10; // Add extra space after the last line

    const DataColumnsToPrint = [
      { label: "Lead Owner Name", key: "LeadOwnerName" },
      { label: "Id", key: "Id" },
      { label: "Leads", key: "Leads" },
      { label: "Prospects", key: "Prospects" },
      { label: "Meetings", key: "Meetings" },
      { label: "Calls", key: "Calls" },
      { label: "Conducted Meetings", key: "ConductedMeetings" },
      { label: "Cancelled Meetings", key: "CancelledMeetings" },
      { label: "Conducted Calls", key: "ConductedCalls" },
      { label: "Cancelled Calls", key: "CancelledCalls" },
    ];

    // Define columns to print for leadData
    const leadColumnsToPrint = [
      { label: "LeadOwnerName", key: "LeadOwnerName" },
      { label: "Lead Number", key: "LeadNumber" },
      { label: "Company", key: "Company" },
      { label: "Email", key: "Email" },
      { label: "Contact Person", key: "ContactPerson" },
      { label: "City", key: "City" },
      { label: "Remarks", key: "Remarks" },
      { label: "Created At [YYYY-MM-DD]", key: "CreatedAt" },
    ];
    const prospectColumnsToPrint = [
      { label: "ProspectOwnerName", key: "ProspectOwnerName" },
      { label: "Prospect Number", key: "ProspectNumber" },
      { label: "Company", key: "Company" },
      { label: "Email", key: "Email" },
      { label: "Contact Person", key: "ContactPerson" },
      { label: "City", key: "City" },
      { label: "Remarks", key: "Remarks" },
      { label: "Created At [YYYY-MM-DD]", key: "CreatedAt" },
    ];
    const meetingsToPrint = [
      { label: "LeadOwnerName", key: "LeadOwnerName" },
      { label: "Company", key: "Company" },
      { label: "Meeting Date [YYYY-MM-DD]", key: "MeetingDate" },
      { label: "Meeting Time", key: "MeetingTime" },
      { label: "Meeting Remarks", key: "MeetingRemarks" },
    ];
    const callsToPrint = [
      { label: "LeadOwnerName", key: "LeadOwnerName" },
      { label: "Company", key: "Company" },
      { label: "Call Date [YYYY-MM-DD]", key: "CallDate" },
      { label: "Call Time", key: "CallsTime" },
      { label: "Call Remarks", key: "CallRemarks" },
    ];
    const meetingsConductedToPrint = [
      { label: "LeadOwnerName", key: "LeadOwnerName" },
      { label: "Company", key: "Company" },
      { label: "Meeting Date [YYYY-MM-DD]", key: "MeetingDate" },
      { label: "Meeting Time", key: "MeetingTime" },
      { label: "Meeting Remarks", key: "MeetingRemarks" },
    ];
    const meetingsCanceledToPrint = [
      { label: "LeadOwnerName", key: "LeadOwnerName" },
      { label: "Company", key: "Company" },
      { label: "Meeting Date [YYYY-MM-DD]", key: "MeetingDate" },
      { label: "Meeting Time", key: "MeetingTime" },
      { label: "Meeting Remarks", key: "MeetingRemarks" },
    ];
    const callsConductedToPrint = [
      { label: "LeadOwnerName", key: "LeadOwnerName" },
      { label: "Company", key: "Company" },
      { label: "Call Date [YYYY-MM-DD]", key: "CallDate" },
      { label: "Call Time", key: "CallsTime" },
      { label: "Call Remarks", key: "CallRemarks" },
    ];
    const callsCanceledToPrint = [
      { label: "LeadOwnerName", key: "LeadOwnerName" },
      { label: "Company", key: "Company" },
      { label: "Call Date [YYYY-MM-DD]", key: "CallDate" },
      { label: "Call Time", key: "CallsTime" },
      { label: "Call Remarks", key: "CallRemarks" },
    ];

    // Generate tables for each dataset
    generateTable("DSR Data", data, DataColumnsToPrint, startY);
    startY = doc.autoTable.previous.finalY + 10;

    if (Array.isArray(leadData) && leadData.length > 0) {
      generateTable("Lead Data", leadData, leadColumnsToPrint, startY);
      startY = doc.autoTable.previous.finalY + 10;
    } else {
      console.log("Lead data is empty or not an array.");
    }

    if (Array.isArray(prospectData) && prospectData.length > 0) {
      generateTable(
        "Prospect Data",
        prospectData,
        prospectColumnsToPrint,
        startY
      );
      startY = doc.autoTable.previous.finalY + 10;
    } else {
      console.log("Prospect data is empty or not an array.");
    }

    if (Array.isArray(meetData) && meetData.length > 0) {
      generateTable("Meeting Data", meetData, meetingsToPrint, startY);
      startY = doc.autoTable.previous.finalY + 10; // Update startY position
    } else {
      console.log("Meet data is empty or not an array.");
    }

    if (Array.isArray(callData) && callData.length > 0) {
      generateTable("Call Data", callData, callsToPrint, startY);
      startY = doc.autoTable.previous.finalY + 10;
    } else {
      console.log("Call data is empty or not an array.");
    }
    if (Array.isArray(conductedmeetData) && conductedmeetData.length > 0) {
      generateTable(
        "Meeting Data-Conducted",
        conductedmeetData,
        meetingsConductedToPrint,
        startY
      );
      startY = doc.autoTable.previous.finalY + 10;
    } else {
      console.log("Conducted meeting data is empty or not an array.");
    }

    if (Array.isArray(canceledmeetData) && canceledmeetData.length > 0) {
      generateTable(
        "Meeting Data-Cancelled",
        canceledmeetData,
        meetingsCanceledToPrint,
        startY
      );
      startY = doc.autoTable.previous.finalY + 10;
    } else {
      console.log("Cancelled meeting data is empty or not an array.");
    }

    if (Array.isArray(conductedcallData) && conductedcallData.length > 0) {
      generateTable(
        "Online Call Data-Conducted",
        conductedcallData,
        callsConductedToPrint,
        startY
      );
      startY = doc.autoTable.previous.finalY + 10;
    } else {
      console.log("Conducted online call data is empty or not an array.");
    }

    if (Array.isArray(canceledcallData) && canceledcallData.length > 0) {
      generateTable(
        "Call Data-Cancelled",
        canceledcallData,
        callsCanceledToPrint,
        startY
      );
      startY = doc.autoTable.previous.finalY + 10;
    } else {
      console.log("Cancelled Call data is empty or not an array.");
    }

    const finalFileName = `${fileName}_${currentDate}_${currentTime}.pdf`;
    doc.save(finalFileName);
  };

  // const exportToText = (dataSets, fileName, transformedArrayState) => {
  //     let textContent = '';

  //     if (transformedArrayState.length === 0) {
  //         // If transformedArrayState is empty, consider the current week's Monday date as fromDate
  //         const fromDate = moment().startOf('isoWeek').format('YYYY-MM-DD');
  //         // Consider the current date as toDate
  //         const toDate = moment().format('YYYY-MM-DD');
  //         textContent += `"From Date","${fromDate}"\n`;
  //         textContent += `"To Date","${toDate}"\n`;
  //     } else {
  //         transformedArrayState.forEach(dateSet => {
  //             textContent += `"From Date","${dateSet.fromDate}"\n`;
  //             textContent += `"To Date","${dateSet.toDate}"\n`;
  //         });
  //     };

  //     dataSets.forEach(({ name, headers, data }) => {
  //         textContent += `"${name}"\n`;
  //         if (headers && headers.length > 0) {
  //             textContent += `"${headers.join('","')}"\n`;
  //         }
  //         const tableRows = data.map(row => row.join(","));
  //         textContent += tableRows.join('\n');
  //         textContent += '\n\n';
  //     });

  //     const fileType = "text/plain;charset=utf-8;";
  //     const fileExtension = "txt";

  //     const textDataBlob = new Blob([textContent], {
  //         type: fileType,
  //     });

  //     const currentDate = new Date().toISOString().split("T")[0];
  //     const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
  //     const finalFileName = `${fileName}_${currentDate}_${currentTime}.${fileExtension}`;

  //     saveAs(textDataBlob, finalFileName);
  // };

  // All report Download

  const handleAllReportExportOptionChange = (e) => {
    setselectedAllReportExportOption(e.target.value);
    setDownloadComplete(false);
  };

  const handleAllReportExport = () => {
    setIsDownloading(true);
    setDownloadComplete(false);

    if (selectedAllReportExportOption === "Excel") {
      // Simulate the download process with a setTimeout
      setTimeout(() => {
        handleGenerateExcelClick();
        //exportToExcel(exportData, leadData, prospectData, meetData, callData, "DSR", transformedArrayState);
        setIsDownloading(false);
        setDownloadComplete(true);
      }, 2000); // 2 seconds delay for demonstration purposes
    } else if (selectedAllReportExportOption === "PDF") {
      setTimeout(() => {
        handleGeneratePDFClick();
        //exportToPDF(exportData, leadData, prospectData, meetData, callData, "DSR", transformedArrayState);
        setIsDownloading(false);
        setDownloadComplete(true);
      }, 2000);
    }
  };

  const handleGenerateExcelClick = async () => {
    try {
      // Fetch data from API
      const response = await Axios.post(
        `/report/get_dsr_all_download?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        { sendData: transformedArrayState },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      console.log("Response from API:", response);

      // Check if response data is empty
      if (!response.data) {
        console.error(
          "Error generating Excel workbook: Response data is empty"
        );
        return;
      }

      // Call createExcelWorkbook function with the fetched data
      await createExcelWorkbook(response.data.data, "DSRAllData", UserName); // You can specify the filename here
    } catch (error) {
      console.error("Error generating Excel workbook:", error);
    }
  };
  const createExcelWorkbook = async (data, fileName, UserName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const wb = XLSX.utils.book_new();

    // Prepare array to hold all fromDate and toDate
    const datePairs = [];

    // If transformedArrayState is empty, calculate fromDate and toDate
    if (transformedArrayState.length === 0) {
      const currentDate = new Date();
      const currentDay = currentDate.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
      const fromMonday = new Date(currentDate); // Clone the current date
      fromMonday.setDate(
        currentDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1)
      ); // Calculate Monday of the current week
      const toDate = currentDate.toISOString().split("T")[0];
      const fromDate = fromMonday.toISOString().split("T")[0];
      datePairs.push(["From Date", fromDate]);
      datePairs.push(["To Date", toDate]);
    } else {
      // Extract fromDate and toDate from transformedArrayState
      transformedArrayState.forEach((item) => {
        datePairs.push(["From Date", item.fromDate]);
        datePairs.push(["To Date", item.toDate]);
      });
    }

    // Sheet 1: DSR Data
    const dsrDataArray = [];
    dsrDataArray.push(["Downloaded by:", UserName]);
    // Add fromDate and toDate pairs
    datePairs.forEach((pair) => {
      dsrDataArray.push(pair);
    });

    // Add table heading
    dsrDataArray.push([
      "LeadOwnerName",
      "Id",
      "Leads",
      "Prospects",
      "Meetings",
      "Calls",
      "Conducted Meetings",
      "Cancelled Meetings",
      "Conducted Calls",
      "Cancelled Calls",
    ]);

    // Add table content
    data.forEach((item) => {
      dsrDataArray.push([
        item.LeadOwnerName,
        item.Id,
        item.Leads,
        item.Prospects,
        item.Meetings,
        item.Calls,
        item.ConductedMeetings,
        item.CancelledMeetings,
        item.ConductedCalls,
        item.CancelledCalls,
      ]);
    });

    const dsrDataWs = XLSX.utils.aoa_to_sheet(dsrDataArray);
    XLSX.utils.book_append_sheet(wb, dsrDataWs, "DSR Data");

    // Sheet 2: Lead Data
    const leadDataArray = data.reduce((accumulator, current) => {
      return accumulator.concat(
        current.LeadData.map((lead) => [
          lead.LeadOwnerName,
          lead.LeadNumber,
          lead.Company,
          lead.Email,
          lead.ContactPerson,
          lead.City,
          lead.Remarks,
          moment(lead.CreatedAt).format("YYYY-MM-DD"),
        ])
      );
    }, []);
    const leadDataWs = XLSX.utils.aoa_to_sheet([
      [
        "LeadOwnerName",
        "Lead Number",
        "Company",
        "Email",
        "Contact Person",
        "City",
        "Remarks",
        "Created At [YYYY-MM-DD]",
      ],
      ...leadDataArray,
    ]);
    XLSX.utils.book_append_sheet(wb, leadDataWs, "Lead Data");

    // Sheet 3: Prospect Data
    // Assuming Prospect Data has the same structure as Lead Data
    // Replace LeadData with ProspectData in the above code if structure differs
    const prospectDataArray = data.reduce((accumulator, current) => {
      return accumulator.concat(
        current.ProspectData.map((prospect) => [
          prospect.ProspectOwnerName,
          prospect.ProspectNumber,
          prospect.Company,
          prospect.Email,
          prospect.ContactPerson,
          prospect.City,
          prospect.Remarks,
          moment(prospect.CreatedAt).format("YYYY-MM-DD"),
        ])
      );
    }, []);
    const prospectDataWs = XLSX.utils.aoa_to_sheet([
      [
        "ProspectOwnerName",
        "ProspectNumber",
        "Company",
        "Email",
        "ContactPerson",
        "City",
        "Remarks",
        "CreatedAt [YYYY-MM-DD]",
      ],
      ...prospectDataArray, // Reusing leadDataArray for Prospect Data
    ]);
    XLSX.utils.book_append_sheet(wb, prospectDataWs, "Prospect Data");

    // Sheet 4: Meeting Data
    const meetingDataArray = data.reduce((accumulator, current) => {
      return accumulator.concat(
        current.MeetingData.map((meeting) => [
          meeting.LeadOwnerName,
          meeting.Company,
          moment(meeting.MeetingDate).format("YYYY-MM-DD"),
          meeting.MeetingTime,
          meeting.MeetingRemarks,
        ])
      );
    }, []);
    const meetingDataWs = XLSX.utils.aoa_to_sheet([
      [
        "LeadOwnerName",
        "Company",
        "Meeting Date [YYYY-MM-DD]",
        "Meeting Time   ",
        "Meeting Remarks",
      ],
      ...meetingDataArray,
    ]);
    XLSX.utils.book_append_sheet(wb, meetingDataWs, "Meeting Data");

    // Sheet 5: Call Data
    const callDataArray = data.reduce((accumulator, current) => {
      return accumulator.concat(
        current.CallData.map((call) => [
          call.LeadOwnerName,
          call.Company,
          moment(call.CallDate).format("YYYY-MM-DD"),
          call.CallsTime,
          call.CallRemarks,
        ])
      );
    }, []);
    const callDataWs = XLSX.utils.aoa_to_sheet([
      [
        "LeadOwnerName",
        "Company",
        "Call Date [YYYY-MM-DD]",
        "Call Time   ",
        "Call Remarks",
      ],
      ...callDataArray,
    ]);
    XLSX.utils.book_append_sheet(wb, callDataWs, "Call Data");

    // Sheet 6: Meeting conducted Data
    const meetingConductedDataArray = data.reduce((accumulator, current) => {
      return accumulator.concat(
        current.meetingConductedData.map((meeting) => [
          meeting.LeadOwnerName,
          meeting.Company,
          moment(meeting.MeetingDate).format("YYYY-MM-DD"),
          meeting.MeetingTime,
          meeting.MeetingRemarks,
        ])
      );
    }, []);
    const meetingConductedDataWs = XLSX.utils.aoa_to_sheet([
      [
        "LeadOwnerName",
        "Company",
        "Meeting Date [YYYY-MM-DD]",
        "Meeting Time   ",
        "Meeting Remarks",
      ],
      ...meetingConductedDataArray,
    ]);
    XLSX.utils.book_append_sheet(
      wb,
      meetingConductedDataWs,
      "Meeting Data-Conducted"
    );

    // Sheet 7: Meeting canceled Data
    const meetingCanceledDataArray = data.reduce((accumulator, current) => {
      return accumulator.concat(
        current.meetingCanceledData.map((meeting) => [
          meeting.LeadOwnerName,
          meeting.Company,
          moment(meeting.MeetingDate).format("YYYY-MM-DD"),
          meeting.MeetingTime,
          meeting.MeetingRemarks,
        ])
      );
    }, []);
    const meetingCanceledDataWs = XLSX.utils.aoa_to_sheet([
      [
        "LeadOwnerName",
        "Company",
        "Meeting Date [YYYY-MM-DD]",
        "Meeting Time   ",
        "Meeting Remarks",
      ],
      ...meetingCanceledDataArray,
    ]);
    XLSX.utils.book_append_sheet(
      wb,
      meetingCanceledDataWs,
      "Meeting Data-Cancelled"
    );

    // Sheet 8: Call Data conducted
    const callConductedDataArray = data.reduce((accumulator, current) => {
      return accumulator.concat(
        current.callConductedData.map((call) => [
          call.LeadOwnerName,
          call.Company,
          moment(call.CallDate).format("YYYY-MM-DD"),
          call.CallsTime,
          call.CallRemarks,
        ])
      );
    }, []);
    const callConductedDataWs = XLSX.utils.aoa_to_sheet([
      [
        "LeadOwnerName",
        "Company",
        "Call Date [YYYY-MM-DD]",
        "Call Time   ",
        "Call Remarks",
      ],
      ...callConductedDataArray,
    ]);
    XLSX.utils.book_append_sheet(
      wb,
      callConductedDataWs,
      "Call Data-Conducted"
    );

    // Sheet 9: Call Data canceled
    const callCanceledDataArray = data.reduce((accumulator, current) => {
      return accumulator.concat(
        current.callCanceledData.map((call) => [
          call.LeadOwnerName,
          call.Company,
          moment(call.CallDate).format("YYYY-MM-DD"),
          call.CallsTime,
          call.CallRemarks,
        ])
      );
    }, []);
    const callCanceledDataWs = XLSX.utils.aoa_to_sheet([
      [
        "LeadOwnerName",
        "Company",
        "Call Date [YYYY-MM-DD]",
        "Call Time   ",
        "Call Remarks",
      ],
      ...callCanceledDataArray,
    ]);
    XLSX.utils.book_append_sheet(wb, callCanceledDataWs, "Call Data-Cancelled");

    // Modify workbook as needed

    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `${fileName}_${currentDate}_${currentTime}${fileExtension}`;

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: fileType });

    saveAs(dataBlob, finalFileName);
  };

  const handleGeneratePDFClick = async () => {
    try {
      // Fetch data from API
      const response = await Axios.post(
        `/report/get_dsr_all_download?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        { sendData: transformedArrayState },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      console.log("Response from API:", response);

      // Check if response data is empty
      if (!response.data) {
        console.error("Error generating PDF: Response data is empty");
        return;
      }

      // Call createPDF function with the fetched data
      createPDF(response.data.data, "DSRAllData", UserName);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const createPDF = (data, fileName, UserName) => {
    const doc = new jsPDF();
    let startY = 10;
    doc.setFontSize(9);
    doc.text(`Downloaded By: ${UserName}`, 10, startY);
    startY += 10;

    if (transformedArrayState.length === 0) {
      const currentDate = new Date();
      const currentDay = currentDate.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
      const fromMonday = new Date(currentDate); // Clone the current date
      fromMonday.setDate(
        currentDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1)
      ); // Calculate Monday of the current week
      const toDate = currentDate.toISOString().split("T")[0];
      const fromDate = fromMonday.toISOString().split("T")[0];

      const content = `From Date        : ${fromDate}\nTo Date            : ${toDate}\n\n`;
      doc.text(content, 10, startY);
      startY += doc.getTextDimensions(content).h + 10; // Update startY for the next content
    } else {
      // Add fromDate and toDate content
      transformedArrayState.forEach((item, index) => {
        const content = `From Date        : ${item.fromDate}\nTo Date            : ${item.toDate}\n\n`;
        doc.text(content, 10, startY);
        startY += doc.getTextDimensions(content).h + 10; // Update startY for the next content
      });
    }

    // Function to add a table to the PDF with a heading
    const addTableWithHeading = (columns, rows, heading) => {
      doc.text(heading, 10, startY + 5); // Add heading
      startY += 5; // Increase startY for the next element
      doc.autoTable({
        head: [columns],
        body: rows,
        startY: startY + 5, // Start table below the heading
        styles: {
          fontSize: 8, // Font size for the table content
        },
        headStyles: {
          fontSize: 8, // Font size for the table heading
          fontStyle: "bold", // Make the table heading bold
        },
      });
      startY = doc.autoTable.previous.finalY; // Update startY for the next element
    };

    // Add DSR Data table
    const dsrColumns = [
      "LeadOwnerName",
      "Id",
      "Leads",
      "Prospects",
      "Meetings",
      "Calls",
      "Conducted Meeting",
      "Cancelled Meeting",
      "Conducted Calls",
      "Cancelled Calls",
    ];
    const dsrRows = data.map((item) => [
      item.LeadOwnerName,
      item.Id,
      item.Leads,
      item.Prospects,
      item.Meetings,
      item.Calls,
      item.ConductedMeetings,
      item.CancelledMeetings,
      item.ConductedCalls,
      item.CancelledCalls,
    ]);
    addTableWithHeading(dsrColumns, dsrRows, "DSR Data");

    // Add Lead Data table
    const leadColumns = [
      "LeadOwnerName",
      "Lead Number",
      "Company",
      "Email",
      "Contact Person",
      "City",
      "Remarks",
      "Created At [YYYY-MM-DD]",
    ];
    const leadRows = data.flatMap((item) =>
      item.LeadData.map((lead) => [
        lead.LeadOwnerName,
        lead.LeadNumber,
        lead.Company,
        lead.Email,
        lead.ContactPerson,
        lead.City,
        lead.Remarks,
        moment(lead.CreatedAt).format("YYYY-MM-DD"),
      ])
    );
    addTableWithHeading(leadColumns, leadRows, "Lead Data");

    // Add Prospect Data table
    const prospectColumns = [
      "ProspectOwnerName",
      "Prospect Number",
      "Company",
      "Email",
      "Contact Person",
      "City",
      "Remarks",
      "Created At [YYYY-MM-DD]",
    ];
    const prospectRows = data.flatMap((item) =>
      item.ProspectData.map((prospect) => [
        prospect.ProspectOwnerName,
        prospect.ProspectNumber,
        prospect.Company,
        prospect.Email,
        prospect.ContactPerson,
        prospect.City,
        prospect.Remarks,
        moment(prospect.CreatedAt).format("YYYY-MM-DD"),
      ])
    );
    addTableWithHeading(prospectColumns, prospectRows, "Prospect Data");

    // Add Meeting Data table
    const meetingColumns = [
      "LeadOwnerName",
      "Company",
      "Meeting Date [YYYY-MM-DD]",
      "Meeting Time",
      "Meeting Remarks",
    ];
    const meetingRows = data.flatMap((item) =>
      item.MeetingData.map((meeting) => [
        meeting.LeadOwnerName,
        meeting.Company,
        moment(meeting.MeetingDate).format("YYYY-MM-DD"),
        meeting.MeetingTime,
        meeting.MeetingRemarks,
      ])
    );
    addTableWithHeading(meetingColumns, meetingRows, "Meetings Data");

    // Add Call Data table
    const callColumns = [
      "LeadOwnerName",
      "Company",
      "Call Date [YYYY-MM-DD]",
      "Call Time",
      "Call Remarks",
    ];
    const callRows = data.flatMap((item) =>
      item.CallData.map((call) => [
        call.LeadOwnerName,
        call.Company,
        moment(call.CallDate).format("YYYY-MM-DD"),
        call.CallsTime,
        call.CallRemarks,
      ])
    );
    addTableWithHeading(callColumns, callRows, "Calls Data");

    // Add Meeting Data table-Conducted
    const meetingConductedColumns = [
      "LeadOwnerName",
      "Company",
      "Meeting Date [YYYY-MM-DD]",
      "Meeting Time",
      "Meeting Remarks",
    ];
    const meetingConductedRows = data.flatMap((item) =>
      item.meetingConductedData.map((meeting) => [
        meeting.LeadOwnerName,
        meeting.Company,
        moment(meeting.MeetingDate).format("YYYY-MM-DD"),
        meeting.MeetingTime,
        meeting.MeetingRemarks,
      ])
    );
    addTableWithHeading(
      meetingConductedColumns,
      meetingConductedRows,
      "Meetings Data-Conducted"
    );

    // Add Meeting Data table-Canceled
    const meetingCanceledColumns = [
      "LeadOwnerName",
      "Company",
      "Meeting Date [YYYY-MM-DD]",
      "Meeting Time",
      "Meeting Remarks",
    ];
    const meetingCanceledRows = data.flatMap((item) =>
      item.meetingCanceledData.map((meeting) => [
        meeting.LeadOwnerName,
        meeting.Company,
        moment(meeting.MeetingDate).format("YYYY-MM-DD"),
        meeting.MeetingTime,
        meeting.MeetingRemarks,
      ])
    );
    addTableWithHeading(
      meetingCanceledColumns,
      meetingCanceledRows,
      "Meetings Data-Cancelled"
    );

    // Add Call Data table-conducted
    const callConductedColumns = [
      "LeadOwnerName",
      "Company",
      "Call Date [YYYY-MM-DD]",
      "Call Time",
      "Call Remarks",
    ];
    const callConductedRows = data.flatMap((item) =>
      item.callConductedData.map((call) => [
        call.LeadOwnerName,
        call.Company,
        moment(call.CallDate).format("YYYY-MM-DD"),
        call.CallsTime,
        call.CallRemarks,
      ])
    );
    addTableWithHeading(
      callConductedColumns,
      callConductedRows,
      "Calls Data-Conducted"
    );

    // Add Call Data table-Canceled
    const callCanceledColumns = [
      "LeadOwnerName",
      "Company",
      "Call Date [YYYY-MM-DD]",
      "Call Time",
      "Call Remarks",
    ];
    const callCanceledRows = data.flatMap((item) =>
      item.callCanceledData.map((call) => [
        call.LeadOwnerName,
        call.Company,
        moment(call.CallDate).format("YYYY-MM-DD"),
        call.CallsTime,
        call.CallRemarks,
      ])
    );
    addTableWithHeading(
      callCanceledColumns,
      callCanceledRows,
      "Calls Data-Cancelled"
    );

    // Save the PDF
    const currentDate = new Date().toISOString().split("T")[0];
    const currentTime = new Date().toLocaleTimeString().replace(/:/g, "-");
    const finalFileName = `${fileName}_${currentDate}_${currentTime}.pdf`;

    doc.save(finalFileName);
  };

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    setHandleCheckboxFilter(value);
  };

  const handleCheckboxColumn = (event, item) => {
    settableColumnsReport((prevData) => {
      const newData = prevData.map((dataItem) =>
        dataItem.key === item.key
          ? { ...dataItem, isVisible: !dataItem.isVisible }
          : dataItem
      );
      return newData;
    });

    const newData = tableColumnsReport.map((dataItem) =>
      dataItem.key === item.key
        ? { ...dataItem, isVisible: !dataItem.isVisible }
        : dataItem
    );

    localStorage.setItem("tableColumnsReport", JSON.stringify(newData));
  };
  const handleCheckbox = (id) => {
    if (Array.isArray(id)) {
      setCheckBoxSelected(id);
    } else {
      setCheckBoxSelected((prev) => {
        if (Array.isArray(prev)) {
          if (prev.includes(id)) {
            return prev.filter((item) => item !== id);
          } else {
            return [...prev, id];
          }
        } else {
          return [id];
        }
      });
    }
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      const { data } = await Axios.get(
        `/report/get_dsrreport?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.data?.length === 0) {
        setReportData([]);
        return setColumns2([]);
      }
      const array1 = Object.keys(columns).filter((key) => columns[key]);

      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible: true,
        };
      });
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );

      setColumns2(filteredDataHeader);

      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};

        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });

        return filteredItem;
      });
      setIntialvalue(filteredData);
      console.log("IG", Intialvalue);
      setFilterData(filteredData);
      setReportData(filteredData);
      setExportData(data.data);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (window.localStorage.getItem("reportfilter")?.length === 0) {
      return setReportData([]);
    }

    if (window.localStorage.getItem("reportfilter")) {
      getFilterData();
    } else {
      getData();
    }
  }, []);

  // Filter Data
  const [filterRows, setFilterRows] = useState(() => {
    return JSON.parse(localStorage.getItem("reportfilterRows"))
      ? JSON.parse(localStorage.getItem("reportfilterRows"))
      : [0];
  });
  const [filterConditions, setFilterConditions] = useState(() => {
    const savedConditions = JSON.parse(
      localStorage.getItem("reportfilterConditions")
    );
    return savedConditions || {};
  });

  const [inputValues, setInputValues] = useState({ ...filterConditions });
  const [selectValues, setSelectValues] = useState({ ...filterConditions });
  const addFilterRow = () => {
    setFilterRows((prevFilterRows) => [
      ...prevFilterRows,
      prevFilterRows?.length,
    ]);
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [filterRows?.length]: {
        condition: "",
        value: "",
      },
    }));
  };

  useEffect(() => {
    localStorage.setItem("reportfilterRows", JSON.stringify(filterRows));
    localStorage.setItem(
      "reportfilterConditions",
      JSON.stringify(filterConditions)
    );
  }, [filterRows, filterConditions]);

  const removeFilterRow = (index) => {
    const newFilterRows = filterRows.filter((rowIndex) => rowIndex !== index);
    setFilterRows(newFilterRows);

    setFilterConditions((prevConditions) => {
      const { [index]: removedCondition, ...restConditions } = prevConditions;
      return restConditions;
    });
  };

  const handleSubmitFilter = async () => {
    localStorage.setItem(
      "reportfilterConditions",
      JSON.stringify(filterConditions)
    );
    const transformedarray = [];
    filterRows.forEach((index) => {
      const fromDate =
        fromDisplay || document.getElementById(`fromDate-${index}`).value;
      const toDate =
        toDisplay || document.getElementById(`value-input-${index}`).value;

      if (fromDate || toDate) {
        let transformedConditions = {};

        if (fromDate && toDate) {
          transformedConditions = {
            fromDate: fromDate,
            toDate: toDate,
          };
        } else if (fromDate && !toDate) {
          // If only fromDate is provided, calculate toDate as fromDate + 1 day
          const nextDay = fromDate;
          transformedConditions = {
            fromDate: fromDate,
            toDate: nextDay,
          };
        } else if (!fromDate && toDate) {
          // If only toDate is provided, calculate fromDate as toDate - 1 day
          const previousDay = toDate;
          transformedConditions = {
            fromDate: previousDay,
            toDate: toDate,
          };
        }

        transformedarray.push(transformedConditions);
        setTransformedArrayState(transformedarray);
      }
    });

    console.log("transformed array:", transformedArrayState);
    try {
      const { data } = await Axios.post(
        `/report/massfilter_dsr?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        { sendData: transformedarray },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data?.data?.length === 0) {
        setFilterOpen(false);
        localStorage.setItem("reportfilter", JSON.stringify([]));
        return setReportData([]);
      }

      const array1 = Object.keys(columns).filter((key) => columns[key]);

      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible: true,
        };
      });
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );

      setColumns2(filteredDataHeader);

      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};

        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });
        return filteredItem;
      });
      setFilterData(filteredData);
      setReportData(filteredData);
      setExportData(data.data);
      localStorage.setItem("reportfilter", JSON.stringify(filteredData));
      setFilterOpen(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: error?.response?.data?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else if (error?.response?.statusText) {
        toast({
          title: error?.response?.statusText,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      } else {
        toast({
          title: error?.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    }
  };

  const handleFilterConditionSelect = (event, index) => {
    const selectedValue = event.target.value;
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        condition: selectedValue,
      },
    }));
  };
  const handleFilterValueChange = (event, index) => {
    const selectedValue = event.target.value;
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        value: selectedValue,
      },
    }));
  };
  const handleFromDateChange = (event, index) => {
    const selectedFromDate = new Date(event.target.value);
    const selectedToDate = new Date(filterConditions[index]?.toDate);

    // Calculate one year before the selected toDate
    const oneYearBeforeToDate = new Date(selectedToDate);
    oneYearBeforeToDate.setFullYear(selectedToDate.getFullYear() - 1);

    // Check if the selected fromDate is not within one year before the toDate
    if (selectedFromDate < oneYearBeforeToDate) {
      const toDateStr = `${selectedToDate.getFullYear()}-${
        selectedToDate.getMonth() + 1
      }-${selectedToDate.getDate()}`;
      toast({
        title: `Please select a date within one year before the To Date (${toDateStr}).`,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return; // Prevent setting the fromDate if it's not within one year before the toDate
    }

    // Update the fromDate in the filterConditions state
    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        fromDate: event.target.value,
      },
    }));
  };
  const handleToDateChange = (event, index) => {
    const selectedValue = event.target.value;
    const selectedToDate = new Date(selectedValue);
    const selectedFromDate =
      new Date(filterConditions[index]?.fromDate) || new Date();
    const oneYearLater = new Date(selectedFromDate);
    oneYearLater.setFullYear(selectedFromDate.getFullYear() + 1);

    if (
      selectedToDate > oneYearLater ||
      selectedToDate.getTime() < selectedFromDate.getTime()
    ) {
      const fromDateStr = `${selectedFromDate.getFullYear()}-${
        selectedFromDate.getMonth() + 1
      }-${selectedFromDate.getDate()}`;
      const toDateStr = `${selectedToDate.getFullYear()}-${
        selectedToDate.getMonth() + 1
      }-${selectedToDate.getDate()}`;
      toast({
        title: `Please select a date within one year from the From Date(${fromDateStr}).`,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      return; // Prevent setting the toDate if it's beyond one year from the fromDate or the same as the fromDate
    }

    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        toDate: selectedValue,
      },
    }));
  };

  const getFilterData = async (sendData) => {
    try {
      const data = {
        data: JSON.parse(window.localStorage.getItem("reportfilter")),
      };

      if (data?.data?.length === 0) {
        setFilterOpen(false);
        return setReportData([]);
      }

      const array1 = Object.keys(columns).filter((key) => columns[key]);

      const header = Object.keys(data?.data[0])?.map((item) => {
        return {
          key: item,
          label: item.charAt(0).toUpperCase() + item.slice(1),
          isVisible: true,
        };
      });

      // // Filter the data based on the keys in filterKeys array
      const filteredDataHeader = header.filter((obj) =>
        array1.includes(obj.key)
      );

      setColumns2(filteredDataHeader);

      const filteredData = data?.data?.map((item) => {
        const filteredItem = {};

        Object.keys(item).forEach((key) => {
          if (array1.includes(key)) {
            filteredItem[key] = item[key];
          }
        });
        return filteredItem;
      });

      setReportData(filteredData);
      setExportData(data.data);
      setFilterOpen(false);
    } catch (error) {
      //console.log(error.message);
    }
  };

  const handleClearFilters = () => {
    const inputs = document.querySelectorAll(".value-input");
    const newInputValues = { ...inputValues };
    inputs.forEach((input) => {
      const index = inputValues;
      newInputValues[index] = input.value;
      input.value = "";
    });

    const selects = document.querySelectorAll(".column-select, .filter-type");
    selects.forEach((select) => {
      select.value = "select";
    });

    setFilterConditions({});
    setFilterOpen(false);
    setFilterRows([0]);
    getData();
    setleadData();
    setprospectData();
    setFilterData(Intialvalue);
    console.log("Fil:", FilterData);
    setmeetData();
    setcallData();
    setconductedmeetData();
    setconductedcallData();
    setcanceledcallData();
    setcanceledmeetData();
    setSelectedDurationFilter("Custom");
    setFromDisplay("");
    setToDisplay("");
    if (transformedArrayState.length > 0) {
      setTransformedArrayState([]);
    }
    console.log(transformedArrayState);
    setInputValues(newInputValues);
    localStorage.removeItem("reportfilterConditions");
    window.localStorage.removeItem("reportfilter");
    window.localStorage.removeItem("reportfilterRows");
    // localStorage.setItem("filterInputValues", JSON.stringify(newInputValues));
  };

  useEffect(() => {
    const array1 = columns && Object?.keys(columns);
    const array2 = reportData[0] && Object?.keys(reportData[0]);

    const savedInputValues = localStorage.getItem("filterInputValues");
    if (savedInputValues) {
      setInputValues(JSON.parse(savedInputValues));
    }

    if (window.localStorage.getItem("reportfilter")?.length === 0) {
      return setReportData([]);
    }

    if (window.localStorage.getItem("reportfilter")) {
      getFilterData();
    } else {
      getData();
      getFilterData();
    }
  }, [columns]);

  // Mass Update Input Type

  const handleOptionChange = (newValue) => {
    setSelectedOption(newValue);
  };

  const [initialReportData, setInitialReportData] = useState([]);
  //Debounced search function
  const handleSearch = debounce((queryData) => {
    if (queryData.trim() === "") {
      // If the query is empty, display the initial report data
      console.log("I", initialReportData);
      setReportData(initialReportData);
    } else {
      console.log("P", initialReportData);
      // Filter reportData based on the search term
      const filteredData = initialReportData.filter((item) => {
        // Convert each object value to string and check if it includes the search term
        return Object.values(item).some((value) =>
          String(value).toLowerCase().includes(queryData.toLowerCase())
        );
      });
      // Update reportData with filtered data
      setReportData(filteredData);
    }
  }, 100);

  // Handler for search input change
  const handleSearchInputChange = (event) => {
    const queryData = event.target.value;
    console.log(FilterData);
    // Save the initial reportData if it's not already saved
    if (initialReportData.length === 0) {
      setInitialReportData(FilterData);
    }

    // Trigger search immediately if queryData is not empty, debounce otherwise
    if (queryData.trim() === "") {
      setInitialReportData(FilterData);
      setReportData(initialReportData); // Show initial data
    } else {
      setInitialReportData(FilterData);
      console.log("intial", initialReportData);
      handleSearch.cancel();
      handleSearch(queryData);
    }
  };

  // const handleSearch = async (event) => {
  //     const queryData = event.target.value;

  //     const dataSet = {
  //         userId: JSON.parse(localStorage.getItem("user")).userId,
  //     };

  //     const { data } = await Axios.get(
  //         `/report/search_dsr?userId=${JSON.parse(localStorage.getItem("user")).userId
  //         }&query=${queryData}`,
  //         {
  //             headers: {
  //                 "Content-Type": "application/json",
  //                 Authorization: JSON.parse(localStorage.getItem("token")),
  //             },
  //         }
  //     );

  //     if (data?.data?.length === 0) {
  //         return setReportData([]);
  //     }

  //     const array1 = Object.keys(columns).filter((key) => columns[key]);

  //     const header = Object.keys(data?.data[0])?.map((item) => {
  //         return {
  //             key: item,
  //             label: item.charAt(0).toUpperCase() + item.slice(1),
  //             isVisible: true,
  //         };
  //     });
  //     const filteredDataHeader = header.filter((obj) => array1.includes(obj.key));

  //     setColumns2(filteredDataHeader);

  //     const filteredData = data?.data?.map((item) => {
  //         const filteredItem = {};

  //         Object.keys(item).forEach((key) => {
  //             if (array1.includes(key)) {
  //                 filteredItem[key] = item[key];
  //             }
  //         });

  //         return filteredItem;
  //     });

  //     setReportData(filteredData);
  // };
  const [selectedDurationFilter, setSelectedDurationFilter] =
    useState("Custom");
  const [fromDisplay, setFromDisplay] = useState();
  const [toDisplay, setToDisplay] = useState();

  const handleItemClick = (dateRange) => {
    const today = moment();
    let fromDisplay = "";
    let toDisplay = "";

    switch (dateRange) {
      case "Daily":
        fromDisplay = today.clone().subtract(1, "days").format("YYYY-MM-DD");
        toDisplay = today.clone().subtract(1, "days").format("YYYY-MM-DD");
        break;
      case "Weekly":
        fromDisplay = today
          .clone()
          .startOf("isoWeek")
          .subtract(1, "week")
          .format("YYYY-MM-DD");
        toDisplay = today
          .clone()
          .endOf("isoWeek")
          .subtract(1, "week")
          .format("YYYY-MM-DD");
        break;
      case "Monthly":
        fromDisplay = today
          .clone()
          .startOf("month")
          .subtract(1, "month")
          .format("YYYY-MM-DD");
        toDisplay = today
          .clone()
          .endOf("month")
          .subtract(1, "month")
          .format("YYYY-MM-DD");
        break;
      case "Quaterly":
        fromDisplay = today
          .clone()
          .subtract(3, "months")
          .startOf("quarter")
          .format("YYYY-MM-DD");
        toDisplay = today
          .clone()
          .subtract(3, "months")
          .endOf("quarter")
          .format("YYYY-MM-DD");
        break;
      case "Half Yearly":
        const currentMonth = today.month();
        if (currentMonth < 6) {
          // First half-yearly interval (start of third quarter of previous year to end of fourth quarter of previous year)
          fromDisplay = today
            .clone()
            .subtract(6, "months")
            .startOf("year")
            .startOf("quarter")
            .add(2, "quarters")
            .format("YYYY-MM-DD");
          toDisplay = today
            .clone()
            .subtract(6, "months")
            .startOf("year")
            .endOf("year")
            .format("YYYY-MM-DD");
        } else {
          // Second half-yearly interval (start of first quarter of current year to end of second quarter of current year)
          fromDisplay = today
            .clone()
            .startOf("year")
            .startOf("quarter")
            .format("YYYY-MM-DD");
          toDisplay = today
            .clone()
            .startOf("year")
            .startOf("quarter")
            .add(1, "quarters")
            .endOf("quarter")
            .format("YYYY-MM-DD");
        }
        break;

      case "Annual":
        fromDisplay = today
          .clone()
          .startOf("year")
          .subtract(1, "year")
          .format("YYYY-MM-DD");
        toDisplay = today
          .clone()
          .endOf("year")
          .subtract(1, "year")
          .format("YYYY-MM-DD");
        break;
      case "Custom":
        // When "Custom" is clicked, set fromDisplay and toDisplay as empty strings
        fromDisplay = "";
        toDisplay = "";
        break;
      default:
        break;
    }

    setSelectedDurationFilter(dateRange);
    setFromDisplay(fromDisplay);
    setToDisplay(toDisplay);
  };

  // Pagination

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const availableItemsPerPage = [5, 10, 15, 20, 50, 80, 100];

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < pageNumbers ? prevPage + 1 : prevPage
    );
  };

  const pageNumbers = Math.ceil(reportData?.length / itemsPerPage);

  const renderPageNumbers = () => {
    const pagesToShow = 5; // Adjust this number to control how many page numbers are displayed at once.

    const firstPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const lastPage = Math.min(pageNumbers, firstPage + pagesToShow - 1);
    return (
      <>
        {Array.from(
          { length: lastPage - firstPage + 1 },
          (_, i) => firstPage + i
        ).map((number) => (
          <li
            key={number}
            onClick={() => setCurrentPage(number)}
            data-selected={currentPage === number}
            className="py-1 px-2 block data-[selected=true]:font-bold border-gray-200 dark:border-gray-700 dark:text-white cursor-pointer"
          >
            {number}
          </li>
        ))}
      </>
    );
  };

  // filter Part Combinationget

  const handleFilterTypeSelect = (event, index) => {
    const selectedValue = event.target.value;

    setFilterConditions((prevConditions) => ({
      ...prevConditions,
      [index]: {
        ...prevConditions[index],
        column: selectedValue,
      },
    }));
  };

  useEffect(() => {
    if (localStorage.getItem("tableColumnsReport")) {
      settableColumnsReport(
        JSON.parse(localStorage.getItem("tableColumnsReport"))
      );
    } else {
      settableColumnsReport(columns2);
    }
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [columns2]);

  //console.log(columns2);

  const handleDragStart = (event, index) => {
    event.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, dropIndex) => {
    event.preventDefault();

    const dragIndex = parseInt(event.dataTransfer.getData("text/plain"));
    const updatedColumns = [...tableColumnsReport];

    const [draggedColumn] = updatedColumns.splice(dragIndex, 1);
    updatedColumns.splice(dropIndex, 0, draggedColumn);
    localStorage.setItem("tableColumnsReport", JSON.stringify(updatedColumns));
    settableColumnsReport(updatedColumns);
  };
  const style = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflow: "hidden",
  };

  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "0%",
    zIndex: "200",
  };
  const backdropStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Dull black color with opacity
    zIndex: "199", // Lower zIndex than the modal to ensure the modal stays on top
  };
  const modalStyleUpdate = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "200",
  };

  const closeModal = {
    position: "absolute",
    top: "3%",
    right: "5%",
    cursor: "pointer",
  };
  //,fromDate,toDate
  const handleIconClick = async (
    id,
    LeadIds,
    ProspectIds,
    MeetingIds,
    CallIds,
    ConductedMeetingIds,
    CanceledMeetingIds,
    ConductedCallIds,
    CanceledCallIds
  ) => {
    try {
      // Make a GET request to the API endpoint with the userId parameter
      const response = await Axios.post(
        `report/get_dsrallreport?userId=${id}`,
        {
          LeadIds,
          ProspectIds,
          MeetingIds,
          CallIds,
          ConductedMeetingIds,
          CanceledMeetingIds,
          ConductedCallIds,
          CanceledCallIds,
        }
      ); //
      // Extract the data from the response
      console.log(fromDate, toDate);
      setleadData(response.data.data.leads);
      setprospectData(response.data.data.prospects);
      setmeetData(response.data.data.meetings);
      setcallData(response.data.data.calls);
      setconductedmeetData(response.data.data.conductedmeetings);
      setcanceledmeetData(response.data.data.canceledmeetings);
      setconductedcallData(response.data.data.conductedcalls);
      setcanceledcallData(response.data.data.canceledcalls);
      console.log(leadData);
      console.log(prospectData);
      console.log(meetData);
      console.log(callData);
      console.log(conductedmeetData);
      console.log(canceledmeetData);
      console.log(conductedcallData);
      console.log(canceledcallData);
    } catch (error) {
      // Handle errors if any
      console.error("Error fetching data:", error);
    }
  };
  const handlePrint = () => {
    window.print();
  };
  return (
    <>
      {/* <Header /> */}
      <div className="mx-auto  bg-blue-950 text-white flex flex-wrap dark:bg-indigo-900">
        <FaFilter
          size={25}
          onClick={isOpenFilter ? onCloseFilter : onOpenFilter}
          style={{
            cursor: "pointer",
            marginTop: "15",
            marginLeft: "50",
            marginBottom: "10",
          }}
        />
        &nbsp;&nbsp;
        <>
          <div className="pt-2 ml-10 relative mx-auto text-gray-600">
            <input
              className="border-2  border-gray-300 bg-white h-8 px-5 pr-10 rounded-lg text-sm focus:outline-none"
              ref={searchRef}
              type="search"
              name="search"
              placeholder="Search"
              onChange={handleSearchInputChange}
            />
          </div>
        </>
        {/* &nbsp;&nbsp; */}
        <TbAdjustmentsHorizontal
          onClick={
            isOpenManagecolumn ? onCloseManagecolumn : onOpenManagecolumn
          }
          size={25}
          style={{ cursor: "pointer", marginTop: "15", marginRight: "30" }}
        />
        {/* &nbsp;&nbsp; */}
        <>
          {isOpenFilter && (
            <>
              <div style={backdropStyle}></div>
              <div className={`FilterModal ${scrollBehavior}`}>
                <Rnd
                  style={style}
                  default={{
                    x: 0,
                    y: 0,
                    width: 700,
                    // height: 550,
                  }}
                  cancel="X"
                  allowAnyClick={true}
                >
                  <br />
                  <Flex justifyContent="space-between">
                    <Box flex="1" mr={4}>
                      <div
                        className="closemodule"
                        onClick={() => {
                          onCloseFilter(false);
                        }}
                        style={closeModal}
                      >
                        <CgClose />
                      </div>

                      <Card>
                        <CardHeader>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <header
                              style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                marginBottom: "25px",
                                alignSelf: "flex-start",
                              }}
                            >
                              Duration Filter
                            </header>
                          </div>
                        </CardHeader>
                        <CardBody>
                          <Select
                            value={selectedDurationFilter || "Custom"}
                            onChange={(e) => handleItemClick(e.target.value)}
                            colorScheme={
                              selectedDurationFilter === "Custom"
                                ? "blue"
                                : "gray"
                            }
                          >
                            <option value="Daily">Daily</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Monthly">Monthly</option>
                            <option value="Quaterly">Quarterly</option>
                            <option value="Half Yearly">Half Yearly</option>
                            <option value="Annual">Annual</option>
                            <option value="Custom">Custom</option>
                          </Select>
                        </CardBody>
                      </Card>
                    </Box>
                    <br />
                    <Box flex="1">
                      {selectedDurationFilter && (
                        <Card>
                          <CardBody>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <header
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  marginBottom: "10px",
                                  alignSelf: "flex-start",
                                }}
                              >
                                Date Range
                              </header>
                            </div>
                            <br />
                            <Flex flexDirection="row">
                              <FormLabel ml={5} mr={40}>
                                From Date
                              </FormLabel>
                              <FormLabel>To Date</FormLabel>
                            </Flex>
                            {selectedDurationFilter &&
                              selectedDurationFilter !== "Custom" && (
                                <FormControl>
                                  {filterRows.map((index) => (
                                    <Flex key={index}>
                                      <Input
                                        type="date"
                                        id={`fromDate-${index}`}
                                        name="fromDate"
                                        value={fromDisplay}
                                        onChange={(e) =>
                                          handleFromDateChange(e, index)
                                        }
                                        disabled
                                      />
                                      &nbsp;&nbsp;
                                      <Input
                                        type="date"
                                        name="toDate"
                                        id={`value-input-${index}`}
                                        value={toDisplay}
                                        onChange={(e) =>
                                          handleToDateChange(e, index)
                                        }
                                        min={fromDisplay}
                                        max={
                                          filterConditions[index]
                                            ?.fromDate instanceof Date
                                            ? new Date(
                                                filterConditions[
                                                  index
                                                ].fromDate.getTime() +
                                                  365 * 24 * 60 * 60 * 1000
                                              )
                                                .toISOString()
                                                .split("T")[0]
                                            : ""
                                        }
                                        disabled
                                      />
                                      &nbsp;&nbsp;{" "}
                                    </Flex>
                                  ))}
                                </FormControl>
                              )}

                            {selectedDurationFilter === "Custom" && (
                              <FormControl>
                                {filterRows.map((index) => (
                                  <Flex key={index}>
                                    <Input
                                      type="date"
                                      id={`fromDate-${index}`}
                                      name="fromDate"
                                      value={
                                        filterConditions[index]?.fromDate ||
                                        "" ||
                                        fromDisplay
                                      }
                                      placeholder="dd-mm-yyyy"
                                      onChange={(e) =>
                                        handleFromDateChange(e, index)
                                      }
                                    />
                                    &nbsp;&nbsp;
                                    <Input
                                      type="date"
                                      name="toDate"
                                      id={`value-input-${index}`}
                                      value={
                                        filterConditions[index]?.toDate ||
                                        "" ||
                                        toDisplay
                                      }
                                      placeholder="dd-mm-yyyy"
                                      onChange={(e) =>
                                        handleToDateChange(e, index)
                                      }
                                      min={
                                        filterConditions[index]?.fromDate || ""
                                      }
                                      max={
                                        filterConditions[index]
                                          ?.fromDate instanceof Date
                                          ? new Date(
                                              filterConditions[
                                                index
                                              ].fromDate.getTime() +
                                                365 * 24 * 60 * 60 * 1000
                                            )
                                              .toISOString()
                                              .split("T")[0]
                                          : ""
                                      }
                                    />
                                    &nbsp;&nbsp;{" "}
                                  </Flex>
                                ))}
                              </FormControl>
                            )}
                          </CardBody>
                        </Card>
                      )}
                    </Box>
                  </Flex>
                  <br />
                  <div style={{ float: "right", display: "block" }}>
                    <Button
                      colorScheme="green"
                      onClick={() => {
                        handleSubmitFilter();
                        onCloseFilter();
                      }}
                      mr={3}
                    >
                      Apply
                    </Button>
                    <Button
                      colorScheme="red"
                      onClick={() => {
                        handleClearFilters();
                        onCloseFilter();
                      }}
                    >
                      Clear
                    </Button>
                  </div>
                </Rnd>
              </div>
            </>
          )}
        </>
        <>
          {isOpenDownload && (
            <div className={`DownloadExcelModule ${scrollBehavior}`}>
              <Rnd
                style={style}
                default={{
                  x: 0,
                  y: 0,
                  width: 500,
                  // height: 200
                }}
                cancel="X"
                allowAnyClick={true}
              >
                <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Do you wanna to export data?
                </header>
                <div
                  onClick={() => {
                    onCloseDownload(false);
                  }}
                  style={closeModal}
                >
                  <CgClose />
                </div>
                <br />
                <FormControl>
                  <Flex alignItems="center ">
                    {" "}
                    {/* Use alignItems to vertically center the contents */}
                    <FormLabel marginLeft="100">Select</FormLabel>{" "}
                    {/* Push FormLabel to the left */}
                    <Select
                      w={60}
                      mt={-1}
                      placeholder="Select option"
                      size="md"
                      ref={initialRef}
                      value={selectedExportOption}
                      onChange={handleExportOptionChange}
                    >
                      <option value="Excel">Excel</option>
                      {/* <option value="CSV">CSV</option> */}
                      <option value="PDF">PDF</option>
                      {/* <option value="Text">Text</option> */}
                    </Select>
                  </Flex>
                </FormControl>
                <br />
                <Button
                  colorScheme="green"
                  onClick={() => {
                    handleExport();
                  }}
                  disabled={isDownloading}
                  mr={3}
                >
                  {isDownloading ? "Downloading..." : "Export Data"}
                </Button>
                {downloadComplete && (
                  <Text mt={2} color="green">
                    Download completed!
                  </Text>
                )}
              </Rnd>
            </div>
          )}
        </>
        <>
          {isOpenDownloadAllReport && (
            <div className={`DownloadExcelModule ${scrollBehavior}`}>
              <Rnd
                style={style}
                default={{
                  x: 0,
                  y: 0,
                  width: 500,
                  // height: 200
                }}
                cancel="X"
                allowAnyClick={true}
              >
                <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Do you wanna to export all data?
                </header>
                <div
                  onClick={() => {
                    onCloseDownloadAllReport(false);
                  }}
                  style={closeModal}
                >
                  <CgClose />
                </div>
                <br />
                <FormControl>
                  <Flex alignItems="center ">
                    {" "}
                    {/* Use alignItems to vertically center the contents */}
                    <FormLabel marginLeft="100">Select</FormLabel>{" "}
                    {/* Push FormLabel to the left */}
                    <Select
                      w={60}
                      mt={-1}
                      placeholder="Select option"
                      size="md"
                      ref={initialRef}
                      value={selectedAllReportExportOption}
                      onChange={handleAllReportExportOptionChange}
                    >
                      <option value="Excel">Excel</option>
                      <option value="PDF">PDF</option>
                    </Select>
                  </Flex>
                </FormControl>
                <br />
                <Button
                  colorScheme="green"
                  onClick={() => {
                    handleAllReportExport();
                  }}
                  disabled={isDownloading}
                  mr={3}
                >
                  {isDownloading ? "Downloading..." : "Export Data"}
                </Button>
                {downloadComplete && (
                  <Text mt={2} color="green">
                    Download completed!
                  </Text>
                )}
              </Rnd>
            </div>
          )}
        </>
        <>
          {isOpenManagecolumn && (
            <div style={modalStyleUpdate}>
              <Rnd
                style={style}
                default={{
                  x: 0,
                  y: 0,
                  width: 320,
                  // height: 200
                }}
                cancel="X"
                allowAnyClick={true}
              >
                <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Manage Column
                </header>
                <div
                  onClick={() => {
                    onCloseManagecolumn(false);
                  }}
                  style={closeModal}
                >
                  <CgClose />
                </div>
                <br />
                {tableColumnsReport.map(
                  (label, index) =>
                    label.key !== "id" &&
                    label.key !== "FromDate" &&
                    label.key !== "ToDate" &&
                    label.key !== "DialCode" &&
                    label.key !== "LeadIds" &&
                    label.key !== "MeetingIds" &&
                    label.key !== "ProspectIds" &&
                    label.key !== "CallIds" &&
                    label.key !== "ConductedMeetingIds" &&
                    label.key !== "CanceledMeetingIds" &&
                    label.key !== "ConductedCallIds" &&
                    label.key !== "CanceledCallIds" &&
                    label.key !== "fromDate" &&
                    label.key !== "toDate" && (
                      <label
                        key={index}
                        className="flex items-center"
                        draggable
                        onDragStart={(event) => handleDragStart(event, index)}
                        onDragOver={handleDragOver}
                        onDrop={(event) => handleDrop(event, index)}
                      >
                        <input
                          type="checkbox"
                          // checked={label.label}
                          className="cursor-pointer"
                          disabled={
                            label.key === "LeadOwnerName"
                              ? true
                              : label.key === "Id"
                              ? true
                              : label.key === "Leads"
                              ? true
                              : label.key === "Prospects"
                              ? true
                              : label.key === "Meetings"
                              ? true
                              : label.key === "Calls"
                              ? true
                              : null
                          }
                          checked={label.isVisible}
                          onChange={(event) => {
                            handleCheckboxColumn(event, label);
                          }}
                        />
                        <span className="ml-1" style={{ cursor: "all-scroll" }}>
                          {label.label}
                        </span>
                        {label.isDisabled && (
                          <span style={{ color: "red" }}> *</span>
                        )}
                      </label>
                    )
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                    float: "right",
                  }}
                >
                  <Button
                    onClick={() => {
                      onCloseManagecolumn(false);
                    }}
                    colorScheme="green"
                  >
                    OK
                  </Button>
                  <Button
                    onClick={() => {
                      onCloseManagecolumn(false);
                    }}
                    colorScheme="red"
                    ml={3}
                  >
                    Cancel
                  </Button>
                </div>
              </Rnd>
            </div>
          )}
        </>
      </div>

      <div className="mx-auto  bg-blue-950 text-black flex flex-wrap dark:bg-indigo-900">
        <div className="header-3 md:w-full bg-slate-200 text-black dark:text-white dark:bg-slate-800">
          {/* Next page button */}
          <div
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-200 dark:bg-slate-700 dark:text-white text-black inline-block w-50"
            style={{ float: "right", marginRight: "5px" }}
          >
            <FiArrowRightCircle
              className="cursor-pointer w-6 h-6"
              onClick={goToNextPage}
              disabled={currentPage === pageNumbers}
            />
          </div>

          {/* Previous page button */}
          <div
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-200 text-black dark:bg-slate-700 dark:text-white inline-block w-50"
            style={{ float: "right", marginRight: "10px" }}
          >
            <FiArrowLeftCircle
              className="cursor-pointer w-6 h-6"
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
            />
          </div>

          {/* Page numbers */}
          <p
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700  dark:text-white inline-block  w-50"
            style={{ float: "right", marginRight: "20px" }}
          >
            <a className="relative inline-flex items-center ">
              {renderPageNumbers()}
            </a>
          </p>

          {/* Items per page dropdown */}
          <select
            className="py-1 px-2 my-2 block border-gray-200 rounded-full text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 inline-block  w-50"
            style={{
              float: "right",
              marginRight: "20px",
              cursor: "pointer",
            }}
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
          >
            {availableItemsPerPage.map((option) => (
              <option key={option} value={option}>
                {option} Records Per Page
              </option>
            ))}
          </select>
          {/* Icons */}
          <Tooltip hasArrow label="Download" bg="gray.300" color="black">
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm  dark:text-white text-black inline-block w-50 delete">
              <MdDownloading
                size={20}
                onClick={() => {
                  onOpenDownload();
                }}
                style={{ pointerEvents: "none", color: "grey" }}
              />
            </div>
          </Tooltip>
          <Tooltip hasArrow label="Print" bg="gray.300" color="black">
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm  dark:text-white text-black inline-block w-50 delete">
              <MdPrint
                size={20}
                onClick={handlePrint}
                style={{ pointerEvents: "none", color: "grey" }}
              />
            </div>
          </Tooltip>
          <Tooltip
            hasArrow
            label="All Report Export"
            bg="gray.300"
            color="black"
          >
            <div className="py-1 px-2 my-2  border-gray-200 rounded-full text-sm  dark:text-white text-black inline-block w-50 delete">
              <MdDownloadForOffline
                onClick={() => {
                  onOpenDownloadAllReport();
                }}
                size={20}
                style={{ pointerEvents: "none", color: "grey" }}
              />
            </div>
          </Tooltip>

          {/* Record count */}
          <p className="py-1 px-2 my-2" style={{ float: "left" }}>
            <p className="text-sm text-gray-700 dark:text-white">
              Showing{" "}
              <span className="font-medium">
                {currentPage === 1 ? 1 : indexOfFirstItem + 1}
              </span>{" "}
              to{" "}
              <span className="font-medium">
                {Math.min(indexOfLastItem, reportData?.length)}
              </span>{" "}
              of <span className="font-medium">{reportData?.length}</span>{" "}
              records
            </p>
            <p style={{ float: "left", marginTop: "10px" }}>
              <Selectallpages
                selectAllPages={selectAllPages}
                setSelectAllPages={setSelectAllPages}
                checkBoxAll={checkBoxAll}
              />
            </p>
          </p>
        </div>

        <div className="table-container">
          <DraggableReportTable
            // isLoading={isLoading}
            columns={columns2}
            reportData={reportData}
            getData={getData}
            data={reportData.slice(indexOfFirstItem, indexOfLastItem)}
            setReportData={setReportData}
            handleCheckbox={handleCheckbox}
            checkBoxSelected={checkBoxSelected}
            setCheckBoxSelected={setCheckBoxSelected}
            setCheckBoxAll={setCheckBoxAll}
            checkBoxAll={checkBoxAll}
            handleDragOver={handleDragOver}
            handleDragStart={handleDragStart}
            handleDrop={handleDrop}
            tableColumnsReport={tableColumnsReport}
            currentPage={currentPage}
            pageNumbers={pageNumbers}
            handleIconClick={handleIconClick}
            fromDate={fromDate}
            toDate={toDate}
          />
        </div>
      </div>
      {/* <p>
  {transformedArrayState.map((item, index) => (
    <div key={index}>
      <p>From Date: {item.fromDate}</p>
      <p>To Date: {item.toDate}</p>
    </div>
  ))}
</p> */}

      <div className="table-container">
        <ReportTableCollapse
          leadData={leadData}
          prospectData={prospectData}
          meetData={meetData}
          callData={callData}
          conductedmeetData={conductedmeetData}
          canceledmeetData={canceledmeetData}
          conductedcallData={conductedcallData}
          canceledcallData={canceledcallData}
          setleadData={setleadData}
          setprospectData={setprospectData}
          setmeetData={setmeetData}
          setcallData={setcallData}
          setconductedmeetData={setconductedmeetData}
          setcanceledmeetData={setcanceledmeetData}
          setconductedcallData={setconductedcallData}
          setcanceledcallData={setcanceledcallData}
        />
      </div>
    </>
  );
};

export default Report;
