import React, { useState } from "react";
import { Header } from "../../components";
import Sidebar from "./helpers/Sidebar";
import Report from "./Report";
import Home from "./Home";


const IntialReport = () => {
  const [selectedButton, setSelectedButton] = useState("DSR report");
  return (
    <>
     
        <Header />
        <Sidebar
            selectedButton={selectedButton}
            setSelectedButton={setSelectedButton}
            />
        {selectedButton === "" && (
                    <Home 
                    // selectedButton={selectedButton}
                    // setSelectedButton={setSelectedButton}
                    />
                )}


            {selectedButton === "DSR report" && (
              <Report
              // selectedButton={selectedButton}
              // setSelectedButton={setSelectedButton}
               />
            )}
    </>
  );
};

export default IntialReport;
