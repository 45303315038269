import React from "react"; // Import React
import { useState, useEffect } from "react"; // Import necessary hooks
import { Header } from "../../components"; // Import a Header component
import axios from "axios"; // Import Axios for making HTTP requests
import { v4 as uuidv4 } from "uuid"; // Import the uuidv4 function for generating unique IDs
import Axios from "../../api-instance"; // Import Axios with a custom configuration
import { useToast } from "@chakra-ui/react"; // Import useToast from Chakra UI for displaying notifications
import { useNavigate } from "react-router-dom"; // Import useNavigate from React Router for navigation

import "./Addrate.css"; // Import CSS styles

const Addrate = () => {
  const [submitAllowed, setSubmitAllowed] = useState(true);
  const toast = useToast(); // Initialize the useToast hook for notifications
  const navigate = useNavigate(); // Initialize the useNavigate hook for navigation
  const [rateId, setRateId] = useState(uuidv4());


  const [formData, setFormData] = useState({
    // Initialize a state variable with form data
    RateId: rateId,
    RateNumber: "",
    mode: "",
    origin_country: "",
    destination_country: "",
    pickup_location: "",
    origin_port: "",
    destination_port: "",
    delivery_location: "",
    incoterm: "",
    commodity: "",
    hs_code: "",
    dimensions: "",
    weight: "",
    scope: "",
    free_time: "",
    equipment: "",
    stackable: "",
    dg: "",
    packing_type: "",
    attachments: "",
    remarks: "",
    rate: "",
    vendor: ""
  });

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    console.log(formData, "form"); // Log the form data to the console
    const { data } = await Axios.post(
      // Send a POST request using Axios
      `/rates/add_rate?userId=${
        JSON.parse(localStorage.getItem("user")).userId
      }`,
      formData, // Include form data in the request
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")), // Set request headers
        },
      }
    );

    console.log("===================================="); // Log a separator line
    console.log(data); // Log the response data
    console.log("===================================="); // Log another separator line

    if (data.success) {
      // Check if the response indicates success
      toast({
        title: "rate created successfully!", // Show a success toast notification
        position: "top-right",
        isClosable: true,
        status: "success",
        containerstyle: {
          width: "1500px",
          maxwidth: "100%",
          alignContent: "center",
        },
      });
      navigate("/rate"); // Navigate to the "/rate" route
    } else {
      toast({
        title: data.message, // Show an error toast notification
        position: "top-right",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "1500px",
          maxwidth: "100%",
          alignContent: "center",
        },
      });
    }
  };

  // Function to handle input changes
  const handleChange = async (e) => {
    const { name, value } = e.target; // Get the name and value from the input element

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value, // Update the form data with the changed input value
      };
    });
  };

  const Pagerefresh = () => window.location.reload(true);
  const handleSubmitNext = async (event) => {
    try {
      event.preventDefault();

      const { data } = await Axios.post(
        `/rates/add_rate?userId=${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.success) {
        toast({
          title: "Rate created successfully!",
          position: "bottom-right",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "1500px",
            maxWidth: "100%",
            alignContent: "center",
          },
        });
        Pagerefresh();
      } else {
        toast({
          title: data.message,
          position: "bottom-right",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "1500px",
            maxWidth: "100%",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-right",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "1500px",
          maxWidth: "100%",
          alignContent: "center",
        },
      });
    }
  };

  return (
    <>
      <Header /> {/* Render a Header component */}
      <form>
        {/* Create a form element with a submission handler */}
        <div className="top-bar bg-blue-950 text-white dark:bg-indigo-900">
          {/* Create a top bar with buttons */}
          <p className="float-left py-1 px-2 rounded my-2">Create Rate</p>
          <button
            className="flexbox-container flexbox-item flexbox-item-4 bg-white text-blue-900 text-base font-semibold dark:text-white dark-bg-blue-950"
            type="submit"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Save
          </button>
          <button
            className="flexbox-container flexbox-item flexbox-item-2 bg-white text-blue-900 text-base font-semibold dark-text-white dark-bg-blue-950"
            type="submit"
            onClick={(e) => {
              handleSubmitNext(e);
            }}
          >
            Save & Next
          </button>
          <button
            className="flexbox-container flexbox-item flexbox-item-2 bg-white text-blue-900 text-base font-semibold dark-text-white dark-bg-blue-950"
            onClick={() => {
              navigate("/rate");
            }}
            disabled={!submitAllowed}
          >
            Cancel
          </button>
        </div>
        <div className="container-from-data bg-white dark-bg-slate-950 text-black dark-text-white">
          {/* Create a container for form data */}
          <div className="title">Rate Information</div> {/* Add a title */}
          <br />
          <br />
          <div className="content">
            <div className="form-user">
              <div className="user-rate">
                <div className="input-box">
                  <span className="rate">Rate</span>{" "}
                  {/* Label for Rate input */}
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="Rate"
                    value={formData.Rate}
                    onChange={handleChange}
                    placeholder="Enter the Rate"
                  />{" "}
                  {/* Input field for Rate */}
                </div>
                <div className="input-box">
                  <span className="rate">Description</span>{" "}
                  {/* Label for Description input */}
                  <input
                    className="text-black bg-white dark-bg-gray-950 dark-text-white"
                    type="text"
                    name="Description"
                    value={formData.Description}
                    onChange={handleChange}
                    placeholder="Enter the Description"
                  />{" "}
                  {/* Input field for Description */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Addrate;
