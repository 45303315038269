import React from "react";
import { useState, useEffect } from "react";
import { Header } from "../../components";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Axios from "../../api-instance";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./AddVendor.css";

const AddVendor = () => {
  // From Handling Data Send
  // const [value, setValue] = useState();
  const [Phone, setPhone] = useState("");
  const toast = useToast();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [vendorId, setVendorId] = useState(uuidv4());
  const [vendornumber, setVendorNumber] = useState(null);
  const [countriesDataSet, setCountriesDataSet] = useState([]);
  const [citiesDataSet, setCitiesDataSet] = useState([]);
  const [gradesDataSet, setGradesDataSet] = useState([]);
  // Generated Date Auto
  const current = new Date();
  const currentDate = `${current.getFullYear()}-${(current.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${current.getDate().toString().padStart(2, "0")}`;
  const [formData, setFormData] = useState({
    userId: JSON.parse(localStorage.getItem("user")).id,
    VendorId: vendorId,
    VendorNumber: "",
    Company: "",
    VendorType: "",
    PIC: "",
    Email: "",
    PhoneWithCode: "",
    CountryData: "",
    Country: "",
    City: "",
    Designation: "",
    Specialization: "",
    Datecreated: currentDate,
  });
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await Axios.get("/countries/get_countries");

        setCountriesDataSet(data);
      } catch (error) {
        toast({
          title: error.message,
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    };

    getData();
  }, []);
  // useEffect(() => {
  //   const getData = async () => {
  //     try {
  //       const { data } = await Axios.get("/grades/get_grades");
  //       setGradesDataSet(data.data);
  //       console.log(data.data);
  //     } catch (error) {
  //       toast({
  //         title: error.message,
  //         position: "top-right",
  //         isClosable: true,
  //         status: "error",
  //       });
  //     }
  //   };
  //   getData();
  // }, []);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await Axios.get("/vendors/get_vendornumber", {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        });
        setFormData((prev) => {
          return {
            ...prev,
            VendorNumber: data.data,
          };
        });
        setVendorNumber(data?.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    };
    getData();
  }, [vendornumber]);
  const handleChangePhone = (value, countryData) => {
    console.log(value, countryData);
    const dialCodeLength = countryData.dialCode.length;
    const Phone = value.slice(dialCodeLength);
    setFormData({
      ...formData,
      Phone,
      PhoneWithCode: value,
      CountryData: countryData,
    });
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });

    if (name === "Country") {
      try {
        const { data } = await Axios.get(
          `/countries/get_cities?country=${value}`
        );

        setCitiesDataSet(data[0]?.cities);

        console.log("Cities Data:", data[0]?.cities);
      } catch (error) {
        toast({
          title: error.message,
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  // End

  const handleSubmit = async (event) => {
    event.preventDefault();

    // formData.VendorNumber = vendornumber;
    console.log(formData, "form");
    const { data } = await Axios.post("/vendors/add_vendor", formData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(localStorage.getItem("token")),
      },
    });

    console.log("====================================");
    console.log(data);
    console.log("====================================");

    if (data.success) {
      toast({
        title: "Vendor created successfully!",
        position: "top-right",
        isClosable: true,
        status: "success",
      });
    } else {
      toast({
        title: data.message,
        position: "top-right",
        isClosable: true,
        status: "error",
      });
    }
  };

  const handleSubmitNext = async (event) => {
    try {
      event.preventDefault();
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailPattern.test(formData.Email);

      if (!isValidEmail) {
        toast({
          title: "Please provide a valid email address",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
        return;
      }

      const { data } = await Axios.post("/vendors/add_vendor", formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      });

      if (data.success) {
        toast({
          title: "Vendor created successfully!",
          position: "top-right",
          isClosable: true,
          status: "success",
        });
        navigate("/vendor");
      } else {
        toast({
          title: data.message,
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    } catch (error) {
      toast({
        title: error.message,
        position: "top-right",
        isClosable: true,
        status: "error",
      });
    }
  };

  return (
    <>
      <Header />
      <form onSubmit={handleSubmit}>
        <div className="top-bar bg-blue-950  text-white  dark:bg-indigo-900 ">
          <p className="float-left py-1 px-2 rounded my-2">Create Vendor</p>
          {/* <button className="flexbox-container flexbox-item flexbox-item-4 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950">
            Save
          </button> */}
          <button
            className="flexbox-container flexbox-item flexbox-item-3 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            type="submit"
            onClick={handleSubmitNext}
          >
            Save
          </button>
          <button className="flexbox-container flexbox-item flexbox-item-2 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950">
            {" "}
            Cancel
          </button>
        </div>

        <div className="container-from-data bg-white dark:bg-slate-950 text-black dark:text-white">
          <div className="title">Vendor Information</div>
          <br />
          <br />
          <div className="content">
            <div className="form-user">
              <div className="user-vendors">
                {/* <div className="input-box">
          <span className="vendors">
            Vendor ID<span style={{ color: "red" }}>*</span>
          </span>
          <input
            className="text-black bg-white dark:bg-gray-950 dark:text-white"
            type="text"
            name="VendorId"
            value={vendorId}
            onChange={handleChange}
            placeholder="Enter your Vendor Id"
            required
          />
        </div> */}
                <div className="input-box">
                  <span className="vendors">
                    Vendor Number<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="VendorNumber"
                    value={vendornumber}
                    onChange={handleChange}
                    placeholder="Enter your Vendor Number"
                    required
                    disabled
                  />
                </div>
                <div className="input-box">
                  <span className="vendors">
                    Company<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Company"
                    value={formData.Company}
                    onChange={handleChange}
                    placeholder="Enter your Company"
                    required
                  />
                </div>
                <div className="input-box">
                  <span className="vendors">
                    Vendor Type<span style={{ color: "red" }}>*</span>
                  </span>
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="VendorType"
                    value={formData.VendorType}
                    onChange={handleChange}
                    placeholder="Enter your VendorType"
                    required
                  >
                    <option value="Null">--Select vendor type--</option>
                    <option>LCL Consolidator</option>
                    <option>Main Line</option>
                    <option>CHA</option>
                    <option>Transporter</option>
                  </select>
                </div>

                <div className="input-box">
                  <span className="vendors">
                    PIC<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="PIC"
                    value={formData.PIC}
                    onChange={handleChange}
                    placeholder="Enter your PIC"
                    required
                  />
                </div>

                <div className="input-box">
                  <span className="vendors">
                    Email<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Email"
                    value={formData.Email}
                    onChange={handleChange}
                    placeholder="Enter your Email"
                    required
                  />
                </div>

                <div className="input-box">
                  <span className="vendors">
                    Phone<span style={{ color: "red" }}>*</span>
                  </span>
                  <PhoneInput
                    className="dark:bg-gray-950 dark:text-white phone country"
                    name="Phone"
                    country={"us"}
                    value={formData.PhoneWithCode}
                    onChange={(value, countryData) =>
                      handleChangePhone(value, countryData)
                    }
                  />
                  {/* <select className="text-black bg-white dark:bg-gray-950 dark:text-white countrycode">
            <option>+91</option>
          </select>
          &nbsp;
          <input
            className="text-black bg-white dark:bg-gray-950 dark:text-white phone"
            type="text"
            name="Phone"
            maxLength={10}
            value={formData.Phone}
            onChange={handleChange}
            placeholder="Enter your Phone"
            // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            required
          /> */}
                </div>

                <div className="input-box">
                  <span className="vendors">
                    Country<span style={{ color: "red" }}>*</span>
                  </span>
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="Country"
                    value={formData.Country}
                    onChange={handleChange}
                    required
                  >
                    <option>Select Country</option>
                    {countriesDataSet?.map((item) => {
                      return <option value={item}>{item}</option>;
                    })}
                  </select>
                </div>

                <div className="input-box">
                  <span className="vendors">City</span>
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="City"
                    value={formData.City}
                    onChange={handleChange}
                    required
                  >
                    <option>Select City</option>
                    {citiesDataSet?.map((item) => {
                      return <option value={item}>{item}</option>;
                    })}
                  </select>
                </div>

                {/* <div className="input-box "> */}
                {/* <span className="leads">Date created</span> */}
                {/* <div className={colorTheme === "dark" ? "dark-theme" : "light-theme"}> */}
                <input
                  className="text-black bg-white dark:bg-gray-950 dark:text-white "
                  type="date"
                  name="Datecreated"
                  value={formData.Datecreated || currentDate}
                  onChange={handleChange}
                  placeholder="Enter your Date created"
                  required
                  hidden
                />
                {/* </div> */}
                {/* </div> */}

                <div className="input-box">
                  <span className="vendors">Designation</span>
                  <input
                    className="dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Designation"
                    value={formData.Designation}
                    onChange={handleChange}
                    placeholder="Enter your Designation"
                    showIcon
                    required
                  />
                  {/* <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} /> */}
                </div>

                <div className="input-box">
                  <span className="vendors">Specialization</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    id="Specialization"
                    name="Specialization"
                    value={formData.Specialization}
                    onChange={handleChange}
                    placeholder="Enter the Specialization"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddVendor;
