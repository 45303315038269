import React from "react";
import { Bar, Line, Pie, Doughnut } from "react-chartjs-2";
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
  } from "chart.js/auto";
ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement
  );
function BarChart({ chartData }) {
  console.log("BarChart chartData:", chartData);
  return <Bar data={chartData} />;
}

function LineChart({ chartData }) {
    console.log("LineChart chartData:", chartData);
    return <Line data={chartData} />;
  }
  

function PieChart({ chartData }) {
  console.log("PieChart chartData:", chartData);
  return <Pie data={chartData} />;
}

function DoughnutChart({ chartData }) {
  console.log("DoughnutChart chartData:", chartData);
  return <Doughnut data={chartData} />;
}

export { BarChart, LineChart, PieChart, DoughnutChart };