import {React, useState,useEffect} from "react";
// import MXM from "../assets/img/MXM.png";
import MXM from "../../assets/img/MXM.mp4";
import { useNavigate } from "react-router-dom";
import Axios from "../../api-instance";
import { Tooltip, useToast } from "@chakra-ui/react";
import { BiShow, BiHide } from "react-icons/bi";
import "../../assets/css/login.css";
import login from '../../assets/img/login.webp'
import { Card, Stack, Heading, CardBody, Image, Text, CardFooter, Button } from '@chakra-ui/react'

const Login = () => {
  const CryptoJS = require('crypto-js')
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const handleShowPassword = () => {
    setIsPasswordVisible(!isPasswordVisible);
    setShowPassword(!showPassword);
  };
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const toast = useToast();

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      if (!email) {
        toast({
          title: "Please fill in the username.",
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        return;
      }

      if (!password) {
        toast({
          title: "Please fill in the password.",
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        return;
      }
      const { data } = await Axios.post("/login", {
        emailOrUsername: email,
        password:password,
      });
      if (data.success) {
        localStorage.setItem("token", JSON.stringify(data?.result?.token));
        localStorage.setItem("user", JSON.stringify(data?.result?.user));
        navigate("/dashboard"); //it will take you to dashboard page
      } else {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };
  const registerpage = () => {
    navigate("/register");
  };
  const forgetpassword = () => {
    navigate("/forgetpassword");
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
  return (
    <div className="p-4 flex flex-shrink w-full bg-slate-200">
      <div className="rounded-2xl flex flex-shrink shadow-md sm:flex-row flex-col w-full">
        <div className="flex flex-1 w-full ">
          {/* <img
            className="object-cover overflow-hidden rounded-tl-2xl rounded-bl-2xl w-full"
            src={MXM}
            alt="image"
          /> */}
          <div className="container-control">
            <div className="rotating-text-wrapper">
              <h2>Welcome to Matrix Mindz</h2>
            </div>
            <video className="h-screen video-control" autoPlay muted loop>
              <source src={MXM} type="video/mp4" />
            </video>
          </div>
        </div>
        <div className="flex flex-1 flex-col justify-evenly  p-3.5 min-w-0">
          <div className="px-8 md:px-16 ">
            <h2 className=" font-bold text-2xl text-[#002D74]">Welcome Back</h2>
            <p className="text-xs  text-[#002D74]">
              Welcome back! Please enter your details.
            </p>
            <br />
            <form
              onSubmit={handleSubmit}
              className="font-sans text-sm rounded w-full max-w-xs mx-auto pb-8"
            >
              <Tooltip
                hasArrow
                label="Username or Email"
                placement="right-end"
                bg="gray.300"
                color="black"
              >
                <div className="relative border rounded mb-4 shadow appearance-none label-floating">
                  <input
                    className="w-full py-2 px-3 bg-slate-200 text-base leading-normal rounded cursor-pointer"
                    type="text"
                    placeholder="Enter your Email or Username"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </Tooltip>
              <Tooltip
                hasArrow
                label="Password"
                placement="right-end"
                bg="gray.300"
                color="black"
              >
                <div className="relative border rounded mb-4 shadow appearance-none label-floating">
                  <input
                    className="w-full py-2 px-3 bg-slate-200 text-base leading-normal rounded cursor-pointer"
                    type={isPasswordVisible ? "text" : "password"}
                    name="password"
                    placeholder="Enter your password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {showPassword ? (
                    <BiShow
                      size={20}
                      className="cursor-pointer eye-slash absolute top-1/2 right-3 -translate-y-1/2"
                      onClick={handleShowPassword}
                    />
                  ) : (
                    <BiHide
                      size={20}
                      className="cursor-pointer eye-slash absolute top-1/2 right-3 -translate-y-1/2"
                      onClick={handleShowPassword}
                    />
                  )}
                </div>
              </Tooltip>
              <br />
              <div className="text-xs  text-[#002D74]">
                <div className="flex items-center">
                  <input
                    id="link-checkbox"
                    type="checkbox"
                    value=""
                    className="w-4 h-4 text-amber-500 bg-gray-100 border-gray-300 rounded focus:ring-amber-500"
                  />
                  <label
                    htmlFor="link-checkbox"
                    className="ml-2 text-sm font-medium whitespace-nowrap"
                  >
                    Remember for 30 days
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <label>
                    <a
                      onClick={forgetpassword}
                      className="ml-2 text-sm font-medium whitespace-nowrap text-red-500 cursor-pointer"
                    >
                      Forgot password?
                    </a>
                  </label>
                </div>
                <br />
                <button
                  type="submit"
                  className="bg-[#172554] w-48 font-medium rounded-xl text-base text-white py-2 hover:scale-105 duration-300"
                >
                  Sign in
                </button>
                <br />
                <br />
                {/* <p className="font-medium text-red-400">
                  Don't have an account?
                </p> */}
                <br />
                {/* <button
                  className="py-2 px-5 bg-white border rounded-xl hover:scale-110 duration-300 text-blue-950 text-sm"
                  onClick={registerpage}
                >
                  Sign Up
                </button> */}
              </div>
            </form>
            <div className="mt-3 text-xs flex justify-between items-center text-[#002D74]"></div>
            {/* <div className="w-full  grid grid-cols-3 items-center text-gray-400">
              <hr className="border-gray-400 items-center justify-center" />
              <p className="text-center text-sm">OR</p>
              <hr className="border-gray-400 items-center justify-center" />
            </div> */}
            {/* <button className="bg-white border py-2  w-64 rounded-xl mt-5 mx-auto flex justify-center items-center text-sm hover:scale-105 duration-300 text-[#002D74]">
              <svg
                className="mr-3 "
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                width="25px"
              >
                <path
                  fill="#FFC107"
                  d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                />
                <path
                  fill="#FF3D00"
                  d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                />
                <path
                  fill="#4CAF50"
                  d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                />
                <path
                  fill="#1976D2"
                  d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                />
              </svg>
              Sign in with Google
            </button> */}
           
          </div>
        </div>
      </div>
    </div>
    // <div className="flex w-screen flex-wrap text-slate-800">
    //   <div className="relative hidden h-screen select-none flex-col justify-center bg-blue-600 text-center md:flex md:w-1/2">
    //     <div className="mx-auto py-16 px-8 text-white xl:w-[40rem]">
    //       {/* <span className="rounded-full bg-white px-3 py-1 font-medium text-blue-600">
    //         New Feature
    //       </span> */}
    //       <p className="my-6 text-7xl font-serif leading-10">
    //       MatrixMindz
    //         {/* <span className="mx-auto block w-56 whitespace-nowrap rounded-lg bg-orange-400 py-2 text-white">
    //           MatrdixMindz
    //         </span> */}

    //       </p>
    //       <p className="my-6 text-1xl font-serif leading-10">
    //       &copy; {currentYear}, MatrixMindz.
    //       </p>
          
    //       {/* <img src={login} alt="MatrixMindz" /> */}
    //       {/* <p className="mb-4">
    //         Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt
    //         necessitatibus nostrum repellendus ab totam.
    //       </p>
    //       <a
    //         href="#"
    //         className="font-semibold tracking-wide text-white underline underline-offset-4"
    //       >
    //         Learn More
    //       </a> */}
    //     </div>
    //     {/* <img class="mx-auto w-11/12 max-w-lg rounded-lg object-cover" src="/images/SoOmmtD2P6rjV76JvJTc6.png" /> */}
    //   </div>
    //   <div className="flex w-full flex-col md:w-1/2">
    //     <div className="flex justify-center pt-12 md:justify-start md:pl-12">

    //       <a href="#" className="text-2xl font-bold text-blue-600">
    //         {" "}
    //         MatrixMindz CRM{" "}
    //       </a>

    //     </div>
    //     <div className="my-auto mx-auto flex flex-col justify-center px-6 pt-4 md:justify-start lg:w-[28rem]">
    //       <p className="text-center text-3xl font-bold md:text-center md:leading-tight">
    //         Welcome Back
    //       </p>
    //       <p className="mt-6 text-center font-medium md:text-center">
    //         Welcome back! Please enter your details.
    //       </p>
    //       {/* <button className="-2 mt-8 flex items-center justify-center rounded-md border px-4 py-1 outline-none ring-gray-400 ring-offset-2 transition hover:border-transparent hover:bg-black hover:text-white focus:ring-2">
    //     <img
    //       className="mr-2 h-5"
    //       src="/images/-9jfz8JJkYKu0yDYmD5WM.svg"
    //       alt=""
    //     />{" "}
    //     Get started with Google
    //   </button> */}
    //       {/* <div className="relative mt-8 flex h-px place-items-center bg-gray-200">
    //     <div className="absolute left-1/2 h-6 -translate-x-1/2 bg-white px-4 text-center text-sm text-gray-500">
    //       Or use email instead
    //     </div>
    //   </div> */}
    //       <form onSubmit={handleSubmit} className="flex flex-col items-stretch pt-3 md:pt-8">
    //         <div className="flex flex-col pt-4">
    //           {/* <div className="relative flex overflow-hidden rounded-md border-2 transition focus-within:border-blue-600">
    //             <input
    //               type="text"
    //               id="login-name"
    //               className="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
    //               placeholder="Name"
    //             />
    //           </div> */}
    //         </div>
    //         <div className="flex flex-col pt-4">
    //           <div className="relative flex overflow-hidden rounded-md border-2 transition focus-within:border-blue-600">
    //             <input
    //               type="text"
    //               id="email"
    //               value={email}
    //               onChange={(e) => setEmail(e.target.value)}
    //               className="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
    //               placeholder="Email or Username"
    //             />
    //           </div>
    //         </div>
    //         <div className="mb-4 flex flex-col pt-4">
    //           <div className="relative flex overflow-hidden rounded-md border-2 transition focus-within:border-blue-600">
    //             <input
    //               type={isPasswordVisible ? "text" : "password"}
    //               id="password"
    //               value={password}
    //               onChange={(e) => setPassword(e.target.value)}
    //               className="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
    //               placeholder="Password (minimum 8 characters)"
    //             />
    //             <span onClick={handleShowPassword}>
    //               {isPasswordVisible ? <BiHide className="cursor-pointer eye-slash absolute top-1/2 right-3 -translate-y-1/2" size={20} /> : <BiShow className="cursor-pointer eye-slash absolute top-1/2 right-3 -translate-y-1/2" size={20} />}
    //             </span>
    //           </div>
    //         </div>
    //         <div className="block">
    //           <input
    //             className="mr-2 h-5 w-5 float-left appearance-none rounded border border-gray-300 bg-contain bg-no-repeat align-top text-black shadow checked:bg-blue-600 focus:border-blue-600 focus:shadow"
    //             type="checkbox"
    //             id="remember-me"

    //           />
    //           <label className="inline-block float-left" htmlFor="remember-me">
    //             {" "}
    //             Remember for 30 days
    //           </label>
    //           <label className="inline-block" htmlFor="remember-me">

    //             <a onClick={forgetpassword} className="whitespace-nowrap font-semibold text-blue-700 float-right cursor-pointer">
    //               Forgot password?
    //             </a>
    //           </label>
    //         </div>
    //         <button
    //           type="submit"
    //           className="mt-6 rounded-lg bg-blue-600 px-4 py-2 text-center text-base font-semibold text-white shadow-md outline-none ring-blue-500 ring-offset-2 transition hover:bg-blue-700 focus:ring-2 md:w-32"
    //         >
    //           Sign in
    //         </button>
    //       </form>
    //     </div>
    //   </div>
    // </div>

  );
};

export default Login;
