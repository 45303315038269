import  {React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Bruhat_Logo from "../../assets/img/Bruhat_Logo.png";
import { AiOutlineSetting } from "react-icons/ai";
import { AiOutlineTeam } from "react-icons/ai";
import { FaRegUserCircle } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { BsCalendarEvent } from "react-icons/bs";
import { MdRefresh } from "react-icons/md";
import "./Header.css";
import { useNavigate } from "react-router-dom";
import Axios from "../../api-instance";
import Switcher from "../Switcher/Switcher";
import Userprofile from "../User/Userprofile";
import { Tooltip, useToast } from "@chakra-ui/react";
const Header = () => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState();
  const Pagerefresh = () => window.location.reload(true);

  const registerpage = () => {
    navigate("/register");
  };
  const toast = useToast();
  const [userdata, setUserdata] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await Axios.get(
          `user/get_user?userId=${
            JSON.parse(localStorage.getItem("user")).userId
          }`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: JSON.parse(localStorage.getItem("token")),
            },
          }
        );

        const userDataList = response?.data?.data || [];
        setUserdata(userDataList);
      } catch (error) {
        // Handle error
      }
    };

    getData();
  }, []);

  // Extracting the roles from user data
  const userRoles = userdata.map(user => user.Role);

  // Check if the user has the 'Admin' role
  const isTechSupport = userRoles.includes('TechSupport');
  const isAdmin = userRoles.includes('Admin');
  return (
    <>
      <header>
        <nav className="bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-slate-800">
          <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
            <Link to="/dashboard" className="flex items-center">
              <img src={Bruhat_Logo} className="mr-3 h-6 sm:h-9" alt="Bruhat" />
            </Link>
            <div className="flex items-center lg:order-2">
              <MdRefresh
                className="mr-5 dark:bg-gray-800 dark:text-white cursor-pointer"
                onClick={Pagerefresh}
                size={20}
              />

              <AiOutlineSetting
                className="mr-5 dark:bg-gray-800 dark:text-white cursor-pointer"
                size={20}
              />
              <Tooltip hasArrow label='TechSupport' bg='gray.300' color='black'>
            <div>
            {isTechSupport && (
             <AiOutlineTeam 
            className="mr-5 dark:bg-gray-800 dark:text-white cursor-pointer"
           onClick={registerpage}
           size={20}
           />)}
           </div>
           </Tooltip> 
           <Tooltip hasArrow label='Admin' bg='gray.300' color='black'>
            <div>
            {isAdmin && (
             <AiOutlineTeam 
            className="mr-5 dark:bg-gray-800 dark:text-white cursor-pointer"
           onClick={registerpage}
           size={20}
           />)}
           </div>
           </Tooltip>      
                {/* <button
                data-collapse-toggle="mobile-menu-2"
                type="button"
                class="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                aria-controls="mobile-menu-2"
                aria-expanded="false"
              >
                <GiHamburgerMenu  
                  className="dark:bg-gray-800 dark:text-white cursor-pointer"
                  size={25}
                />
              </button> */}
              {/* <Switcher
                className="mr-5 dark:bg-gray-800 dark:text-white cursor-pointer"
                size={24}
              /> */}
              <Userprofile
                className="ml-5 dark:bg-gray-800 dark:text-white cursor-pointer"
                size={20}
              />
              <button
                data-collapse-toggle="mobile-menu-2"
                type="button"
                class="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                aria-controls="mobile-menu-2"
                aria-expanded="false"
              >
                <GiHamburgerMenu
                  className="dark:bg-gray-800 dark:text-white cursor-pointer"
                  size={25}
                />
              </button>
            </div>
            <div
              class="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1"
              id="mobile-menu-2"
            >
              <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                <li>
                  <Link
                    to="/dashboard"
                    className={`block py-2 pr-4 pl-3 ${
                      currentTab === "dashboard" ? "text-blue" : "text-gray-700"
                    } rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white`}
                  >
                    Dashboard
                  </Link>
                </li>
                <li>
                  <Link
                    to="/lead"
                    className={`block py-2 pr-4 pl-3 ${
                      currentTab === "lead" ? "text-blue" : "text-gray-700"
                    } border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700`}
                  >
                    Leads
                  </Link>
                </li>
                <li>
                  <Link
                    to="/prospect"
                    className={`block py-2 pr-4 pl-3 ${
                      currentTab === "prospect" ? "text-blue" : "text-gray-700"
                    } border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700`}
                  >
                    Prospects
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ pointerEvents: "none", color: "grey" }}
                    to="/booking"
                    className={`block py-2 pr-4 pl-3 ${
                      currentTab === "booking" ? "text-blue" : "text-gray-700"
                    } border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700`}
                  >
                    Booking
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ pointerEvents: "none", color: "grey" }}
                    to="/opertion_home"
                    className={`block py-2 pr-4 pl-3 ${
                      currentTab === "opertion_home" ? "text-blue" : "text-gray-700"
                    } border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700`}
                  >
                    Operation & Docs
                  </Link>
                </li>
                <li>
                  <Link
                    //style={{ pointerEvents: "none", color: "grey" }}
                    to="/report"
                    className={`block py-2 pr-4 pl-3 ${
                      currentTab === "report" ? "text-blue" : "text-gray-700"
                    } border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700`}
                  >
                    Reports
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ pointerEvents: "none", color: "grey" }}
                    to="/enquiry"
                    className={`block py-2 pr-4 pl-3 ${
                      currentTab === "enquiry" ? "text-blue" : "text-gray-700"
                    } border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700`}
                  >
                    Enquiry
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ pointerEvents: "none", color: "grey" }}
                    to="/vendor"
                    className={`block py-2 pr-4 pl-3 ${
                      currentTab === "vendor" ? "text-blue" : "text-gray-700"
                    } border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700`}
                  >
                    Vendor
                  </Link>
                </li>
                {/* <li>
                  <Link
                    style={{ pointerEvents: "none", color: "grey" }}
                    to="/industry"
                    className={`block py-2 pr-4 pl-3 ${
                      currentTab === "report" ? "text-blue" : "text-gray-700"
                    } border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700`}
                  >
                    Industry
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
