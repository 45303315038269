import React, { useState, useEffect } from "react";
import { BiSolidMinusCircle } from "react-icons/bi";
import { IoMdAddCircle } from "react-icons/io";
import { Button, Radio, useDisclosure, FormControl, Flex, RadioGroup, Stack, useToast } from '@chakra-ui/react'
import Axios from "../../api-instance.js"
// import BarChart from "./Charts/BarChart.js";
// import LineChart from "./Charts/LineChart.js";
// import PieChart from "./Charts/PieChart.js";
// import DoughnutChart from "./Charts/DoughnutChart.js";
import {
  BarChart, LineChart, PieChart, DoughnutChart
} from './Charts/charts.js'
import { Rnd } from "react-rnd";
import { CgClose } from "react-icons/cg";
import "./Editchart.css";
import { chartColors } from "../../Utils/colors.js";
function Editchart() {
  const toast = useToast();
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [loading, setLoading] = useState(true);
  const {
    isOpen: isOpenFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();
  const style = {
    // display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#ffff",
    color: "black",
    borderRadius: "20px",
    padding: "20px",
    boxShadow: "0px 0px 5px grey",
    backdropFilter: "blur(10px)",
    overflow: "hidden",
  };

  const modalStyle = {
    position: "absolute",
    top: "20%",
    left: "0%",
    zIndex: "200",
  };

  const modalStyleUpdate = {
    position: "absolute",
    top: "20%",
    left: "45%",
    zIndex: "200",
  };

  const closeModal = {
    position: "absolute",
    top: "3%",
    right: "5%",
    cursor: "pointer",
  };
  const [scrollBehavior, setScrollBehavior] = React.useState("inside");
  const [selectedFields, setSelectedFields] = useState([""]);
  const [selectedChart, setSelectedChart] = useState("bar");
  const name = [
    "Lead Number",
    "City",
    "Country",
    "Tags",
    "Tier",
    "Industry"
  ];
  
  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const userId = JSON.parse(localStorage.getItem("user")).userId;
        const token = JSON.parse(localStorage.getItem("token"));
  
        const response = await Axios.get(
          `dashboard/leads/get_chart_lead?userId=${userId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
  
        const responseData = response.data;
  
        console.log("Response Data:", responseData);
  
        //useEffect function
        if (Array.isArray(responseData.data)) {
          const fieldCounts = {};
  
          // Count each field
          responseData.data.forEach((lead) => {
            selectedFields.forEach((field) => {
              const value = lead[field] !== null ? lead[field] : 'null';
              if (!fieldCounts[field]) {
                fieldCounts[field] = {};
              }
              if (!fieldCounts[field][value]) {
                fieldCounts[field][value] = 1;
              } else {
                fieldCounts[field][value]++;
              }
            });
          });
  
          setChartData((prevChartData) => ({
            labels: name,
            datasets: selectedFields.map((field, index) => {
              const label = typeof field === "string"
                ? field.charAt(0).toUpperCase() + field.slice(1)
                : field;
  
              return {
                label,
                data: Object.values(fieldCounts[field]),
                backgroundColor: chartColors,
              };
            }),
          }));
        }
  
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
        toast({
          title: error.message,
          description: "Failed to fetch data",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };
  
    getData();
  }, [selectedFields, toast]);
  
  const handleChartChange = (chartType) => {
    setSelectedChart(chartType);
  };


  let selectedChartComponent;

  // Add a check for chartData existence
  if (chartData && chartData.datasets && chartData.labels) {
    if (selectedChart === "bar") {
      selectedChartComponent = <BarChart chartData={chartData} />;
    } else if (selectedChart === "line") {
      selectedChartComponent = <LineChart chartData={chartData} />;
    } else if (selectedChart === "pie") {
      selectedChartComponent = <PieChart chartData={chartData} />;
    } else if (selectedChart === "doughnut") {
      selectedChartComponent = <DoughnutChart chartData={chartData} />;
    }
  } else {
    selectedChartComponent = <p>No data available for the selected chart.</p>;
  }

  const handleRemoveField = (index) => {
    const updatedFields = [...selectedFields];
    updatedFields.splice(index, 1);
    setSelectedFields(updatedFields);
  };

  const handleAddField = (item) => {
    setSelectedFields([...selectedFields, item]);
  };

  return (

    <div >
      <Button colorScheme='teal' size='sm' top='10px' style={{ marginLeft: "90%" }} onClick={isOpenFilter ? onCloseFilter : onOpenFilter}>
        Edit Chart
      </Button>
      {loading && <p>Loading...</p>}
      <>
        {!loading && isOpenFilter && (
          <div className={`FilterModal ${scrollBehavior}`}>
            <Rnd
              style={style}
              default={{
                x: 0,
                y: 0,
                width: 600,
                // height: 200,
              }}
              cancel="X"
              allowAnyClick={true}
            >
              <header style={{ fontSize: "20px", fontWeight: "bold" }}>
                Edit Chart
              </header>
              <div
                className="closemodule"
                onClick={() => {
                  onCloseFilter(false);
                }}
                style={closeModal}
              >
                <CgClose />
              </div>
              <br />

              <FormControl>
                {selectedFields.map((item, index) => (
                  <Flex key={index}>
                    <select
                      size={"md"}
                      onChange={(e) => {
                        const updatedFields = [...selectedFields];
                        updatedFields[index] = e.target.value;
                        setSelectedFields(updatedFields);
                      }} id={`fieldSelector${index}`} value={item}>
                      <option>Select Field</option>
                      <option value="LeadNumber">Lead Number</option>
                      <option value="Tier">Tier</option>
                      <option value="City">City</option>
                      <option value="Country">Country</option>
                      <option value="Industry">Industry</option>
                      <option value="Tags">Tags</option>

                    </select>
                    &nbsp;&nbsp;
                    <IoMdAddCircle
                      className="cursor-pointer"
                      size={25}
                      style={{ color: "green", marginTop: "5px" }}
                      onClick={() => handleAddField(item, index)}
                    />
                    {selectedFields.length > 1 && index > 0 && (
                      <BiSolidMinusCircle
                        className="cursor-pointer"
                        size={25}
                        style={{ color: "red", marginTop: "5px" }}
                        onClick={() => handleRemoveField(index)}
                      />
                    )}
                  </Flex>
                ))}
              </FormControl>

              <div style={{ float: "right", display: "block" }}>
                <Button
                  colorScheme="green"
                  onClick={() => {
                    onCloseFilter();
                  }}
                  mr={3}
                >
                  Apply
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() => {

                    onCloseFilter();
                  }}
                >
                  Clear
                </Button>
              </div>
              <div
                style={{
                  float: "left",
                  marginTop: "10px",
                  display: "block",
                }}
              >
                <RadioGroup style={{ flex: 1 }} >
                  <Stack direction="row">

                    <Radio value="line"
                      checked={selectedChart === "line"}
                      onChange={() => handleChartChange("line")}
                    >
                      Line Chart
                    </Radio>
                    <Radio value="pie"
                      checked={selectedChart === "pie"}
                      onChange={() => handleChartChange("pie")}
                    >
                      Pie Chart
                    </Radio>
                    <Radio value="doughnut"
                      checked={selectedChart === "doughnut"}
                      onChange={() => handleChartChange("doughnut")}>
                      Doughnut Chart
                    </Radio>
                  </Stack>
                </RadioGroup>
              </div>
            </Rnd>
          </div>
        )}
      </>
      {!loading && <div className="chartShow">{selectedChartComponent}</div>}
    </div>

  );
}
export default Editchart;
