import React, { useEffect, useState } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { useQuery } from "@tanstack/react-query";

//internal module import
import {
  Header,
  Menu,
  PrimaryButton,
  UnAuthorizedModal,
} from "../../components";
import SideBard from "./sidebar";
import RecentTable from "./RecentTable";
import Followup from "./Followup";
//import { BarChart, DonutChart, DonutChartIndustry } from "./Chart";
import LeadOwnerAnalytics from "./LeadOwnerAnalytics";
import Axios from "../../api-instance";
import { countKey, defaultComponents } from "./helper";
import TotalCount from "./TotalCount";
import { useToast } from "@chakra-ui/react";
import { FaListUl } from "react-icons/fa";
import Editchart from "./Editchart";
import { BarChart, DonutChart, PieChart } from "./Chart";

export default function Dashboard() {
  //usestate
  const [componentSettings, setComponentSettings] = useState([]);
  const [components, setComponents] = useState(defaultComponents);
  const [selected, setSelected] = useState("Leads");
  const [showCustomizeModal, setShowCustomizeModal] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const manageMenu = [
    {
      text: "Customize",
      onClick() {
        setShowCustomizeModal(true);
      },
    },
  ];

  // Define the query function to fetch data based on the selected
  const dashBoardQuery = useQuery({
    queryKey: ["dashboard", selected],
    queryFn: () =>
      Axios.get(
        `/dashboard/${selected.toLocaleLowerCase()}?userId=${
          JSON.parse(localStorage.getItem("user"))?.userId
        }`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      ),
    retry: false,
  });

  const {
    dataByCountry,
    dataByGrade,
    dataByIndustry,
    dataByCity,
    totalCustomers,
    totalCurrentCustomers,
    totalConverted,
    totalDeleted,
    recentCustomers,
  } = dashBoardQuery?.data?.data?.result || {};

  const gradeAndValue = countKey(dataByGrade, "Tier");  //Grade
  const industryAndValue = countKey(dataByIndustry, "Industry");
  const countryAndValue = countKey(dataByCountry, "Country");
  const cityAndValue = countKey(dataByCity, "City");

  // Function to hide a specific child component by name
  const hideChild = (name) => {
    const newComponents = components.map((component) => {
      if (component.name === name) {
        return { ...component, visible: false };
      }
      return component;
    });
    setComponents(newComponents);
  };

  console.log(components, "component");

  const handleUpdateComponents = (updatedComponents) => {
    setComponents(updatedComponents);
  };

  return (
    <div className="h-dvh w-screen flex flex-col bg-cyan-light font-roboto overflow-hidden">
      <Header />
      <div className="flex-1 flex overflow-hidden">
        {showSidebar && <SideBard {...{ selected, setSelected }} />}
        <div className="relative w-full flex-1 flex flex-col gap-4 p-10 overflow-x-hidden overflow-y-auto">
          {dashBoardQuery?.error?.response?.status !== 401 ? (
            <>
              <div className="flex justify-between">
                <div className="flex items-center justify-center">
                  <FaListUl
                    onClick={() => setShowSidebar(!showSidebar)}
                    className="text-xl font-bold cursor-pointer"
                  />
                  <div className="text-2xl font-bold ml-2">{selected}</div>
                </div>

                <Menu data={manageMenu} bottom={true} toggleType={"active"}>
                  <PrimaryButton
                    className={"flex items-center text-lg"}
                    selected={true}
                  >
                    <span>Manage</span>
                    <MdOutlineKeyboardArrowDown className="text-xl" />
                  </PrimaryButton>
                </Menu>
              </div>

              {/* Render components based on their order */}
              <div className="flex flex-wrap mb-4 gap">
                {components.map((component) => {
                  if (component.visible) {
                    if (component.name === "TOTALCOUNT") {
                      return (
                        <div
                          className="mb-4 flex-initial w-full"
                          key={`component.name`}
                        >
                          <TotalCount
                            totalConverted={totalConverted}
                            totalDeleted={totalDeleted}
                            totalCount={totalCustomers}
                            currentCount={totalCurrentCustomers}
                            selected={selected}
                          />
                        </div>
                      );
                    } else if (component.name === "RECENTS") {
                      return (
                        <div
                          key={component.name}
                          className="mb-4 flex-initial w-full"
                        >
                          <RecentTable
                            data={recentCustomers || []}
                            hideComponent={() => hideChild(component.name)}
                            selectedScreen={selected}
                          />
                        </div>
                      );
                    } else if (component.name === "FOLLOWUPS") {
                      return (
                        <div
                          key={component.name}
                          className="mb-4 flex-initial w-full"
                        >
                          <Followup
                            selectedScreen={selected}
                            hideComponent={() => hideChild(component.name)}
                          />
                        </div>
                      );
                    } 

                    else if (
                      component.name === "LEADS_BY_GRADE" ||
                      component.name === "LEADS_BY_CITY" ||
                      component.name === "LEADS_BY_COUNTRY" ||
                      component.name === "LEADS_BY_INDUSTRY"
                    ) {
                      return (
                        <div key={component.name} className="flex-1 mb-4">
                          {component.name === "LEADS_BY_GRADE" && (
                            <DonutChart
                              className={"max-w-sm"}
                              data={gradeAndValue}
                              cardTitle={`${selected} by Tier`}
                              hideComponent={() => hideChild(component.name)}
                            />
                          )}
                          {component.name === "LEADS_BY_CITY" && (
                            <DonutChart
                              className={"max-w-sm"}
                              data={cityAndValue}
                              cardTitle={`${selected} by City`}
                              hideComponent={() => hideChild(component.name)}
                            />
                          )}
                          {component.name === "LEADS_BY_COUNTRY" && (
                            <DonutChart
                              className={"max-w-sm"}
                              data={countryAndValue}
                              cardTitle={`${selected} by Country`}
                              hideComponent={() => hideChild(component.name)}
                            />
                          )}
                          {component.name === "LEADS_BY_INDUSTRY" && (
                            <PieChart
                              className={"max-w-sm"}
                              data={industryAndValue}
                              cardTitle={`${selected} by Industry`}
                              hideComponent={() => hideChild(component.name)}
                            />
                          )}
                        </div>
                      );
                    }
                    // else if (
                    //   component.name === "LEADS_Chart"
                    //  )
                    //  {
                    //     return (
                    //     <div key={component.name} className="mb-4 flex-initial w-full bg-white" style={{borderRadius:"10px"}}>
                    //       <div style={{position:"relative"}}>
                    //       <Editchart/>
                    //       </div>
                    //     </div>
                    //     );
                    //   } 
                  
                    else if (component.name === "LEAD_OWNER_ANALYTICS") {
                      if (selected !== "Prospects") {
                        return (
                          <div
                            key={component.name}
                            className="mb-4 flex-initial w-full"
                          >
                            <LeadOwnerAnalytics
                              hideComponent={() => hideChild(component.name)}
                            />
                          </div>
                        );
                      }
                    }
                  }
                })}
                
              </div>
            </>
          ) : (
            <UnAuthorizedModal
              heading={"UnAuthorized"}
              text={"Contact the administrator for more details"}
            />
          )}
        </div>
      </div>
      {showCustomizeModal && (
        <CustomizeModal
          cards={components}
          onUpdateComponents={setComponents}
          setShowCustomizeModal={setShowCustomizeModal}
          selected={selected}
        />
      )}
    </div>
  );
}

//function to customize the dashboard
function CustomizeModal({
  cards = [],
  onUpdateComponents,
  setShowCustomizeModal,
  selected,
}) {
  function onToggle(ind) {
    const modified = cards.map((card, index) => {
      if (ind !== index) return card;
      return { ...card, visible: !card.visible };
    });
    onUpdateComponents(modified);
  }

  function onDragStart(e, ind) {
    e.dataTransfer.setData("ind", ind);
  }

  function onDragOver(e) {
    e.preventDefault();
  }
  console.log(selected);
  function onDragDrop(e, ind) {
    const draggedInd = parseInt(e.dataTransfer.getData("ind"));
    const draggedCard = cards[draggedInd];
    const modified = cards.filter((card, index) => index !== draggedInd);
    modified.splice(ind, 0, draggedCard);
    onUpdateComponents(modified);
  }

  return (
    <div
      onClick={() => setShowCustomizeModal(false)}
      className="absolute top-0 left-0 w-full h-full flex items-start justify-center bg-black-shady bg-opacity-30"
    >
      <div
        className="flex flex-col  gap-3"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="h-28"></div>
        {cards.map((card, ind) =>
          // Exclude "LEAD_OWNER_ANALYTICS" card when selected is "Prospects"
          selected === "Prospects" &&
          card.name === "LEAD_OWNER_ANALYTICS" ? null : (
            <div
              key={ind}
              draggable={true}
              onDragStart={(e) => onDragStart(e, ind)}
              onDragOver={onDragOver}
              onDrop={(e) => onDragDrop(e, ind)}
              className="w-4/5 lg:w-[800px] px-7 py-2 rounded-md cursor-grab overflow-hidden bg-white hover:bg-white-primary active:cursor-grabbing"
            >
              <span className="flex gap-4">
                <input
                  type="checkbox"
                  defaultChecked={card.visible}
                  onChange={() => onToggle(ind)}
                />
                <span
                  data-selected={card.visible}
                  className="select-none data-[selected=false]:text-gray-dark"
                >
                  {card.label}
                </span>
              </span>
              <span></span>
            </div>
          )
        )}
      </div>
    </div>
  );
}