import React from "react";
import { useState, useEffect } from "react";
import { Header } from "../../components";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Axios from "../../api-instance";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Addlead.css";
import { GiCancel } from "react-icons/gi";

const Addlead = () => {
  const [submitAllowed, setSubmitAllowed] = useState(true);
  const [Phone, setPhone] = useState("");
  const toast = useToast();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [leadId, setLeadId] = useState(uuidv4());
  const [leadnumber, setLeadNumber] = useState(null);
  const [countryData, setCountryData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [tierData, setTierData] = useState([]);
  const [industryData, setIndustryData] = useState([]);
  const [tagData, setTagData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [leadSourceData, setLeadSourceData] = useState([]);
  const [country, setCountry] = useState("in"); //This state is country has to be change same time Phone code also changed
  // Generated Date Auto
  const current = new Date();
  const currentDate = `${current.getFullYear()}-${(current.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${current.getDate().toString().padStart(2, "0")}`;
  const [formData, setFormData] = useState({
    //userId: JSON.parse(localStorage.getItem("user")).userId,
    LeadId: leadId,
    LeadNumber: "",
    Company: "",
    ContactPerson: "",
    Email: "",
    PhoneWithCode: "",
    CountryData: "",
    Country: "",
    State: "",
    City: "",
    LeadOwner: JSON.parse(localStorage.getItem("user")).userId,
    Industry: "Others",
    Tier: "",
    Tags: "",
    OtherTags: "",
    Region: "",
    OtherRegion:"",
    Source: "",
    OtherSource:"",
    FollowUpDate: "",
    //FollowUpStatus: "pending",
    NotificationDays: "2",
    FollowUpInterval: "2",
    Remarks: "",
  });
  useEffect(() => {
    // Calculate and set the initial follow-up date if not empty
    const calculatedFollowUpDate = calculateFollowUpDate();
    if (calculatedFollowUpDate) {
      setFormData((prevData) => ({
        ...prevData,
        FollowUpDate: calculatedFollowUpDate,
      }));
    }
  }, []);
  const calculateFollowUpDate = () => {
    const currentDate = new Date();
    const notificationDays = parseInt(formData.NotificationDays, 10) || 0;
    const followUpInterval = parseInt(formData.FollowUpInterval, 10) || 0;

    const followUpDate = new Date(currentDate);
    followUpDate.setDate(
      currentDate.getDate() + notificationDays + followUpInterval
    );

    return followUpDate.toISOString().split("T")[0];
  };
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await Axios.get("/masterdata/get_tag");
        setTagData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await Axios.get("/masterdata/get_industry");
        setIndustryData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await Axios.get("/masterdata/get_grade");
        setTierData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await Axios.get("/masterdata/get_region");
        setRegionData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await Axios.get("/masterdata/get_source");
        setLeadSourceData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  // useEffect(() => {
  //   const getData = async () => {
  //     try {
  //       const { data } = await Axios.get(
  //         `/leads/get_leadnumber?userId=${
  //           JSON.parse(localStorage.getItem("user")).userId
  //         }`,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: JSON.parse(localStorage.getItem("token")),
  //           },
  //         }
  //       );
  //       setFormData((prev) => {
  //         return {
  //           ...prev,
  //           LeadNumber: data.data,
  //         };
  //       });
  //       setLeadNumber(data?.data);
  //     } catch (error) {
  //       toast({
  //         title: error.message,
  //         position: "bottom-center",
  //         isClosable: true,
  //         status: "error",
  //         containerStyle: {
  //           width: "98.5vw",
  //           maxWidth: "98.5vw",
  //           alignContent: "center",
  //         },
  //       });
  //     }
  //   };
  //   getData();
  // }, [leadnumber]);

  function isWeekend(date) {
    return date.getDay() === 0 || date.getDay() === 6; // 0 is Sunday, 6 is Saturday
  }

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await Axios.get("/masterdata/get_country");
        setCountryData(data.data);
        console.log(data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };
    getData();
  }, []);
  const handleChangePhone = (value, countryData) => {
    if (countryData && countryData.dialCode) {
      const dialCodeLength = countryData.dialCode.length;
      const Phone = value.slice(dialCodeLength);
      setFormData({
        ...formData,
        Phone,
        PhoneWithCode: value,
        //Country: countryData.iso2,
      });
    } else {
      console.error("countryData is undefined or missing dialCode property");
    }
  };

  const handleCountryChange = async (e) => {
    const selectedCountry = e.target.value;
    const selectedCountryData = countryData.find(
      (item) => item.Country_name === selectedCountry
    );
    console.log("Selected Country Data:", selectedCountryData);
    if (selectedCountryData) {
      setFormData((prevData) => ({
        ...prevData,
        Country: selectedCountry, // Update the "Country" field with the selected country name
        CountryData: selectedCountryData,
        PhoneWithCode: selectedCountryData.Phone_code,
      }));
      try {
        setCityData([]);
        const { data } = await Axios.get(
          `/masterdata/get_city?countryData=${selectedCountryData.Country_name}`
        );

        setCityData(data.data);
        console.log("Cities Data:", data.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } else {
      toast({
        title: "Country Data Not Found",
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });

      // setFormData((prevData) => ({
      //   ...prevData,
      //   Country: selectedCountry, // Update the "Country" field with the selected country name
      //   CountryData: selectedCountryData,
      //   PhoneWithCode: selectedCountryData.Phone_code,
      // }));
    }
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  // End
  const Pagerefresh = () => window.location.reload(true);
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData, "form");
    const { data } = await Axios.post(
      `/leads/add_lead?userId=${JSON.parse(localStorage.getItem("user")).userId
      }`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      }
    );

    console.log("====================================");
    console.log(data);
    console.log("====================================");
    if (data.success) {
      toast({
        title: data.data.message,
        position: "bottom-center",
        isClosable: true,
        status: "success",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
      navigate("/lead");
    } else {
      toast({
        title: data.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  const handleSubmitNext = async (event) => {
    try {
      event.preventDefault();

      const { data } = await Axios.post(
        `/leads/add_lead?userId=${JSON.parse(localStorage.getItem("user")).userId
        }`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );
      if (data.success) {
        toast({
          title: data.data.message,
          position: "bottom-center",
          isClosable: true,
          status: "success",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
        Pagerefresh();
      } else {
        toast({
          title: data.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    } catch (error) {
      toast({
        title: error.message,
        position: "bottom-center",
        isClosable: true,
        status: "error",
        containerStyle: {
          width: "98.5vw",
          maxWidth: "98.5vw",
          alignContent: "center",
        },
      });
    }
  };

  const handleBackToDropdown = (field) => {
    // Reset the value of the corresponding "Other" field and set the main field to its default value
    setFormData((prevData) => ({
      ...prevData,
      [`Other${field}`]: '',
      [field]: `Select ${field}`,
    }));
  };
  return (
    <>
      <Header />
      <form>
        <div className="top-bar bg-blue-950  text-white  dark:bg-indigo-900 ">
          <p className="float-left py-1 px-2 rounded my-2">Create Lead</p>
          <button
            className="flexbox-container flexbox-item flexbox-item-4 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            type="submit"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Save
          </button>
          <button
            className="flexbox-container flexbox-item flexbox-item-3 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            type="submit"
            onClick={handleSubmitNext}
          >
            Save & Next
          </button>
          <button
            className="flexbox-container flexbox-item flexbox-item-2 bg-white  text-blue-900 text-base font-semibold  dark:text-white dark:bg-blue-950"
            onClick={() => {
              navigate("/lead");
            }}
            disabled={!submitAllowed}
          >
            {" "}
            Cancel
          </button>
        </div>

        <div className="container-from-data bg-white dark:bg-slate-950 text-black dark:text-white">
          <div className="title">Lead Information</div>
          <br />
          <br />
          <div className="content">
            <div className="form-user">
              <div className="user-leads">
                <div className="input-box" hidden>
                  <span className="leads" hidden>
                    Lead ID<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="LeadId"
                    value={leadId}
                    onChange={handleChange}
                    placeholder="Enter your Lead Id"
                    required
                    disabled
                    hidden
                  />
                </div>
                {/* <div className="input-box">
                  <span className="leads">
                    Lead Number<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="LeadNumber"
                    value={leadnumber}
                    onChange={handleChange}
                    placeholder="Enter your Lead Number"
                    disabled
                  />
                </div> */}
                <div className="input-box">
                  <span className="leads">
                    Company<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Company"
                    value={formData.Company}
                    onChange={handleChange}
                    placeholder="Enter your Company"
                  />
                </div>
                <div className="input-box">
                  <span className="leads">
                    Contact Person<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="ContactPerson"
                    value={formData.ContactPerson}
                    onChange={handleChange}
                    placeholder="Enter your Contact Person"
                  />
                </div>
                <div className="input-box">
                  <span className="leads">
                    Email<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Email"
                    value={formData.Email}
                    onChange={handleChange}
                    placeholder="Enter your Email"
                  />
                </div>
                <div className="input-box">
                  <span className="leads">
                    Country<span style={{ color: "red" }}>*</span>
                  </span>
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="Country"
                    value={formData.Country}
                    onChange={(e) => {
                      handleCountryChange(e);
                      handleChange(e);
                    }}
                  >
                    <option>Select Country</option>
                    {countryData.map((item) => (
                      <option value={item.Country_name}>
                        {item.Country_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="input-box">
                  <span className="leads">
                    Phone<span style={{ color: "red" }}>*</span>
                  </span>
                  <PhoneInput
                    className="dark:bg-gray-950 dark:text-white country"
                    name="Phone"
                    // country={
                    //   formData.CountryData ? formData.CountryData.iso2 : "in"
                    // }
                    country={country}
                    value={formData.PhoneWithCode}
                    onChange={(value, countryData) =>
                      handleChangePhone(value, countryData)
                    }
                    countryCodeEditable={false}
                  />
                </div>
                {/* <div className="input-box">
                  <span className="leads">
                    State<span style={{ color: "red" }}>*</span>
                  </span>
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="State"
                    value={formData.State}
                    onChange={handleChange}
                    required
                  >
                    <option>Select State</option>
                    {stateData.map((item) => (
                      <option key={item.Country_code}  value={item.State_name}>
                        {item.State_name}
                      </option>
                    ))}
                  </select>
                </div> */}
                <div className="input-box">
                  <span className="leads">City</span>
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="City"
                    value={formData.City}
                    onChange={handleChange}
                  >
                    <option>Select City</option>
                    {cityData.map((item) => (
                      <option value={item.City_name}>{item.City_name}</option>
                    ))}
                  </select>
                </div>

                {/* <div className="input-box">
                  <span className="leads">
                    Lead Owner<span style={{ color: "red" }}>*</span>
                  </span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="LeadOwner"
                    value={formData.LeadOwner || "JOHN WICK"}
                    onChange={handleChange}
                    placeholder="Enter your Lead Owner"
                    required
                    disabled
                  />
                </div> */}
                <div className="input-box">
                  <span className="leads">Industry</span>
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Industry"
                    value={formData.Industry}
                    onChange={handleChange}
                    placeholder="Enter your Industry"
                  >
                    <option>Select Industry</option>
                    {industryData.map((item) => (
                      <option value={item.Industry}>{item.Industry}</option>
                    ))}
                  </select>
                </div>
                <div className="input-box">
                  <span className="leads">Tier</span>
                  
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    name="Tier"
                    value={formData.Tier}
                    onChange={handleChange}
                    placeholder="Enter your Tier"
                  >
                    <option>Select Tier</option>
                    {tierData.map((item) => (
                      <option value={item.Tier}>{item.Tier}</option>
                    ))}
                    </select>
                  </div>
                <div className="input-box">
                  <span className="leads">Tags</span>
                  {formData.Tags !== 'Others' ? (
                  <select
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Tags"
                    value={formData.Tags}
                    onChange={handleChange}
                    placeholder="Enter your Tags"
                  >
                    <option>Select Tags</option>
                    {tagData.map((item) => (
                      <option value={item.Tags}>{item.Tags}</option>
                    ))}
                     <option>Others</option>
                  </select>
                   ) : (
                    <div className="custom-input-container">
                      <input
                        type="text"
                        name="OtherTags"
                        value={formData.OtherTags}
                        onChange={handleChange}
                        placeholder="Enter your custom tag"
                        className="custom-input"
                      />
                      {/* Use GiCancel icon as a button to go back to dropdown */}

                      <GiCancel
                        onClick={() => handleBackToDropdown('Tags')}
                        style={{ cursor: 'pointer', color: 'red' }}
                        size={20}
                        className="cancel-icon"
                      />
                    </div>
                  )}
                </div>
                <div className="input-box">
                  <span className="leads">Internal Region</span>
                  {formData.Region !== 'Others' ? (
                    <select
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="Region"
                      value={formData.Region}
                      onChange={handleChange}
                      placeholder="Enter your Region"
                    >
                      <option>Select Internal Region</option>
                      {regionData.map((item) => (
                        <option key={item.Region} value={item.Region}>{item.Region}</option>
                      ))}
                      {/* <option>Others</option> */}
                    </select>
                  ) : (
                    <div className="custom-input-container">
                      <input
                        type="text"
                        name="OtherRegion"
                        value={formData.OtherRegion}
                        onChange={handleChange}
                        placeholder="Enter your custom region"
                        className="custom-input"
                      />
                      {/* Use GiCancel icon as a button to go back to dropdown */}

                      <GiCancel
                        onClick={() => handleBackToDropdown('Region')}
                        style={{ cursor: 'pointer', color: 'red' }}
                        size={20}
                        className="cancel-icon"
                      />
                    </div>
                  )}
                </div>
                <div className="input-box">
                  <span className="leads">Lead Source</span>
                  {formData.Source !== 'Others' ? (
                    <select
                      className="text-black bg-white dark:bg-gray-950 dark:text-white"
                      type="text"
                      name="Source"
                      value={formData.Source}
                      onChange={handleChange}
                      placeholder="Enter your Prospect Source"
                    >
                      <option>Select Lead Source</option>
                      {leadSourceData.map((item) => (
                        <option value={item.Source}>{item.Source}</option>
                      ))}
                      {<option>Others</option>}
                    </select>
                  ) : (
                    <div className="custom-input-container">
                      <input
                        type="text"
                        name="OtherSource"
                        value={formData.OtherSource}
                        onChange={handleChange}
                        placeholder="Enter your custom source"
                        className="custom-input"
                      />
                      {/* Use GiCancel icon as a button to go back to dropdown */}

                      <GiCancel
                        onClick={() => handleBackToDropdown('Source')}
                        style={{ cursor: 'pointer', color: 'red' }}
                        size={20}
                        className="cancel-icon"
                      />
                    </div>
                  )}
                </div>
                <div className="input-box">
                  <span className="leads">Notification Days</span>
                  <input
                    className="dark:bg-gray-950 dark:text-white"
                    type="number"
                    name="NotificationDays"
                    value={formData.NotificationDays}
                    onChange={handleChange}
                    placeholder="Enter your Notify Lead Owner"
                  />
                </div>
                <div className="input-box">
                  <span className="leads">Follow up interval (in days)</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="number"
                    name="FollowUpInterval"
                    value={formData.FollowUpInterval}
                    onChange={handleChange}
                    placeholder="Enter the Follow up interval in days"
                  />
                </div>
                {/* <div className="input-box">
                  <span className="leads">Follow Up Status</span>
                  <input
                    className="dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="FollowUpStatus"
                    value={formData.FollowUpStatus}
                    onChange={handleChange}
                    placeholder="Enter your Follow up Status"
                    required
                  />
                </div> */}
                {/* <div className="input-box">
                  <span className="leads">Follow up interval (in days)</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="number"
                    id="FollowUpInterval"
                    name="FollowUpInterval"
                    value={formData.FollowUpInterval}
                    onChange={handleChange}
                    placeholder="Enter the Follow up interval in days"
                    required
                  />
                </div> */}
                <div className="input-box">
                  <span className="leads">Follow up date</span>
                  <input
                    className="dark:bg-gray-950 dark:text-white"
                    type="date"
                    name="FollowUpDate"
                    value={formData.FollowUpDate || ""}
                    onChange={handleChange}
                    min={calculateFollowUpDate()} // Restrict the minimum date to the calculated follow-up date
                  />
                </div>
                <div className="input-box">
                  <span className="leads">Remarks</span>
                  <input
                    className="text-black bg-white dark:bg-gray-950 dark:text-white"
                    type="text"
                    name="Remarks"
                    value={formData.Remarks}
                    onChange={handleChange}
                    placeholder="Enter your Remarks"
                  /> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Addlead;
