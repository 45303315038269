import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FaRegUserCircle } from "react-icons/fa";
import {
  useToast,
  Drawer,
  useDisclosure,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  DrawerFooter,
  Stack,
  Box,
  AbsoluteCenter,
  Wrap,
  WrapItem,
  Textarea,
  Text,
  Grid,
  GridItem,
  Badge,
  Button,
  Tag,
  VStack,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { RxAvatar } from "react-icons/rx";
//internal modules imported
import Axios from "../../api-instance";

const Userprofile = () => {
  //hooks
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const firstField = React.useRef();
  const [userdata, setUserdata] = useState([]);

  // Function to handle closing the drawer
  const handleCloseUserProfile = () => {
    onClose();
  };

  const handleLogout = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("tableColumns");
    localStorage.removeItem("DASHBOARD_RECENT_TABLE_COLUMNS");
    localStorage.removeItem("DASHBOARD_FOLLOWUP_TABLE_COLUMNS");
    localStorage.removeItem("DASHBOARD_LEAD_OWNER_ANALYTICS_TABLE_COLUMNS");
    navigate("/login");
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // Fetch user data from an API when the component mounts
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await Axios.get(
          `user/get_user?userId=${
            JSON.parse(localStorage.getItem("user")).userId
          }`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: JSON.parse(localStorage.getItem("token")),
            },
          }
        );

        setUserdata(response?.data?.data);
      } catch (error) {
        toast({
          title: error.message,
          position: "bottom-center",
          isClosable: true,
          status: "error",
          containerStyle: {
            width: "98.5vw",
            maxWidth: "98.5vw",
            alignContent: "center",
          },
        });
      }
    };

    getData();
  }, []);

  // Function to handle opening the drawer
  const handleOpenUserProfile = () => {
    onOpen();
  };
  console.log("User data",userdata);
  return (
    <>
      <>
        <FaRegUserCircle
          className=" dark:bg-gray-800 dark:text-white cursor-pointer"
          size={20}
          onClick={handleOpenUserProfile}
        />

        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={handleCloseUserProfile}
          finalFocusRef={firstField}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader borderBottomWidth="0px" bg="#94a3b8">
              <DrawerCloseButton bg="red" color={"white"} />
            </DrawerHeader>
            <DrawerBody h={10} bg="#94a3b8">
              <Stack spacing="24px">
                <Box position="relative">
                  <AbsoluteCenter mt={20} axis="both">
                    <Wrap>
                      <WrapItem>
                        <RxAvatar size={100} color="#ffff" />{" "}
                      </WrapItem>
                    </Wrap>
                  </AbsoluteCenter>
                </Box>
                <>
                {userdata.length === 0 ? (
              // Display a loading message or handle it in another way
              <Box mt={150} color={"white"}>
                Loading...
              </Box>
            ) : (
              // Map over the first array in userdata
              <Box mt={150} color={"white"}>
                <div
                  style={{
                    textAlign: "center",
                    display: "block",
                  }}
                >
                  <VStack>
                    <Tag>
                      {userdata[0].firstname}&nbsp;
                      {userdata[0].lastname}
                    </Tag>
                    <Tag>{userdata[0].email}</Tag>
                    <Tag>User ID: {userdata[0].userId}</Tag>
                  </VStack>
                </div>
              </Box>
            )}
                </>
              </Stack>

              <Grid
                templateColumns="repeat(5, 1fr)"
                gap={4}
                mt={5}
                borderTopWidth="1px"
                color={"white"}
              >
                <GridItem colSpan={2} h="10" mt={5} className="cursor-pointer">
                  <Button variant="solid" size="sm" colorScheme="orange">
                    My Account
                  </Button>
                </GridItem>
                <GridItem
                  colStart={4}
                  colEnd={6}
                  h="10"
                  mt={5}
                  className="cursor-pointer"
                  onClick={handleLogout}
                >
                  <Button variant="solid" size="sm" colorScheme="red">
                    Sign Out
                  </Button>
                </GridItem>
              </Grid>
            </DrawerBody>
            <DrawerFooter
              borderTopWidth="1px"
              bg="#cbd5e1"
              style={{
                textAlign: "center",
                display: "block",
              }}
            >
              <Tag variant="solid" fontSize="xl" fontWeight="bold">
                &copy; MatrixMindz @{currentYear}
              </Tag>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </>
    </>
  );
};

export default Userprofile;