import React, { useState, useEffect } from "react";
import { FaListUl } from "react-icons/fa";
import Axios from "../../../api-instance";
import {
  Box,
  Button as ChakraButton,
  Input,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";

const Sidebar = ({ selectedButton, setSelectedButton }) => {
  const [reports, setReports] = useState(null);
  const [filteredReports, setFilteredReports] = useState([]);
  const [error, setError] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await Axios.get(`/report/get_reports`);
        setReports(response.data.data);
        setFilteredReports(response.data.data);
      } catch (error) {
        console.error("Error loading reports:", error);
        setError(error);
      }
    };

    fetchReports();
  }, []);

  const openSidebar = () => {
    setIsSidebarOpen(true);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  useEffect(() => {
    if (reports) {
      const results = reports.filter((report) =>
        report.report.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredReports(results);
    }
  }, [searchTerm, reports]);

  return (
    <>
      <FaListUl
        size={25}
        onClick={openSidebar}
        style={{
          cursor: "pointer",
          position: "absolute",
          top: "70px",
          left: "10px",
          zIndex: "999",
          color: "white", // Set the color to white
          border: "2px solid #172254", // Add a 2px solid blue border
        }}
      />

      <Drawer isOpen={isSidebarOpen} placement="left" onClose={closeSidebar}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Reports</DrawerHeader>
          <DrawerBody>
            {/* Render loading state while fetching data */}
            {reports === null ? (
              <p>Loading...</p>
            ) : (
              <>
                {/* Search Input */}
                <Input
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  marginBottom="2"
                />

                {/* Sidebar content */}
                <Box maxHeight="80vh" overflowY="auto">
                  {filteredReports.map((report) => (
                    <ChakraButton
                      key={report.id}
                      variant={selectedButton === report.report ? "solid" : "ghost"}
                      onClick={() => {
                        setSelectedButton(report.report);
                        closeSidebar(); // Close the drawer on button click
                      }}
                      width="full"
                      marginBottom="2"
                    >
                      {report.report}
                    </ChakraButton>
                  ))}
                </Box>
              </>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Sidebar;
