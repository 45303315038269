import axios from "axios";
import constant from "./constants";
const instance = axios.create({
  baseURL: constant.port,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Content-Type, Authorization",
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
  //timeout: 5000,
});
export default instance;

// import axios from "axios";
// import constant from "./constants";
// let base;

// if (window.location.pathname.split("/")[1] === "leads") {
//   base = constant.port;
// } else if (window.location.pathname.split("/")[1] === "prospects") {
//   base = constant.port2;
// }

// const instance = axios.create({
//   baseURL: base,
//   headers: {
//     "Access-Control-Allow-Origin": "*",
//     "Access-Control-Allow-Headers": "Content-Type, Authorization",
//     "Access-Control-Allow-Credentials": "true",
//     "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
//   },
//   //timeout: 5000,
// });

// export default instance;


// import axios from "axios";  
// import constant from "./constants";
// let base;

// if(window.location.pathname.split("/")[1] === "prospects"){
// base = constant.port2
// }
// else {
// base = constant.port
// }
// const instance = axios.create({
//   baseURL: base,
//   headers: {
//     "Access-Control-Allow-Origin": "*",
//     "Access-Control-Allow-Headers": "Content-Type, Authorization",
//     "Access-Control-Allow-Credentials": "true",
//     "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
//   },
//   //timeout: 5000,
// });
// export default instance;


// import axios from "axios";
// import constant from "./constants";


// let base;

// if (window.location.pathname.split("/")[1] === "leads") {
//   base = constant.leadEndpoint;
// } else if (window.location.pathname.split("/")[1] === "prospects"){
//   base = constant.prospectEndpoint;
// }
// else{
//   base = constant.masterDataEndpoint;
// }

// const instance = axios.create({
//   baseURL: base,
//   headers: {
//     "Access-Control-Allow-Origin": "*",
//     "Access-Control-Allow-Headers": "Content-Type, Authorization",
//     "Access-Control-Allow-Credentials": "true",
//     "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
//   },
//   //timeout: 5000,
// });

// export default instance;



