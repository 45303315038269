import React, { useState, useEffect } from "react";
import Axios from "../../api-instance";
import { Badge } from "@chakra-ui/react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  IconButton,
  Tooltip,
  useToast,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Select,
  Box,
  FormErrorMessage,
  FormHelperText,
  Input,
} from "@chakra-ui/react";
import { MdEdit } from "react-icons/md";
import { BiSort } from "react-icons/bi";
import { MdOutlineFactCheck } from "react-icons/md";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import {
  useDisclosure,
  Button,
  Modal,
  Stack,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
const DraggableIndustryTable = ({
  isLoading,
  columns,
  data,
  industryData,
  getData,
  setIndustryData,
  index,
  checkBoxSelected,
  setcheckBoxSelected,
  handleCheckbox,
  setCheckBoxAll,
  checkBoxAll,
  handleDragOver,
  handleDragStart,
  handleDrop,
  tableColumns,
}) => {
  const toast = useToast();
  const [scrollBehavior, setScrollBehavior] = React.useState("inside");
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [countriesDataSet, setCountriesDataSet] = useState([]);
  const [citiesDataSet, setCitiesDataSet] = useState([]);
  // This state variable to store the selected Industry data
  const [selectedIndustry, setSelectedIndustry] = useState({});
  const [editedIndustry, setEditedIndustry] = useState({});
  const [formData, setFormData] = useState({});
  useEffect(() => {
    setEditedIndustry(selectedIndustry);
  }, [selectedIndustry]);

  const handleChange = async (e) => {
    const { name, value } = e.target;

    setEditedIndustry((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Single Update
  const handleSubmitUpdate = async () => {
    const updateData = {
      ...editedIndustry,
    };
    try {
      const { data } = await Axios.put(
        `/industry/singleupdateIndustry?userId=?${
          JSON.parse(localStorage.getItem("user")).userId
        }`,
        {
          ...updateData,
          id: selectedIndustry.id,
          userId: JSON.parse(localStorage.getItem("user")).userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      );

      if (data?.success) {
        toast({
          title: "Industry Updated Successfully!",
          position: "top-right",
          isClosable: true,
          status: "success",
        });
        // Optionally, you may want to update the selectedGrade state here
        setSelectedIndustry((prevSelectedIndustry) => ({
          ...prevSelectedIndustry,
          ...editedIndustry,
        }));
        getData();
      } else {
        toast({
          title: data?.message,
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    } catch (error) {
      toast({
        title: error.message,
        position: "top-right",
        isClosable: true,
        status: "error",
      });
    }
  };

  //  Function to open the modal and fetch Industry data for a particular ID
  const handleOpenModal = (id) => {
    const selectedIndustry = industryData.find(
      (Industry) => Industry.id === id
    );
    setSelectedIndustry(selectedIndustry);
    handleFullSizeClick();
    setSelectedRowId(id);
  };
  const handleCloseModal = () => {
    setSelectedRowId(null);
    setIsModalOpen(false);
    onClose(true);
  };

  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const sortTable = (key) => {
    let newSortOrder = "asc";

    if (sortKey === key) {
      newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    }

    const sortedData = [...industryData].sort((a, b) => {
      if (a[key] < b[key]) return sortOrder === "asc" ? -1 : 1;
      if (a[key] > b[key]) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });

    setSortKey(key);
    setSortOrder(newSortOrder);

    if (typeof setIndustryData === "function") {
      setIndustryData(sortedData);
    }
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [size, setSize] = React.useState("xl"); // Set the initial size to 'full'

  const handleFullSizeClick = () => {
    setSize("xl");
    onOpen();
  };

  return (
    <div>
      {!isLoading ? (
        tableColumns?.length !== 0 ? (
          <table className="table-auto border-collapse border border-slate-400 w-full  dark:border-gray-600 dark:bg-slate-950">
            {/* Table header */}
            <thead className="flex-nowrap text-center">
              <tr>
                <th className="border border-slate-300 whitespace-nowrap text-center">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      style={{
                        cursor: "pointer",
                        transform: "scale(1.3)",
                        marginRight: "5px",
                      }}
                      onChange={() => {
                        handleCheckbox(data.map((item) => item.id));
                        setCheckBoxAll(!checkBoxAll);
                      }}
                    />
                    {/* &nbsp;
                    <button 
                    onClick={toggleIcon}
                    checked={selectAllPages}
                    onChange={handleSelectAllPages}
                    checkBoxAll={checkBoxAll}
                    >
                      {showFactCheck ? (
                        <MdOutlineFactCheck size={20} />
                      ) : (
                        <MdCheckBoxOutlineBlank size={20} />
                      )}
                    </button> */}
                  </div>
                </th>
                <th className="border border-slate-300 whitespace-nowrap text-center">
                  Edit
                </th>
                {tableColumns.map(
                  (column, index) =>
                    column.label !== "Id" &&
                    column.isVisible === true && (
                      <th
                        className="border border-slate-300 whitespace-nowrap text-center"
                        key={column.key}
                        draggable
                        onDragStart={(event) => handleDragStart(event, index)}
                        onDragOver={handleDragOver}
                        onDrop={(event) => handleDrop(event, index)}
                      >
                        <span style={{ cursor: "all-scroll" }}>
                          {column.label}
                        </span>
                        <BiSort
                          style={{
                            marginLeft: "160px",
                            marginTop: "-22px",
                            cursor: "pointer",
                          }}
                          onClick={() => sortTable(column.key)}
                        />
                      </th>
                    )
                )}
              </tr>
            </thead>
            {/* Table body */}
            <tbody>
              {data?.length !== 0 ? (
                data.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    style={
                      selectedRowId === row.id
                        ? { backgroundColor: "#e2e8f0" }
                        : {}
                    }
                  >
                    <td>
                      <Tooltip
                        label="Select single row"
                        bg="green"
                        color="white"
                        hasArrow
                        placement="top-start"
                      >
                        {checkBoxAll ? (
                          <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            checked={checkBoxAll && true}
                            onChange={() => {
                              handleCheckbox(row?.id);
                            }}
                          />
                        ) : (
                          <input
                            type="checkbox"
                            style={{ cursor: "pointer" }}
                            onChange={() => {
                              handleCheckbox(row?.id);
                            }}
                            checked={
                              Array.isArray(checkBoxSelected) &&
                              checkBoxSelected?.includes(row.id)
                            }
                          />
                        )}
                      </Tooltip>
                    </td>
                    <td>
                      <Tooltip
                        label="Edit Row"
                        bg="green"
                        color="white"
                        hasArrow
                        placement="right-start"
                      >
                        <IconButton
                          icon={<MdEdit />}
                          onClick={() => handleOpenModal(row?.id)}
                          boxSize={6}
                          size={"20px"}
                        />
                      </Tooltip>
                    </td>
                    {tableColumns.map(
                      (column, columnIndex) =>
                        column.label !== "Id" &&
                        column.isVisible === true && (
                          <td key={`${rowIndex}-${columnIndex}`}>
                            {row[column.key]}
                          </td>
                        )
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={tableColumns.length}
                    style={{ textAlign: "center" }}
                  >
                    No Data Found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          <h1 style={{ textAlign: "center", color: "black" }}>
            No Data Found!
          </h1>
        )
      ) : (
        <p>Loading...</p>
      )}

      <>
        <Modal
          scrollBehavior={scrollBehavior}
          motionPreset="slideInBottom"
          onClose={handleCloseModal}
          size={size}
          isOpen={isOpen}
        >
          <ModalOverlay />
          <ModalContent
            maxW="900px"
            style={{
              padding: "0",
              width: "100%",
              resize: "both",
              overflow: "auto",
            }}
          >
            <ModalHeader>Edit Industry Information</ModalHeader>
            <ModalCloseButton onClose={handleCloseModal} />
            <ModalBody>
              {selectedIndustry && Object.keys(selectedIndustry).length > 0 && (
                <form>
                  <Grid templateColumns="repeat(5, 1fr)" gap={4}>
                    <GridItem colSpan={2}>
                      <FormControl>
                        <FormLabel>Industry</FormLabel>
                        <Input
                          type="text"
                          name="Industry"
                          defaultValue={
                            formData["Industry"] || selectedIndustry["Industry"]
                          }
                          value={editedIndustry["Industry"] || ""}
                          onChange={handleChange}
                        />
                        <FormLabel>Description</FormLabel>
                        <Input
                          type="text"
                          name="Description"
                          defaultValue={
                            formData["Description"] ||
                            selectedIndustry["Description"]
                          }
                          value={editedIndustry["Description"] || ""}
                          onChange={handleChange}
                        />
                      </FormControl>
                    </GridItem>
                  </Grid>
                  {!selectedIndustry && (
                    <p>Data not available for the selected Industry.</p>
                  )}
                </form>
              )}
              <ModalFooter>
                <Stack spacing={4} direction="row" align="center">
                  <Button onClick={handleCloseModal}>Cancel</Button>
                  <Button
                    colorScheme="messenger"
                    onClick={() => {
                      handleSubmitUpdate();
                      handleCloseModal();
                    }}
                  >
                    Save
                  </Button>
                </Stack>
              </ModalFooter>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    </div>
  );
};
export default DraggableIndustryTable;
