import React from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { Bar, Doughnut, Pie } from "react-chartjs-2";
import { chartColors } from "../../Utils/colors";
import { BsThreeDotsVertical } from "react-icons/bs";
import { countByGrade, getKeyAndValue } from "./helper";
import { Card } from "./Card";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

export function DonutChart({ className, data, cardTitle, filterBy }) {
  const [labels, dataset] = getKeyAndValue(data);

  const chartData = {
    labels,
    datasets: [
      {
        data: dataset,
        backgroundColor: chartColors,
      },
    ],
  };

  return (
    <Card className={className + " flex flex-col gap-4"}>
      <div className="flex justify-between">
        <span className="text-medium font-semibold">{cardTitle}</span>
        <span className="flex items-center gap-6">
          {/* <BsThreeDotsVertical className="text-icon-base text-black-shady cursor-pointer" /> */}
        </span>
      </div>
      <Doughnut data={chartData} />
    </Card>
  );
}

export function PieChart({ className, data, cardTitle }) {
  const [labels, dataset] = getKeyAndValue(data);

  const chartData = {
    labels,
    datasets: [
      {
        data: dataset,
        backgroundColor: chartColors,
      },
    ],
  };

  return (
    <Card className={className + " flex flex-col gap-4"}>
      <div className="flex justify-between">
        <span className="text-medium font-semibold">{cardTitle}</span>
        <span className="flex items-center gap-6">
          {/* <BsThreeDotsVertical className="text-icon-base text-black-shady cursor-pointer" /> */}
        </span>
      </div>
      <Pie data={chartData} />
    </Card>
  );
}

export function BarChart({ className, data, cardTitle }) {
  const [labels, dataset] = getKeyAndValue(data);

  const chartData = {
    labels: [""],
    datasets: dataset.map((data, ind) => ({
      label: labels[ind],
      data: [data],
      backgroundColor: chartColors[ind],
    })),
  };
  console.log(chartData);

  return (
    <Card
      className={className + " flex flex-col gap-4"}
      style={{ maxWidth: "300px" }}
    >
      <div className="flex justify-between">
        <span className="text-medium font-semibold">{cardTitle}</span>
        <span className="flex items-center gap-6">
          {/* <BsThreeDotsVertical className="text-icon-base text-black-shady cursor-pointer" /> */}
        </span>
      </div>
      <Bar data={chartData} />
    </Card>
  );
}




// import { React, useState } from "react";
// import {
//   Chart as ChartJS,
//   ArcElement,
//   Tooltip,
//   Legend,
//   CategoryScale,
//   LinearScale,
//   BarElement,
// } from "chart.js";
// import {
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   ModalHeader,
//   ModalFooter,
//   ModalBody,
//   ModalCloseButton,
//   useDisclosure,
//   sizes,
//   Button,
// } from "@chakra-ui/react";
// import { Bar, Doughnut, Pie } from "react-chartjs-2";
// import { BsThreeDotsVertical } from "react-icons/bs";
// import { HiDotsVertical } from "react-icons/hi";
// //internal module import
// import { countByGrade, getKeyAndValue } from "./helper";
// import { Card } from "./Card";
// import { chartColors } from "../../Utils/colors";

// ChartJS.register(
//   ArcElement,
//   Tooltip,
//   Legend,
//   CategoryScale,
//   LinearScale,
//   BarElement
// );

// // Donut Chart component
// export function DonutChart({ className, data, cardTitle, filterBy }) {
//   const [labels, dataset] = getKeyAndValue(data);

//   const chartData = {
//     labels,
//     datasets: [
//       {
//         data: dataset,
//         backgroundColor: chartColors,
//       },
//     ],
//   };

//   return (
//     <Card className={className + " flex flex-col gap-4"}>
//       <div className="flex justify-between">
//         <span className="text-medium font-semibold">{cardTitle}</span>

//         <span className="flex items-center gap-6">
//           {/* <BsThreeDotsVertical className="text-icon-base text-black-shady cursor-pointer" /> */}
//         </span>
//       </div>
//       <Doughnut data={chartData} />
//     </Card>
//   );
// }

// // Pie Chart component
// export function DonutChartIndustry({ className, data, cardTitle }) {
//   const [labels, dataset] = getKeyAndValue(data);

//   const chartData = {
//     labels,
//     datasets: [
//       {
//         data: dataset,
//         backgroundColor: chartColors,
//       },
//     ],
//   };

//   return (
//     <Card className={className + " flex flex-col gap-4"}>
//       <div className="flex justify-between">
//         <span className="text-medium font-semibold">{cardTitle}</span>
//         <span className="flex items-center gap-6">
//           {/* <BsThreeDotsVertical className="text-icon-base text-black-shady cursor-pointer" /> */}
//         </span>
//       </div>
//       <Doughnut data={chartData} />
//     </Card>
//   );
// }

// // Bar Chart component
// export function BarChart({ className, data, cardTitle }) {
//   const [labels, dataset] = getKeyAndValue(data);

//   const chartData = {
//     labels: [""],
//     datasets: dataset.map((data, ind) => ({
//       label: labels[ind],
//       data: [data],
//       backgroundColor: chartColors[ind],
//     })),
//   };
//   console.log(chartData);

//   return (
//     <Card
//       className={className + " flex flex-col gap-4"}
//       style={{ maxWidth: "300px" }}
//     >
//       <div className="flex justify-between">
//         <span className="text-medium font-semibold">{cardTitle}</span>
//         <span className="flex items-center gap-6">
//           {/* <BsThreeDotsVertical className="text-icon-base text-black-shady cursor-pointer" /> */}
//         </span>
//       </div>
//       <Bar data={chartData} />
//     </Card>
//   );
// }
